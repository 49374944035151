const kustomerMap: Record<string, string> = {
  en: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID || '',
  gb: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID || '',
  staging: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID || '',
  dk: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID || '',
  es: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID || '',
  it: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID || '',
  nl: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID_NL || '',
  de: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID_DE || '',
  fr: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID_FR || '',
  ch: process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID_CH || '',
}

export const kustomerBrandId = (locale: string | undefined) => kustomerMap[locale || 'en']
