import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export type SectionFilterConfigurationData = {
  _type: 'filterConfiguration'
  _id: string
  name: string
  defaultSorting: string
  enableSorting: boolean
  sortingOptions: {
    label: string
    value: string
  }[]
  enableFilters: boolean
  filterOptions: {
    label: string
    value: string
    operator: 'and' | 'or'
  }[]
  filtersDefaultOpen: boolean
  pageSize: number
}

export const SECTION_FILTER_CONFIGURATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'filterConfiguration' => {
    _type,
    _id,
    name,
    'defaultSorting': coalesce(defaultSorting, 'relevance'),
    enableSorting,
    enableFilters,
    filtersDefaultOpen,
    "sortingOptions": sortingOptions[] {
        label,
        value
    },
    "filterOptions": filterOptions[]-> {
      ${coalesceQuery('name', locale, 'label')},
      value,
      operator
    },
    "pageSize": coalesce(pageSize, 1000)
  }
`)
