import { defineQuery } from 'next-sanity'
import { coalesceFilter, coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Slug } from '../../types'

export type BreadcrumbItem = {
  title: string
  slug: Slug
  type: 'current' | 'parent' | 'grandparent'
}

export type CollectionBreadcrumb = {
  items: BreadcrumbItem[]
}

/**
 * This fragment is used to get the breadcrumb for a collection.
 */
export const COLLECTION_BREADCRUMB_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    'items': array::compact([
      {
        'type': 'current',
        ${coalesceQuery('slug', locale)},
        'title': coalesce(
          ${coalesceFilter('shortTitle', locale)},
          ${coalesceFilter('title', locale)}
        )
      },
      ...select(
        defined(parentCollection) => parentCollection[]->{
          'type': 'parent',
          ${coalesceQuery('slug', locale)},
          'title': coalesce(
            ${coalesceFilter('shortTitle', locale)},
            ${coalesceFilter('title', locale)}
          )
        },
        []
      ),
      // Check if the grandparent exists before adding it
      select(
        defined(parentCollection[0]->parentCollection[0]) => parentCollection[0]->parentCollection[0]->{
          'type': 'grandparent',
          ${coalesceQuery('slug', locale)},
          'title': coalesce(
            ${coalesceFilter('shortTitle', locale)},
            ${coalesceFilter('title', locale)}
          )
        },
        null
      )
    ])
`)
