import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'

export interface SectionPromoData {
  _id: string
  _type: 'sectionPromo'
  title: string
  description: string
  media: Media
  cta?: Link
}

export const SECTION_PROMO_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionPromo' => {
    _id,
    _type,
    media-> {
      ${MEDIA_FRAGMENT()}
    },
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('description', locale)},
    defined(cta) => {
      cta-> {
        ${LINK_FRAGMENT(locale)}
      }
    },
  }
`)
