import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionFaqData {
  _type: 'sectionFaq'
  _id: string
  hasSearchbar?: boolean
  header?: string
  faqs: [FaqQuestion | FaqTopic]
}

export const SECTION_FAQ_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionFaq' => {
    _type,
    _id,
    hasSearchbar,
    ${coalesceQuery('header', locale)},
    'faqs': faqs[@->.showInLocales[$locale] != false]-> {
      ${FAQ_FRAGMENT(locale)},
      ${FAQ_TOPIC_FRAGMENT(locale)}
    },
  }
`)

export interface FaqQuestion {
  _type: 'faqQuestion'
  _id: string
  question: string
  answer: RichText
  link?: Link
}

const FAQ_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'faqQuestion' => {
    _type,
    _id,
    ${coalesceQuery('question', locale)},
    ${RICH_TEXT_FRAGMENT(locale, 'answer')},
    defined(link) => {
      link-> {
        ${LINK_FRAGMENT(locale)}
      }
    }
  }
`)

export interface FaqTopic {
  _type: 'faqTopic'
  _id: string
  title: string
  faqs: FaqQuestion[]
}

const FAQ_TOPIC_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'faqTopic' => {
    _type,
    _id,
    ${coalesceQuery('title', locale)},
    'faqs': faqs[@->.showInLocales[$locale] != false]-> {
      ${FAQ_FRAGMENT(locale)}
    }
  }
`)
