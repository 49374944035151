import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Slug } from '../../types'

export interface SectionStoriesData {
  _type: 'sectionStories'
  _id: string
  title: string
  items: PageStory[]
}

export interface PageStory {
  _type: 'pageStory'
  _id: string
  slug: Slug
  preTitle: string
  title: string
  excerpt?: RichText
  thumbnail: Media
  link?: Link
}

export const SECTION_STORIES_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
_type == 'sectionStories' => {
  _type,
  _id,
  ${coalesceQuery('title', locale)},
  items[]-> {
    _type,
    _id,
    ${coalesceQuery('slug', locale)},
    ${coalesceQuery('preTitle', locale)},
    ${coalesceQuery('title', locale)},
    defined(excerpt) => {
      ${RICH_TEXT_FRAGMENT(locale, 'excerpt')}
    },
    thumbnail-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    link-> {
      ${LINK_FRAGMENT(locale)}
    },
   } | order(dateTime(dateCreated) desc)
 }
`)
