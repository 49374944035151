import { ShopifyUserErrorWithoutCode } from '../../types'

export interface AddLineItemsDto {
  data: {
    cartLinesAdd: {
      userErrors: ShopifyUserErrorWithoutCode[]
      cart: { id: string }
    }
  }
}

// See https://shopify.dev/docs/api/storefront/2024-01/mutations/cartLinesAdd
export const ADD_LINE_ITEMS_MUTATION = `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}`
