import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionInvalidTokenData {
  _id: string
  _type: 'sectionInvalidToken'
  title: string
  subtitle?: string
  backgroundImage: Media
  backLink: Link
}

export const SECTION_INVALID_TOKEN_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionInvalidToken' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    backLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
