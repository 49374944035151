import useCustomer from './useCustomer'
import { at } from '../../utils/array.util'

export const useAuthenticatedUserToken = (fallback?: string) => {
  const { customer } = useCustomer()

  return customer?.isLoggedIn ? String(at((String(customer?.id) || '').split('/'), -1) || '') : fallback || undefined
}

export default useAuthenticatedUserToken
