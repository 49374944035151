import { defineQuery } from 'next-sanity'
import { coalesceFilter, coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Slug } from '../../types'

type LinkType = 'product' | 'collection' | 'account' | 'blog' | 'pageArticle' | 'pageWithRequirements' | 'pageAccount' | 'pagePressRelease' | 'pageRetail'

export interface Link {
  _type: 'link'
  linkText?: string
  type?: 'external' | 'phone' | 'email' | 'internal' | 'scrollToSection'
  scrollToSection?: {
    _id: string
  }
  phone?: string
  email?: string
  externalLink?: string
  page?: {
    slug?: Slug
    _type?: LinkType
  }
  newTab?: boolean
}

export interface LinkWithShowInLocales extends Link {
  page?: {
    slug?: Slug
    showInLocales?: boolean
    _type?: LinkType
  }
}

export const LINK_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  type,
  ${coalesceQuery('linkText', locale)},
  defined(newTab) => {newTab},
  defined(page) => {
    page-> {
      _type,
      defined(slug) => {
        'slug': coalesce(${coalesceFilter('slug', locale)}, slug),
      }
    }
  },
  ${coalesceQuery('externalLink', locale)},
  defined(email) => {
    ${coalesceQuery('email', locale)}
  },
  defined(phone) => {
    ${coalesceQuery('phone', locale)}
  },
  defined(scrollToSection) => {
    scrollToSection-> {
      _id
    }
  },
`)

/**
 * Link fragment with nested showInLocales.
 * TODO: This is a temporary fragment for the global navigation A/B/C test. Fix query when test is over.
 */
export const LINK_FRAGMENT_WITH_SHOW_IN_LOCALES = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  type,
  ${coalesceQuery('linkText', locale)},
  defined(newTab) => {newTab},
  defined(page) => {
    page-> {
      _type,
      defined(slug) => {
        'slug': coalesce(${coalesceFilter('slug', locale)}, slug),
      },
      'showInLocales': showInLocales[$locale]
    }
  },
  ${coalesceQuery('externalLink', locale)},
  defined(email) => {
    ${coalesceQuery('email', locale)}
  },
  defined(phone) => {
    ${coalesceQuery('phone', locale)}
  },
  defined(scrollToSection) => {
    scrollToSection-> {
      _id
    }
  },
`)
