import { defineQuery } from 'next-sanity'
import { CollectionSeason, ProductType, Slug } from './types'

import { coalesceFilter, coalesceQuery, DEFAULT_LOCALE, forceFallbackForAll, requireAllToBeTrue, sanityFetch } from './utils'

import { GLOBAL_COLLECTIONS_FRAGMENT } from './fragments/common/dictionary/globalCollections.fragment'
import { GLOBAL_PRODUCTS_FRAGMENT } from './fragments/common/dictionary/globalProducts.fragment'
import { GLOBAL_TERMS_FRAGMENT } from './fragments/common/dictionary/globalTerms.fragment'
import { GET_IMAGE_FRAGMENT, Image } from './fragments/common/getImage.fragment'
import { COLLECTION_BREADCRUMB_FRAGMENT, CollectionBreadcrumb } from './fragments/components/breadcrumb.fragment'
import { COLOR_FRAGMENT, Color } from './fragments/components/color.fragment'
import { FOOTER_MENU_ITEM_FRAGMENT, FooterMenuItem } from './fragments/components/footerMenuItem.fragment'
import { IMAGE_TEXT_LIST_ITEM_FRAGMENT, ImageTextListItem } from './fragments/components/imageTextListItem.fragment'
import { LINK_FRAGMENT, Link } from './fragments/components/link.fragment'
import { MEDIA_FRAGMENT, Media } from './fragments/components/media.fragment'
import { MENU_CARD_FRAGMENT, MenuCard } from './fragments/components/menuCard.fragment'
import { MODEL_BIO_FRAGMENT, ModelBio } from './fragments/components/modelBio.fragment'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProduct } from './fragments/components/productCardProduct.fragment'
import { PRODUCT_COLOR_FRAGMENT, ProductColor } from './fragments/components/productColor'
import { RICH_TEXT_FRAGMENT, RichText } from './fragments/components/richText.fragment'
import { SEO_FRAGMENT, Seo } from './fragments/components/seo.fragment'
import { SIZE_CHARTS_FRAGMENT, SizeChart } from './fragments/components/sizeChart.fragment'
import { ACCOUNT_FRAGMENT, AccountData } from './fragments/globals/account.fragment'
import { CART_FRAGMENT, Cart } from './fragments/globals/cart.fragment'
import { COOKIE_BANNER_FRAGMENT, CookieBanner } from './fragments/globals/cookieBanner.fragment'
import { CUSTOMER_SERVICE_FRAGMENT, CustomerService } from './fragments/globals/customerService.fragment'
import { PAGE_ARTICLE_FRAGMENT } from './fragments/pages/pageArticle.fragment'
import { SECTIONS_FRAGMENT, Section } from './fragments/pages/sections.fragment'
import { SECTION_TYPES_FRAGMENT, SectionType } from './fragments/common/sectionTypes.fragment'
import { HOTSPOT_IMAGE_FRAGMENT, HotspotImage } from './fragments/components/hotspotImage.fragment'
import { PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT, ProductAnnouncementBar } from './fragments/components/productAnnouncementBar.fragment'
import { ANNOUNCEMENT_BAR_FRAGMENT, AnnouncementBar } from './fragments/components/announcementBar.fragment'
import { STYLES_ORDER_FRAGMENT, StylesOrder } from './fragments/globals/stylesOrder.fragment'
import { CARE_INSTRUCTION_SET_FRAGMENT, CareInstructionSet } from './fragments/components/careInstructions.fragment'
import { SERVICE_USPS_SET_FRAGMENT, ServiceUspSet } from './fragments/globals/serviceUspsSet.fragment'
import { GLOBAL_SEARCH_SETTINGS_TERMS_FRAGMENT, GLOBAL_SEARCH_SETTINGS_FRAGMENT, SearchSettings, SearchSettingsTerms } from './fragments/common/dictionary/globalSearch.fragment'
import { PRODUCT_SIZE_INFO_NOTE_FRAGMENT, ProductSizeInfoNote } from './fragments/components/productSizeInfoNote.fragment'
import { MAIN_MENU_ITEM_FRAGMENT, MainMenuItem } from './fragments/components/mainMenuItem.fragment'
import { Navigation } from '../../../apps/store/src/domain/navigation.domain'

export type GetAccountDataResponse = AccountData

export async function getAccountData(locale = DEFAULT_LOCALE, preview = false): Promise<GetAccountDataResponse> {
  const query = defineQuery(`
    *[_type == "account"][0] {
      ${ACCOUNT_FRAGMENT(locale)}
    }
  `)

  return await sanityFetch(query, preview)
}

export interface GetAccountPageSettingsResponse {
  accountNavigation: Link[]
}

export async function getAccountPageSettings(locale = DEFAULT_LOCALE, preview = false): Promise<GetAccountPageSettingsResponse> {
  const query = defineQuery(`
    *[_type == "accountSettings"][0] {
      accountNavigation[@->page->showInLocales[$locale] != false]-> {
        ${LINK_FRAGMENT(locale)}
      }
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export type GetDefaultAnnouncementBarResponse = AnnouncementBar

export async function getDefaultAnnouncementBar(locale = DEFAULT_LOCALE, preview = false): Promise<GetDefaultAnnouncementBarResponse> {
  const query = defineQuery(`
    *[_type == "globalAnnouncementBars"][0].defaultAnnouncementBar-> {
      ${ANNOUNCEMENT_BAR_FRAGMENT(locale)}
    }
  `)

  return await sanityFetch(query, preview)
}

type GetAnnouncementBarResponse = AnnouncementBar

export const getLocalizedAnnouncementBar = async (geo: string, locale = DEFAULT_LOCALE, preview = false): Promise<GetAnnouncementBarResponse> => {
  const query = defineQuery(`
    *[_type == "globalAnnouncementBars"][0][$localizedAnnouncementBar]-> {
      ${ANNOUNCEMENT_BAR_FRAGMENT(locale)}
    }
  `)

  const params = {
    localizedAnnouncementBar: `${geo}AnnouncementBar`,
  }

  return await sanityFetch(query, preview, params)
}

interface MinimalMainMenuItem {
  _key: string
  _type: 'mainMenuItem'
  mainMenuItemLink: Link
}

export interface GetMinimalNavigationResponse {
  wishlistSlug: {
    slug: Slug
  }
  mainMenuItems: (MinimalMainMenuItem | Link | (MinimalMainMenuItem & Link))[]
  secondaryMenuItems: (SecondaryMenuItem | Link | (SecondaryMenuItem & Link))[]
}

export async function getMinimalNavigation(locale = DEFAULT_LOCALE, preview = false): Promise<GetMinimalNavigationResponse> {
  const query = defineQuery(`
    *[_type == "navigation"][0] {
      "wishlistSlug": *[
        _type == 'pageWishlist'
      ][0] {
        ${coalesceQuery('slug', locale)},
      },
      mainMenuItems[] {
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        _type == 'mainMenuItem' => {
          _key,
          _type,
          mainMenuItemLink-> {
            ${LINK_FRAGMENT(locale)}
          },
          productCategories[] {
            ${coalesceQuery(
              'styles',
              locale,
              'styles',
              `
              [style->showInLocales[$locale] != false] {
                ...style->_id,
              }`,
            )}
          }
        }
      } {
        ...,
        productCategories[length(styles) > 0]
      },
      secondaryMenuItems[] {
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        _type == 'secondaryMenuItem' => {
          _type,
          ${coalesceQuery('secondaryMenuItemTitle', locale)},
          secondarySubmenuItems[]-> {
            ${LINK_FRAGMENT(locale)}
          },
          defined(secondaryMenuCard) => {
            secondaryMenuCard {
              ${coalesceQuery('title', locale)},
              ${MENU_CARD_FRAGMENT(locale)}
            }
          }
        }
      }
    } {
      ...,
      mainMenuItems[_type == 'link' || length(productCategories) > 0] {
        _key,
        _type,
        mainMenuItemLink,
        _type == 'link' => {
          type,
          linkText,
          defined(newTab) => {
            newTab,
          },
          defined(page) => {
            page,
          },
          externalLink,
          defined(email) => {
            email,
          },
          defined(phone) => {
            phone,
          },
          defined(scrollToSection) => {
            scrollToSection,
          },
        }
      }
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export interface Style {
  title: string
  slug: Slug
  collectionDescription: string
  new: boolean
  colors?: {
    colorId: string
    hex: string
    image?: Image
  }[]
  styleImage: Image
}

export interface ProductCategory {
  productCategoryMenuTitle: string
  productCategory: {
    title: string
    slug: Slug
  }
  productCategoryImage: Image
  styles?: Style[]
}

export interface SecondaryMenuItem {
  _type: 'secondaryMenuItem'
  secondaryMenuItemTitle: string
  secondarySubmenuItems: Link[]
  secondaryMenuCard?: {
    title: string
    image: Image
    pageLink: Link
  }
}

export interface GetMainMenuAndMenuCardsResponse {
  mainMenuItems: MainMenuItem[]
  topMenuCards: MenuCard[]
}

export async function getMainMenuAndMenuCards(locale = DEFAULT_LOCALE, preview = false): Promise<GetMainMenuAndMenuCardsResponse> {
  const query = defineQuery(`
    *[_type == "navigation"][0] {
      mainMenuItems[] {
        ${MAIN_MENU_ITEM_FRAGMENT(locale)}
      },
      topMenuCards[]-> {
        ${MENU_CARD_FRAGMENT(locale)}
      }
    } {
      ...,
      mainMenuItems[_type == 'link' || length(productCategories) > 0]
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetFooterResponse {
  findStoreHeader: string
  findStoreText: string
  findStoreLink: Link
  findStoreImage: Media
  infoSectionHeader: string
  emailSectionHeader: string
  emailSectionText: string
  emailSectionPlaceholder: string
  emailSectionSubscribe: string
  openingHoursHeader: string
  faqLink: Link
  returnsLink: Link
  productMenuItemsHeader: string
  productMenuItems: FooterMenuItem[]
  supportMenuItemsHeader: string
  supportMenuItems: FooterMenuItem[]
  extraMenuItems: Link[]
  reviewExplanationTooltip: string
  recentlyViewed: string
  clear: string
  copyright: string
  bCorpLink: Link
  bCorpNoticeLink: Link
  customerServiceData: CustomerService
}

export interface GetNavigationResponse {
  wishlistSlug: {
    slug: Slug
  }
  mainMenuItems?: (MainMenuItem | Link | (MainMenuItem & Link))[]
  secondaryMenuItems?: (SecondaryMenuItem | Link | (SecondaryMenuItem & Link))[]
  topMenuCards: MenuCard[]
}

export async function getNavigation(locale = DEFAULT_LOCALE, preview = false): Promise<Navigation> {
  const query = defineQuery(`
    *[_type == "navigation"][0] {
      "wishlistSlug": *[
        _type == 'pageWishlist'
      ][0] {
        ${coalesceQuery('slug', locale)},
      },
      mainMenuItems[] {
        ${MAIN_MENU_ITEM_FRAGMENT(locale)}
      },
      secondaryMenuItems[] {
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        _type == 'secondaryMenuItem' => {
          _type,
          ${coalesceQuery('secondaryMenuItemTitle', locale)},
          secondarySubmenuItems[]-> {
            ${LINK_FRAGMENT(locale)}
          },
          defined(secondaryMenuCard) => {
            secondaryMenuCard {
              ${coalesceQuery('title', locale)},
              ${MENU_CARD_FRAGMENT(locale)}
            }
          }
        }
      },
      topMenuCards[]-> {
        ${MENU_CARD_FRAGMENT(locale)}
      }
    } {
      ...,
      mainMenuItems[_type == 'link' || length(productCategories) > 0]
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export async function getFooter(locale = DEFAULT_LOCALE, preview = false): Promise<GetFooterResponse> {
  const footerDataQuery = defineQuery(`
    *[_type == "footer"][0] {
      _type,
      ${coalesceQuery('findStoreHeader', locale)},
      ${coalesceQuery('findStoreText', locale)},
      findStoreLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      findStoreImage-> {
        ${MEDIA_FRAGMENT(locale)}
      },
      ${coalesceQuery('infoSectionHeader', locale)},
      ${coalesceQuery('emailSectionHeader', locale)},
      ${coalesceQuery('emailSectionText', locale)},
      ${coalesceQuery('emailSectionPlaceholder', locale)},
      ${coalesceQuery('emailSectionSubscribe', locale)},
      ${coalesceQuery('openingHoursHeader', locale)},
      faqLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      returnsLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      ${coalesceQuery('productMenuItemsHeader', locale)},
      productMenuItems[showInLocales[$locale] != false] {
        ${FOOTER_MENU_ITEM_FRAGMENT(locale)}
      },
      ${coalesceQuery('supportMenuItemsHeader', locale)},
      supportMenuItems[showInLocales[$locale] != false] {
        ${FOOTER_MENU_ITEM_FRAGMENT(locale)}
      },
      extraMenuItems[]-> {
        ${LINK_FRAGMENT(locale)}
      },
      ${coalesceQuery('reviewExplanationTooltip', locale)},
      ${coalesceQuery('recentlyViewed', locale)},
      ${coalesceQuery('clear', locale)},
      ${coalesceQuery('copyright', locale)},
      bCorpLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      bCorpNoticeLink-> {
        ${LINK_FRAGMENT(locale)}
      },
      ${CUSTOMER_SERVICE_FRAGMENT(locale)}
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(footerDataQuery, preview, params)
}

interface DiscountCampaign {
  discount: number
  productAnnouncementBar?: ProductAnnouncementBar
  showOnCollections?: 'all' | 'select' | 'exclude'
  productIds: string[]
}

export interface GetGlobalsResponse {
  global: { [key: string]: string }
  product: { [key: string]: string }
  collection: { [key: string]: string }
  colorOrder: string[]
  localizedSettings: {
    showCurrencySymbol: boolean
  }
  searchSettingsTerms: SearchSettingsTerms
  searchSettings: SearchSettings
  discountCampaigns: DiscountCampaign[]
  stylesOrder: StylesOrder
}

export async function getGlobals(locale = DEFAULT_LOCALE, preview = false): Promise<GetGlobalsResponse> {
  const query = defineQuery(`
    {
      "global": *[_type == "globalTerms"][0] {
        ${GLOBAL_TERMS_FRAGMENT(locale)}
      },
      "product": *[_type == "globalProduct"][0] {
        ${GLOBAL_PRODUCTS_FRAGMENT(locale)}
      },
      "collection": *[_type == "globalCollection"][0] {
        ${GLOBAL_COLLECTIONS_FRAGMENT(locale)}
      },
      "colorOrder": *[_type == "colorOrder"].colors[]->._id,
      "localizedSettings": *[_type == "localizedSettings"] {
         "showCurrencySymbol": showCurrencySymbol[$locale] != false
      }[0],
      ...*[_type == "searchSettings"][0] {
        "searchSettingsTerms": {
          ${GLOBAL_SEARCH_SETTINGS_TERMS_FRAGMENT(locale)}
        },
        "searchSettings": {
          ${GLOBAL_SEARCH_SETTINGS_FRAGMENT(locale)}
        }
      },
      "discountCampaigns": *[_type == "discountCampaignSettings"][0].discountCampaigns[@->.showInLocales[$locale] != false]-> {
        discount,
        defined(productAnnouncementBar) => {
          productAnnouncementBar-> {
            ${PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT(locale)}
          }
        },
        showOnCollections,
        "productIds":collections[]->.products[]->_id,
      },
      "stylesOrder": ${STYLES_ORDER_FRAGMENT(locale)}
    }
  `)

  const params = {
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}

export type getCookieBannerResponse = CookieBanner

export async function getCookieBanner(locale = DEFAULT_LOCALE, preview = false): Promise<getCookieBannerResponse> {
  const query = defineQuery(`
    *[_type == "cookies"][0] {
      ${COOKIE_BANNER_FRAGMENT(locale)}
    }
  `)

  return await sanityFetch(query, preview)
}

export type GetCartResponse = Cart

export async function getCart(locale = DEFAULT_LOCALE, preview = false): Promise<GetCartResponse> {
  const query = defineQuery(`
    *[_type == "cart"][0] {
      ${CART_FRAGMENT(locale)}
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export type GetArticleCountResponse = number

export async function getArticleCount(id: string, locale = DEFAULT_LOCALE, preview = false): Promise<GetArticleCountResponse> {
  //  Find the section article overview which has a parent with the id
  const query = defineQuery(`
    count(
      *[
        _type in ['pageArticle', 'pagePressRelease']
        && showInLocales[$locale] != false
        && (
          *[
            _type == 'sectionArticleOverview'
            && ^._id in articles[]._ref
            && (
              *[_id == $id && ^._id in sections[]._ref][0] != null
              && showInLocales[$locale] != false
            )
          ][0] != null
        )
      ]
    )
  `)

  const params = {
    id,
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetAllPagesInLocaleResponse {
  _id: string
  _type: string
  slug: Slug
  allSlugs: Slug[]
  seo: {
    noIndex: boolean
  }
}

/**
 * This fetch is used only for the [...slug].tsx page and should be changed in the future
 */
export async function getAllPagesInLocale(docs: string[], locale = DEFAULT_LOCALE): Promise<GetAllPagesInLocaleResponse[]> {
  const query = defineQuery(`
    *[
      _type in $docs
      && length(${coalesceFilter('slug', locale)}.current) > 0
      && showInLocales[$locale] != false
    ] {
      _id,
      _type,
      ${coalesceQuery('slug', locale)},
      "allSlugs": ${forceFallbackForAll('slug')},
      seo {
        noIndex
      }
    }
  `)

  const params = {
    docs,
    locale,
  }

  return await sanityFetch(query, false, params)
}

export const getAllPagePaths = async (docs: string[], locales: string[]): Promise<{ [key: string]: string }[]> => {
  const query = defineQuery(`
    *[
      _type in $docs
    ] {
      ${locales
        .map(
          (locale) => `
        '${locale}': select(showInLocales['${locale}'] != false => ${coalesceFilter('slug', locale)}.current)
      `,
        )
        .join(', ')}
    }
  `)

  const params = {
    docs,
  }

  return await sanityFetch(query, false, params)
}

interface GetAllPagesInLocaleForSiteMapResponse {
  _type: string
  slug: Slug
  allSlugs: { [key: string]: Slug }
  showInLocales?: { [key: string]: boolean }
}

export const getAllPagesInLocaleForSiteMap = async (docs: string[], locale = DEFAULT_LOCALE): Promise<GetAllPagesInLocaleForSiteMapResponse[]> => {
  const query = defineQuery(`
    *[
      _type in $docs
      && showInLocales[$locale] != false
      && seo.noIndex != true
    ] {
      _type,
      ${coalesceQuery('slug', locale)},
      "allSlugs": ${forceFallbackForAll('slug')},
      defined(showInLocales) => {
        showInLocales
      },
    }
  `)

  const params = {
    docs,
    locale,
  }

  return await sanityFetch(query, false, params)
}

export interface getAllProductPagesInLocaleForSiteMapResponse {
  _id: string
  _type: string
  title: { [key: string]: string }
  slug: Slug
  images: string[]
  availableInLocale: {
    [key: string]: boolean
  }
}

/**
 * This fetch returns all a paginated list of all the indexable product pages in a locale as well as their availability in the other locales
 **/
export async function getAllProductPagesInLocaleForSiteMap(locale = DEFAULT_LOCALE, from: number, to: number): Promise<getAllProductPagesInLocaleForSiteMapResponse[]> {
  const query = defineQuery(`
    *[
      _type == "product"
      && length(coalesce(slug.current, '')) > 0
      && showInLocales[$locale] != false
      && availableInShopify[$locale] != false
      && !seo.noIndex
    ][$from...$to] {
      _id,
      _type,
      ${forceFallbackForAll('productTitle')},
      slug,
      'images': array::compact([
        ...images[_type == 'image'].asset->url,
        ...images[_type == 'reference']->.asset->url
      ]),
      availableInShopify,
      'productShowInLocales': showInLocales,
      'primaryCollectionShowInLocales': *[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0].showInLocales,
    } {
      _id,
      _type,
      slug,
      productTitle,
      images,
      ${requireAllToBeTrue('availableInLocale', ['availableInShopify', 'productShowInLocales', 'primaryCollectionShowInLocales'])}
    }
  `)

  const params = {
    locale,
    from,
    to,
  }

  return await sanityFetch(query, false, params)
}

type getProductsForRecommendationsResponse = ProductCardProduct[]

export const getProductsForRecommendations = async (slugs: string[], locale = DEFAULT_LOCALE, maxProducts = 30): Promise<getProductsForRecommendationsResponse> => {
  const query = defineQuery(`
    *[_type == 'product'
    && slug.current in $slugs
    && availableInShopify[$locale] != false && showInLocales[$locale] != false] {
      ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)},
      "sortOrder": select(${slugs.map((slug, i) => `slug.current == '${slug}' => ${i + 1}`).join(', ')}, ${maxProducts + 1})
    } | order(sortOrder asc) [0...${maxProducts}]
  `)

  const params = {
    slugs,
    locale,
    maxProducts,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, false, params)
}

export type GetDefaultServiceUspsResponse = ServiceUspSet

export const getDefaultServiceUsps = async (locale = DEFAULT_LOCALE, preview = false): Promise<GetDefaultServiceUspsResponse> => {
  const query = defineQuery(`
    *[
      _type in ["globalServiceUsps"]
    ][0].defaultServiceUsps->{
      ${SERVICE_USPS_SET_FRAGMENT(locale)}
    }
  `)

  return await sanityFetch(query, preview)
}

type getLocalizedGlobalServiceUspsResponse = ServiceUspSet

export const getLocalizedGlobalServiceUsps = async (geo: string, locale = DEFAULT_LOCALE, preview = false): Promise<getLocalizedGlobalServiceUspsResponse> => {
  const query = defineQuery(`
    *[
      _type == "globalServiceUsps"
    ][0][$localizedUsps]-> {
      ${SERVICE_USPS_SET_FRAGMENT(locale)}
    }
  `)

  const params = {
    localizedUsps: `${geo}ServiceUsps`,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetDiscountMessagesResponse {
  discountSuccessMessage: string
  discountFailureMessage: string
}

export async function getDiscountMessages(locale = DEFAULT_LOCALE, preview = false): Promise<GetDiscountMessagesResponse> {
  const query = defineQuery(`
  *[_type == "discountTerms"][0] {
    ${coalesceQuery('discountSuccessMessage', locale)},
    ${coalesceQuery('discountFailureMessage', locale)},
  }
  `)

  return await sanityFetch(query, preview)
}

export async function getSectionTypes(docs: string[], locale = DEFAULT_LOCALE, slug?: string, preview = false) {
  const isProduct = docs.includes('product')
  const isPageCollection = docs.includes('pageCollection')

  const defaultQuery = defineQuery(`
    *[_type in $docs ${isProduct && slug ? `&& slug.current == $slug` : slug ? `&& ${coalesceFilter('slug', locale)}.current == $slug` : ''}].sections[]->{
      ${SECTION_TYPES_FRAGMENT(locale)}
    }
  `)

  const pageCollectionQuery = defineQuery(`
    coalesce(
      *[_type == 'pageCollection' && length(connectedCollection[${coalesceFilter('@->slug', locale)}.current == $slug]) > 0][0],
      *[_id == 'defaultCollectionTemplate'][0]
    ).sections[]-> {
      ${SECTION_TYPES_FRAGMENT(locale)}
    }
  `)

  const params = {
    docs,
    locale,
    slug: slug || '',
  }

  const query = isPageCollection ? pageCollectionQuery : defaultQuery

  const response: SectionType[] | [] = (await sanityFetch(query, preview, params)) || []

  return response.filter((each) => each?._type)
}

export const getSectionTypesForPrimaryCollectionFromProduct = async (slug: string, locale = DEFAULT_LOCALE, preview = false) => {
  const query = defineQuery(`
    *[_type == 'collection' && isPrimary == true && $slug in products[]->slug.current].sections[]->{
      ${SECTION_TYPES_FRAGMENT(locale)}
    }
  `)

  const params = {
    slug,
    locale,
  }

  const response: SectionType[] | [] = await sanityFetch(query, preview, params)

  return response?.filter((each) => each?._type)
}

export interface GetHomePageResponse {
  showAnnouncementBar: boolean
  showFooterRecommendations: boolean
  sections: Section[]
  seo: Seo
}

export async function getHomePage(locale = DEFAULT_LOCALE, preview = false): Promise<GetHomePageResponse> {
  const sectionTypes = await getSectionTypes(['homePage'], locale, '/', preview)

  const query = defineQuery(`
    *[_type == "homePage" && ${coalesceFilter('slug', locale)}.current == "/"][0] {
      _id,
      showAnnouncementBar,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes, '/')},
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetPageCollectionResponse {
  slug: Slug
  collectionId: string
  collectionTitle: string
  sections: Section[]
  collectionSeason?: CollectionSeason
  collectionProductType?: ProductType
  seo?: Seo
}

// This function fetches the data for a pageCollection (new structure) and when not available the data from the collection (old structure)
export async function getPageCollection(slug: string, locale = DEFAULT_LOCALE, preview = false): Promise<GetPageCollectionResponse> {
  const sectionTypes = await getSectionTypes(['pageCollection'], locale, slug)

  const query = defineQuery(`
    {
      'page': coalesce(
        *[_type == 'pageCollection' && length(connectedCollection[${coalesceFilter('@->slug', locale)}.current == $slug]) > 0][0],
        *[_id == 'defaultCollectionTemplate'][0]
      ) {
        connectedCollection,
        ${SECTIONS_FRAGMENT(locale, sectionTypes, slug)}
      }
    } {
      ...page,
      ...coalesce(
        page.connectedCollection[0]->,
        *[_type == 'collection' && showInLocales[$locale] != false && ${coalesceFilter('slug', locale)}.current == $slug][0]
      ) {
        'collectionId': _id,
        ${coalesceQuery('title', locale, 'collectionTitle')},
        defined(seo) => {
          seo {
            ${SEO_FRAGMENT(locale)}
          }
        },
        ${coalesceQuery('slug', locale)},
        defined(collectionSeason) => {
          collectionSeason
        },
        defined(productType) => {
          "collectionProductType": productType
        }
      }
    }
  `)

  const params = {
    slug,
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetPageErrorResponse {
  showFooterRecommendations: boolean
  sections: Section[]
  seo: Seo
}

export async function getPageError(docs: string[], locale: string = 'en', preview = false): Promise<GetPageErrorResponse> {
  const sectionTypes = await getSectionTypes(docs, locale)

  const query = defineQuery(`
    *[_type in $docs][0] {
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes)},
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    locale,
    docs,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetPagesResponse {
  _id: string
  showFooterRecommendations: boolean
  sections: Section[]
  seo: Seo
}

export async function getPages(docs: string[], slug: string, locale: string = 'en', preview = false): Promise<GetPagesResponse> {
  const sectionTypes = await getSectionTypes(docs, locale, slug, preview)

  const query = defineQuery(`
    *[_type in $docs && showInLocales[$locale] != false && ${coalesceFilter('slug', locale)}.current == $slug][0] {
      _id,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes, slug)},
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    docs,
    locale,
    slug,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetNestedPagesResponse {
  _id: string
  _type: string
  showFooterRecommendations: boolean
  // TODO: This isn't strictly true. I could also be the Page Article which I'm unsure why I put into the sections array.
  sections: Section[]
  seo: Seo
}

export async function getNestedPages(docs: string[], lastSlug: string, firstSlug: string, locale: string = 'en', preview = false): Promise<GetNestedPagesResponse> {
  const sectionTypes = await getSectionTypes(docs, locale, firstSlug)

  // This query either produces a blog overview page (could be paginated) or an article page
  const query = defineQuery(`
    *[
      _type in $docs
      && showInLocales[$locale] != false
      && select(
        ${coalesceFilter('slug', locale)}.current == $lastSlug => true,
        ${coalesceFilter('slug', locale)}.current == $firstSlug => true,
        false
      )
    ][0] {
      _id,
      _type,
      showFooterRecommendations,
      ${SECTIONS_FRAGMENT(locale, sectionTypes, firstSlug, lastSlug)},
      _type == 'pageArticle' || _type == 'pagePressRelease' => {
        "sections": [
          {
            ${PAGE_ARTICLE_FRAGMENT(locale)}
          }
        ]
      },
      seo {
        ${SEO_FRAGMENT(locale)}
      },
    }
  `)

  const params = {
    docs,
    locale,
    lastSlug,
    firstSlug,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}

export type ProductMedia = Image | HotspotImage | VideoUrl

export type VideoUrl = {
  _type: 'videoUrl'
  url: string
  urlDesktop: string
}
export interface GetProductPageResponse {
  _id: string
  productTitle: string
  productOverrideMaterial?: RichText
  productOverrideCare?: CareInstructionSet
  models: ModelBio[]
  shopifyProductId: string
  isAvailable: boolean
  price: number
  variants: {
    shopifyVariantId: string
    price: number
    sku: string
    inventory: {
      quantity: number
    }
    options: {
      name: string
      value: string
    }[]
    isAvailable: boolean
  }[]
  currentColor: Color
  description?: RichText
  adImages: Image[]
  productMedia: ProductMedia[]
  discontinued?: boolean
  stockWarningThreshold?: number
  sections?: Section[]
  productServiceUsps?: {
    serviceUsps: {
      icon: 'tick' | 'warning'
      text: RichText
      openAccordionItem: 'description' | 'sizing' | 'material' | 'details' | 'policies'
    }[]
  }
  seo?: Seo
  usps: ImageTextListItem[]
  showReviews: boolean
  primaryCollectionId: string
  primaryCollectionSlug: Slug
  primaryCollectionTitle: string
  primaryCollectionProductType: ProductType
  primaryCollectionSeason: CollectionSeason
  breadcrumb: CollectionBreadcrumb
  productAnnouncementBar?: ProductAnnouncementBar
  productSizeInfoNote?: ProductSizeInfoNote
  colors?: ProductColor[]
  collectionBulletImage?: Media
  collectionSections?: Section[]
  sizeCharts?: SizeChart[]
  sizing?: RichText
  material?: RichText
  care?: CareInstructionSet
}

export async function getProductPage(slug: string, locale: string = 'en', preview = false): Promise<GetProductPageResponse> {
  const productSectionTypes = await getSectionTypes(['product'], locale, slug)
  const collectionSectionTypes = await getSectionTypesForPrimaryCollectionFromProduct(slug, locale)

  const query = defineQuery(`
    *[
      _type == 'product' &&
      slug.current == $slug &&
      showInLocales[$locale] != false &&
      ${preview ? '' : `availableInShopify[$locale] &&`}
      (*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0].showInLocales[$locale]) != false
    ][0] {
      _id,
      ${coalesceQuery('productTitle', locale)},
      defined(productOverrideFields.material) => {
        ${RICH_TEXT_FRAGMENT(locale, 'productOverrideFields.material', 'productOverrideMaterial')}
      },
      defined(care) => {
        "productOverrideCare": care-> {
          ${CARE_INSTRUCTION_SET_FRAGMENT(locale)}
        }
      },
      models[]->{
        ${MODEL_BIO_FRAGMENT()}
      },
      'shopifyProductId': shopifyProductId[$locale],
      'isAvailable': count(
        shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > 0]
      ) > 0 && availableInShopify[$locale],
      'price': shopifyVariants[0]->price[$locale],
      'variants': shopifyVariants[]-> {
        'shopifyVariantId': shopifyId[$locale],
        'price': price[$locale],
        sku,
        inventory[locationId == $inventoryLocationId][0] {
          quantity
        },
        options[] {
          name,
          'value': value[$locale]
        },
        'isAvailable': inventory[locationId == $inventoryLocationId][0].quantity > 0
      },
      'currentColor': color-> {
        ${COLOR_FRAGMENT(locale)}
      },
      defined(description) => {
        ${RICH_TEXT_FRAGMENT(locale, 'description')}
      },
      'adImages': coalesce(adImages[0...2] {
        _type == 'image' => {
          ${GET_IMAGE_FRAGMENT()}
        },
      }, []),
      'productMedia': images[] {
        _type == 'image' => {
          ${GET_IMAGE_FRAGMENT()}
        },
        _type == 'reference' || _type == 'taggedImage' => @-> {
          ${HOTSPOT_IMAGE_FRAGMENT(locale)}
        },
        _type == 'videoUrl' => {
          _type,
          url,
          urlDesktop,
        },
      },
      defined(discontinued[$locale]) => {
        'discontinued': discontinued[$locale]
      },
      defined(stockWarningThreshold) => {
        stockWarningThreshold
      },
      defined(sections) => {
        ${SECTIONS_FRAGMENT(locale, productSectionTypes, slug)}
      },
      defined(productServiceUsps) => {
        productServiceUsps-> {
          serviceUsps[^.showInLocales[$locale] != false]-> {
            icon,
            ${RICH_TEXT_FRAGMENT(locale, 'text')},
            openAccordionItem
          }
        }
      },
      defined(seo) => {
        seo {
          ${SEO_FRAGMENT(locale)}
        }
      },
      ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
        "primaryCollectionId": _id,
        defined(product.usps) && count(product.usps) > 0 => {
          'usps': product.usps[]-> {
            ${IMAGE_TEXT_LIST_ITEM_FRAGMENT(locale)}
          }
        },
        defined(showReviews) => {
          showReviews
        },
        ${coalesceQuery('slug', locale, 'primaryCollectionSlug')},
        ${coalesceQuery('title', locale, 'primaryCollectionTitle')},
        "primaryCollectionProductType": productType,
        "primaryCollectionSeason": collectionSeason,
        "breadcrumb": {
          ${COLLECTION_BREADCRUMB_FRAGMENT(locale)}
        },
        defined(productAnnouncementBars) => {
          'productAnnouncementBar': productAnnouncementBars[@->showInLocales[$locale] == true][0]-> {
            ${PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT(locale)}
          }
        },
        defined(productSizeInfoNotes) => {
          'productSizeInfoNote': productSizeInfoNotes[@->showInLocales[$locale] == true][0]-> {
            ${PRODUCT_SIZE_INFO_NOTE_FRAGMENT(locale)}
          }
        },
        defined(products) => {
          'colors': products[@->._type == 'product' && @->availableInShopify[$locale] != false && @->showInLocales[$locale] != false && @->.color != null] -> {
            ${PRODUCT_COLOR_FRAGMENT(locale)}
          }
        },
        "collectionBulletImage": bulletImage-> {
          ${MEDIA_FRAGMENT(locale)}
        },
        defined(sections) => {
          "collectionSections": ${SECTIONS_FRAGMENT(locale, collectionSectionTypes)}
        },
        defined(sizeCharts) => {
          ${SIZE_CHARTS_FRAGMENT(locale)}
        },
        defined(product.sizing) => {
          ${RICH_TEXT_FRAGMENT(locale, 'product.sizing', 'sizing')}
        },
        defined(product.material) => {
          ${RICH_TEXT_FRAGMENT(locale, 'product.material', 'material')}
        },
        defined(care) => {
          care-> {
            ${CARE_INSTRUCTION_SET_FRAGMENT(locale)}
          }
        }
      }
    } {
      ...,
      'care': coalesce(productOverrideCare, care),
      'material': coalesce(productOverrideMaterial, material),
    }
  `)

  const params = {
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
    locale,
    slug,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetProductSlugsByIdResponse {
  gid: string
  slug: Slug
}

export async function getProductSlugsById(ShopifyProductIds: string[], locale = DEFAULT_LOCALE, preview = false): Promise<GetProductSlugsByIdResponse[]> {
  const query = defineQuery(`
    *[
      _type == 'product' &&
      shopifyProductId[$locale] in $ShopifyProductIds
      && showInLocales[$locale] != false
    ] {
      'gid': shopifyProductId[$locale],
      slug
    }
  `)

  const params = {
    locale,
    ShopifyProductIds,
  }

  return await sanityFetch(query, preview, params)
}

export interface GetPDPSettingsResponse {
  accordionItems: string[]
  additionalSections: ('reviews' | 'recommendations' | 'Product level sections' | 'Collection level sections')[]
  productSectionsOverride: boolean
  storeAvailabilityMessage: string
}

export async function getPDPSettings(locale = DEFAULT_LOCALE, preview = false): Promise<GetPDPSettingsResponse> {
  const query = defineQuery(`
    *[_type == "pdpSettings"][0] {
      accordionItems,
      additionalSections,
      productSectionsOverride,
      ${coalesceQuery('storeAvailabilityMessage', locale)},
    }
  `)

  return await sanityFetch(query, preview)
}

interface OpeningTime {
  closed?: boolean
  open?: string
  close?: string
}

interface SpecialOpeningTime {
  date: string
  open?: string
  close?: string
  closed?: boolean
}

export interface GetRetailStoresInfoResponse {
  shopifyId: string
  geoPoint: {
    lat: number
    lng: number
  }
  city: string
  street: string
  postalCode: string
  country: string
  googleMapsLink: string
  defaultOpeningTimes: {
    monday: OpeningTime
    tuesday: OpeningTime
    wednesday: OpeningTime
    thursday: OpeningTime
    friday: OpeningTime
    saturday: OpeningTime
    sunday: OpeningTime
  }
  specialOpeningTimes: SpecialOpeningTime[] | []
  slug: Slug
}

export async function getRetailStoresInfo(locale = DEFAULT_LOCALE, preview = false): Promise<GetRetailStoresInfoResponse[]> {
  const query = defineQuery(`
    *[_type == "retailStore"] {
      shopifyId,
      geoPoint,
      ${coalesceQuery('city', locale)},
      street,
      postalCode,
      country,
      googleMapsLink,
      defaultOpeningTimes,
      specialOpeningTimes[]->,
      "slug": *[references(^._id)][0]._id,
    } {
      ...,
      "slug": *[references(^.slug)][0].slug[$locale] {
        current
      },
      "specialOpeningTimes": specialOpeningTimes[dateTime(date + 'T00:00:00Z') < dateTime(now()) + 60*60*24*14] {
        date,
        open,
        close,
        closed,
      }
    }
  `)

  const params = {
    locale,
  }

  return await sanityFetch(query, preview, params)
}

export type GetWishlistProductInformationResponse = ProductCardProduct

export const getWishlistProductInformation = async (ids: string[], locale = DEFAULT_LOCALE, preview = true): Promise<GetWishlistProductInformationResponse[]> => {
  const query = defineQuery(`
    *[
      _type == 'product'
      && showInLocales[$locale] != false
      && shopifyProductId[$locale] in $ids
    ] {
      ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)}
    }
  `)

  const params = {
    ids: ids.map((id) => `gid://shopify/Product/${id}`),
    locale,
    inventoryLocationId: locale === 'gb' ? process.env.NEXT_PUBLIC_UK_INVENTORY_LOCATION_ID : process.env.NEXT_PUBLIC_NL_INVENTORY_LOCATION_ID,
  }

  return await sanityFetch(query, preview, params)
}
