import { defineQuery, PortableTextBlock } from 'next-sanity'
import { coalesceFilter, coalesceQuery, DEFAULT_LOCALE } from '../../utils'

// TODO: This type is not related to this query
export type RichText = PortableTextBlock[]

export const RICH_TEXT_FRAGMENT = (locale = DEFAULT_LOCALE, name = 'text', rename?: string) =>
  defineQuery(`
  ${coalesceQuery(
    name,
    locale,
    rename,
    `[] {
      ...,
      markDefs[] {
        ...,
        _type == "internalLink" => {
          // We used coalesce here because Products don't have a localized slug
          'slug': coalesce(${coalesceFilter('@.reference->slug', locale)}, @.reference->slug),
          "type": @.reference->_type
        }
      }
    }`,
  )}
`)
