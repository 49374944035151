import { ShopifyCart } from '../../types'
import { CART_QUERY } from './cartQuery.fragment'

export interface GetCartDto {
  data: {
    cart: ShopifyCart
  }
}

export const GET_CART_QUERY = `query($id: ID!) {
  cart(id: $id) {
    ${CART_QUERY}
  }
}`
