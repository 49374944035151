import useSWR from 'swr'
import { get, keys, set } from '../../utils/localStorage.util'
import { getCartLocaleKey } from '../../utils/cart.util'
import { equals } from '../../utils/array.util'
import { ShopifyCartLineItem } from '@/types/shopify'
import getCart from '../../lib/shopify/getCart'
import { useRouter } from 'next/router'
import { addLineItems, applyDiscountCode, removeLineItems, replaceLineItems, updateCartAttributes } from 'data-access'

export function useCart() {
  const { locale } = useRouter()
  const cartLocaleKey = getCartLocaleKey(locale)

  const localStorageKey = keys[cartLocaleKey]

  if (!localStorageKey) {
    throw new Error('Unknown local storage key for cart.')
  }

  const {
    data: cart,
    error,
    isLoading,
    mutate: mutateCart,
  } = useSWR(locale ? ['cart', locale] : null, async () => getCart(locale, get(localStorageKey)), {
    onSuccess: (newCart) => {
      if (newCart) {
        set(localStorageKey, newCart.id)
      }
    },
    onError: (error) => {
      console.error('Error fetching cart:', error)
    },
    revalidateOnFocus: true,
    focusThrottleInterval: 1000 * 60, // 1 min.
  })

  const cartId = cart?.id

  const setCartAttributes = async (customAttributes: { key: string; value: string }[]) => {
    if (!cartId) {
      return
    }

    // GraphQL mutation on Checkout to set attributes
    // @see https://shopify.dev/api/storefront/2022-10/mutations/checkoutattributesupdatev2
    try {
      const result = await updateCartAttributes(locale, cartId, customAttributes)

      if (process.env.NODE_ENV === 'development') {
        console.debug('Set checkout attributes', { customAttributes, result })
      }
    } catch (error) {
      // Silent failure by design
      console.error('Error while updating checkout attributes.', error)
    }
  }

  const replace = async (item: { id: string; quantity: number }) => {
    if (!cartId) {
      throw new Error('Called updateItemQuantity too soon')
    }

    if (item.id == null) {
      throw new Error(`Missing variantId in item`)
    }
    if (item.quantity == null) {
      throw new Error(`Missing quantity in item with variant id: ${item.id}`)
    } else if (typeof item.quantity !== 'number') {
      throw new Error(`Quantity is not a number in item with variant id: ${item.id}`)
    } else if (item.quantity < 0) {
      throw new Error(`Quantity must not be negative in item with variant id: ${item.id}`)
    }

    if (!cartId) {
      return true
    }

    const lineItemsToReplace = cart?.lines.edges.reduce(
      (lines, { node: { id, quantity, attributes } }) => (id === item.id ? lines : [...lines, { id, quantity, attributes }]),
      item.quantity === 0 ? [] : [item],
    )

    const { userErrors } = await replaceLineItems(locale, cartId, lineItemsToReplace)

    const [error] = userErrors || []

    if (error) {
      return error.message || error.code
    }

    mutateCart()

    return false
  }

  /**
   * Adds items to the cart and manages cart attributes for line item ordering and Algolia tracking
   * @param {Object} params - The parameters for adding items to cart
   * @param {Array<{merchandiseId: string, quantity: number}>} params.lines - Array of items to add to cart
   * @param {boolean} [params.reorderCart=true] - Whether to maintain cart order in attributes
   * @param {string} [params.queryID] - Algolia queryID for tracking
   * @param {string} [params.handle] - Product handle for Algolia tracking
   * @returns {Promise<boolean|string>} Returns error message string or boolean indicating success
   */
  const add = async ({
    lines,
    reorderCart = true,
    queryID,
    handle,
  }: {
    lines: { merchandiseId: string; quantity: number }[]
    reorderCart?: boolean
    queryID?: string
    handle?: string
  }) => {
    // Validate cart exists and lines contain items with quantity > 0
    if (!lines.filter(({ quantity }) => quantity > 0).length || !cartId) {
      return true
    }

    // Track whether we need to update cart attributes to avoid unnecessary API calls
    let attributesNeedUpdate = false
    const existingAttributes = cart?.attributes || []
    const newAttributes = [...existingAttributes]

    // Handle sortedLineItems attribute for cart ordering
    if (reorderCart) {
      // Extract new merchandise IDs from lines being added
      const newLineItems = lines?.flatMap((lines) => lines?.merchandiseId)
      // Get existing sorted items from cart attributes
      const previousLineItems = existingAttributes?.filter((attribute) => attribute?.key === 'sortedLineItems')?.[0]?.value?.split(',') || []
      // Combine new and existing items, removing duplicates
      const combinedLineItems = Array.from(new Set([...newLineItems, ...previousLineItems]))

      const sortedLineItemsIndex = newAttributes.findIndex((attr) => attr.key === 'sortedLineItems')
      const newValue = combinedLineItems.toString()

      // Update or add sortedLineItems attribute if needed
      if (sortedLineItemsIndex !== -1) {
        if (newAttributes[sortedLineItemsIndex]?.value !== newValue) {
          newAttributes[sortedLineItemsIndex] = {
            key: 'sortedLineItems',
            value: newValue,
          }
          attributesNeedUpdate = true
        }
      } else {
        newAttributes.push({
          key: 'sortedLineItems',
          value: newValue,
        })
        attributesNeedUpdate = true
      }
    }

    // Handle Algolia tracking data
    if (queryID && handle) {
      // Format: "handle:queryID" for each entry
      const newAlgoliaEntry = `${handle}:${queryID}`
      // Get existing Algolia data, ensuring valid format (handle:queryID)
      const previousAlgoliaData =
        existingAttributes
          ?.filter((attribute) => attribute?.key === '_algoliaData')?.[0]
          ?.value?.split(',')
          ?.filter((entry) => entry && entry.includes(':')) || []

      // Combine existing and new data, removing duplicates and empty entries
      const combinedAlgoliaData = Array.from(new Set([...previousAlgoliaData, newAlgoliaEntry])).filter(Boolean)

      const algoliaDataIndex = newAttributes.findIndex((attr) => attr.key === '_algoliaData')
      const newValue = combinedAlgoliaData.toString()

      // Update or add _algoliaData attribute if needed
      if (algoliaDataIndex !== -1) {
        if (newAttributes[algoliaDataIndex]?.value !== newValue) {
          newAttributes[algoliaDataIndex] = {
            key: '_algoliaData',
            value: newValue,
          }
          attributesNeedUpdate = true
        }
      } else {
        newAttributes.push({
          key: '_algoliaData',
          value: newValue,
        })
        attributesNeedUpdate = true
      }
    }

    // Only make the API call to update attributes if changes were made
    if (attributesNeedUpdate) {
      await setCartAttributes(newAttributes)
    }

    // Add items to cart
    const { userErrors } = await addLineItems(locale, cartId, lines)

    // Handle errors
    const [error] = userErrors || []
    if (error) {
      console.error('Add function has errored:', error.message)
      return error.message
    }

    // Refresh cart data
    return mutateCart()
  }

  /**
   * Remove items from the cart and manages cart attributes for line item ordering and Algolia tracking
   * @param {Object} params - The parameters for removing items from cart
   * @param {Array<string>} params.lineIds - Array of line item IDs to remove from cart
   * @param {string} params.variantId - The Shopify variant ID of the item being removed
   * @param {string} params.productHandle - Product handle in format "product-slug-sku" for Algolia tracking
   * @returns {Promise<boolean|string>} Returns error message string if error occurs, false on success, true if cart doesn't exist
   */
  const remove = async ({ lineIds, productHandle }: { lineIds: string[]; variantId: string; productHandle: string }) => {
    if (!cartId) {
      return true
    }

    // Handle Algolia tracking data removal
    const existingAttributes = cart?.attributes || []
    const newAttributes = [...existingAttributes]

    const algoliaDataIndex = newAttributes.findIndex((attr) => attr.key === '_algoliaData')
    if (algoliaDataIndex !== -1) {
      // Get existing Algolia data entries
      const algoliaEntries = newAttributes[algoliaDataIndex]?.value
        ?.split(',')
        ?.filter((entry) => entry && entry.includes(':')) // Ensure valid entries only
        ?.filter((entry) => !entry.startsWith(`${productHandle}:`)) // Remove entries for this product

      // Update or remove the _algoliaData attribute
      if (algoliaEntries && algoliaEntries?.length > 0) {
        newAttributes[algoliaDataIndex] = {
          key: '_algoliaData',
          value: algoliaEntries.toString(),
        }
      } else {
        // Remove the attribute entirely if no entries left
        newAttributes.splice(algoliaDataIndex, 1)
      }

      // Update cart attributes
      await setCartAttributes(newAttributes)
    }

    const { userErrors } = await removeLineItems(locale, cartId, lineIds)

    const [error] = userErrors || []

    if (error) {
      return error.message
    }

    mutateCart()

    return false
  }

  const addDiscountCode = async (discountCodes: string[]) => {
    if (!cartId) {
      return true
    }

    const {
      userErrors,
      cart: { discountCodes: discountCodesOnCart },
    } = await applyDiscountCode(locale, cartId, discountCodes)

    const [error] = userErrors || []
    const discountCodeApplied = discountCodesOnCart.find((discountCode) => discountCode.code === discountCodes[0])?.applicable

    if (error || !discountCodeApplied) {
      return true
    }

    mutateCart()

    return false
  }

  const lineItemVariantIdsInCart = (cart?.lines?.edges || []).map((item) => item?.node?.merchandise?.id)

  // An array such as ["gid://shopify/ProductVariant/46317766213974", "gid://shopify/ProductVariant/46317766213975"]
  // that informs the frontend about the ideal sort ordering of items in the cart
  const lineItemOrderingInfo =
    cart?.attributes
      ?.filter((attribute) => attribute?.key === 'sortedLineItems')?.[0]
      ?.value?.split(',')
      ?.filter((gid) => lineItemVariantIdsInCart.includes(gid)) || []

  // We can only sort the cart line items if we have sorting for all items in the cart; otherwise we introduce hiding of line items
  const sortOrderInfoComplete =
    lineItemOrderingInfo.length > 0 && (cart?.lines?.edges || []).length === lineItemOrderingInfo.length && equals(lineItemOrderingInfo, lineItemVariantIdsInCart)

  // Item count
  const totalItems = cart?.lines?.edges?.reduce((acc, item) => acc + item.node.quantity, 0) ?? 0

  return {
    cartId,
    mutateCart,
    addDiscountCode,
    setCartAttributes,
    replace,
    add,
    remove,
    error,
    currencyCode: cart?.cost?.subtotalAmount?.currencyCode ? cart?.cost?.subtotalAmount?.currencyCode : locale === 'gb' ? 'GBP' : 'EUR',
    // SWR loading state alone doesn't suffice, we need to check whether
    // the cart/checkout object has been retrieved and parsed
    isLoading: isLoading || cart === undefined,
    cart: { ...cart, webUrl: `${cart?.checkoutUrl}&shop_pay_checkout_as_guest=true&skip_shop_pay=true` },
    isEmpty: totalItems === 0,
    totalItems,
    lineItems:
      (sortOrderInfoComplete
        ? // If we have sort ordering available in the custom attributes of the checkout, use that
          lineItemOrderingInfo?.map((item) => cart?.lines?.edges?.find(({ node }) => item === node?.merchandise?.id))?.map((item) => item?.node)
        : // Sort ordering information not available, return unsorted/raw from Shopify cart
          (cart?.lines?.edges || []).map((item) => item?.node)
      ).filter((item): item is ShopifyCartLineItem => !!item) || [],
    checkoutCustomAttributes: cart?.attributes,
  }
}

export default useCart
