import { defineQuery } from 'next-sanity'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { Link, LINK_FRAGMENT } from './link.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface MenuCard {
  image: Image
  pageLink: Link
}

export const MENU_CARD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  image {
    ${GET_IMAGE_FRAGMENT()}
  },
  pageLink-> {
    ${LINK_FRAGMENT(locale)}
  }
`)
