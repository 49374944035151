import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { COOKIE_TYPE_FRAGMENT, CookieType } from '../components/cookieType.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionCookieSettingsData {
  _type: 'sectionCookieSettings'
  _id: string
  title: string
  customiseLabel: string
  viewCookieListLabel: string
  cookieListTitle: string
  labelTableHeaderName: string
  labelTableHeaderProvider: string
  labelTableHeaderCookies: string
  cookieTypes: CookieType[]
}

export const SECTION_COOKIE_SETTINGS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionCookieSettings' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('customiseLabel', locale)},
    ${coalesceQuery('viewCookieListLabel', locale)},
    ${coalesceQuery('cookieListTitle', locale)},
    ${coalesceQuery('labelTableHeaderName', locale)},
    ${coalesceQuery('labelTableHeaderProvider', locale)},
    ${coalesceQuery('labelTableHeaderCookies', locale)},
    cookieTypes[]-> {
      ${COOKIE_TYPE_FRAGMENT(locale)}
    },
  }
`)
