import { defineQuery } from 'next-sanity'
import { coalesceLocalizedValue, coalesceQuery, DEFAULT_LOCALE } from '../../../utils'
import { Link, LINK_FRAGMENT } from '../../components/link.fragment'
import { SLIDER_CARD_ITEM_FRAGMENT, SliderCardItemData } from '../../sections/sectionSlider.fragment'

export interface SearchSettingsTerms {
  [key: string]: string
}

export const GLOBAL_SEARCH_SETTINGS_TERMS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    ${coalesceQuery('recentSearchesTitle', locale)},
    ${coalesceQuery('popularSearchesTitle', locale)},
    ${coalesceQuery('searchProductResultsTitle', locale)},
    ${coalesceQuery('trendingProductsTitle', locale)},
    ${coalesceQuery('searchCollectionResultsTitle', locale)},
    ${coalesceQuery('popularSearchesTitle', locale)},
    ${coalesceQuery('suggestedProductsTitle', locale)},
    ${coalesceQuery('searchArticleResultsTitle', locale)},
    ${coalesceQuery('searchFaqResultsTitle', locale)},
    ${coalesceQuery('searchPageResultsTitle', locale)},
    ${coalesceQuery('searchPlaceholder', locale)},
    ${coalesceQuery('cancel', locale)},
    ${coalesceQuery('clear', locale)},
    ${coalesceQuery('noResultsMessage', locale)},
`)

export interface SearchSettings {
  discoverCollectionsItems: SliderCardItemData[]
  searchHelpLinks: Link[]
  popularSearchTerms: string[]
}

// TODO: Remove the check for showInLocales as this is legacy
export const GLOBAL_SEARCH_SETTINGS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    searchHelpLinks[]->{
      ${LINK_FRAGMENT(locale)}
    },
    ${coalesceLocalizedValue('popularSearchTerms', locale, 'popularSearchTerms', '.value[]')},
    "discoverCollectionsItems": discoverCollectionsItems->items[@->showInLocales[$locale] != false && (!defined(@->collection) || @->collection->showInLocales[$locale] != false) && @->_type == 'sliderCard'][0...3]-> {
      ${SLIDER_CARD_ITEM_FRAGMENT(locale)}
    }
`)
