import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Cta, CTA_FRAGMENT } from '../components/cta.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionErrorData {
  _type: 'sectionError'
  _id: string
  title?: string
  description?: string
  cta?: Cta
}

export const SECTION_ERROR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionError' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('description', locale)},
    defined(cta) => {
      cta-> {
        ${CTA_FRAGMENT(locale)}
      }
    },
  }
`)
