import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Cta, CTA_FRAGMENT } from '../components/cta.fragment'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'

export interface SectionTextData {
  _id: string
  _type: 'sectionText'
  textAlignementOptions: 'left' | 'right' | 'center'
  title?: string
  text?: RichText
  button?: Cta
  textColor?: 'text-white' | 'text-brand-blue'
}

export const SECTION_TEXT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionText' => {
    _id,
    _type,
    textAlignementOptions,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
    defined(text) => {
      ${RICH_TEXT_FRAGMENT(locale, 'text')}
    },
    titleFontSize,
    textFontSize,
    defined(titleFontWeight) => {
      titleFontWeight
    },
    defined(button) => {
      button-> {
        ${CTA_FRAGMENT(locale)}
      },
    },
    defined(textColor) => { textColor },
  }
`)
