import { Media, MEDIA_FRAGMENT } from './media.fragment'
import { SECTION_TEXT_FRAGMENT, SectionTextData } from '../sections/sectionText.fragment'
import { Slug, YYYY_MM_DD } from '../../types'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { defineQuery } from 'next-sanity'

export interface Article {
  _type: 'pagePressRelease' | 'pageArticle'
  _id: string
  dateCreated: YYYY_MM_DD
  slug: Slug
  title: string
  articleThumbnailImage: Media
  sections: SectionTextData[]
}

export const RELATED_ARTICLES_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  _id,
  dateCreated,
  ${coalesceQuery('slug', locale)},
  ${coalesceQuery('title', locale)},
  articleThumbnailImage-> {
    ${MEDIA_FRAGMENT(locale)}
  },
  "sections" : *[_type == "sectionText" && _id in ^.sections[]._ref] {
    ${SECTION_TEXT_FRAGMENT(locale)},
  }
`)
