import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { CUSTOMER_SERVICE_FRAGMENT, CustomerService } from '../globals/customerService.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionContactData {
  _type: 'sectionContact'
  _id: string
  formHeader: string
  listId: string
  contactBlocks: ContactBlock[]
  customerServiceData: CustomerService
}

export interface ContactBlock {
  _type: 'reference'
  _id: string
  content: RichText
  contactType?: 'phone' | 'email' | 'messenger' | 'chat' | 'whatsapp'
  showLink?: boolean
}

export const SECTION_CONTACT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionContact' => {
    _type,
    _id,
    ${coalesceQuery('formHeader', locale)},
    ${coalesceQuery('listId', locale)},
    contactBlocks[@->.showInLocales[$locale] != false]-> {
      _type,
      _id,
      defined(contactType) => {
        contactType
      },
      ${RICH_TEXT_FRAGMENT(locale, 'content')},
      defined(showLink) => {
        showLink
      }
    },
    ${CUSTOMER_SERVICE_FRAGMENT(locale)}
  }
`)
