export const gtmUrl = 'https://www.googletagmanager.com'
export const gtmJsUrl = `${gtmUrl}/gtm.js`
export const gtmNsUrl = `${gtmUrl}/ns.html`
export const gtmId = process.env.NEXT_PUBLIC_GTM_ID || undefined

export const gtmTag = (defaultConsent: 'granted' | 'denied' = 'denied') => `
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('set', 'url_passthrough', true);
  gtag('consent', 'default', {
    'ad_storage': '${defaultConsent}',
    'ad_user_data': '${defaultConsent}',
    'ad_personalization': '${defaultConsent}',
    'analytics_storage': '${defaultConsent}',
    'functionality_storage': '${defaultConsent}',
    'personalization_storage': '${defaultConsent}',
    'security_storage': '${defaultConsent}'
  });
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  '${gtmJsUrl}?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${gtmId}');
`
