/**
 * Flatten a multidimensional array.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
 */
export const flatten = <T = any>(arr: T[] | T[][]) => arr.flat(Infinity) as T[]

/**
 * Return whether 2 string arrays are equal, comparing constituents.
 */
export const equals = (a: string[], b: string[]): boolean => {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    return false
  }

  // Since sort happens in place, we need to make a copy
  const aCopy = [...a].sort()
  const bCopy = [...b].sort()

  return aCopy.length === bCopy.length && aCopy.every((val, index) => val === bCopy[index])
}

/**
 * Array.at() isn't supported yet by all browsers, hence this function.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/at
 */
export const at = <T = any>(array: Array<T>, index: number) => {
  const length = array == null ? 0 : array?.length

  if (!length) {
    return undefined
  }

  index += index < 0 ? length : 0

  return array?.[index]
}

/**
 * Split array into chunks of size x
 */
export const chunk = <T = any>(array: T[], chunkSize: number) => {
  const result = []

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }

  return result
}

export const sort = <T = object>(array: T[], order: string[], key = '_id') =>
  array.sort((a, b) => {
    const getIndexOf = (item: any) => (item[key] && order.includes(item[key]) ? order.indexOf(item[key]) : Number.MAX_SAFE_INTEGER)
    return getIndexOf(a) - getIndexOf(b)
  })
