import { ShopifyStoreConfig } from '@/types/shopify'

export const stores: ShopifyStoreConfig[] = [
  ...(process.env.NODE_ENV === 'development'
    ? [
        // Staging Store for Development
        {
          storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_STAGING || '',
          customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_STAGING || '',
          storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_STAGING || '',
          storeLocale: 'en',
          default: true,
        },
      ]
    : [
        // International Store / EN store
        {
          storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EN || '',
          customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_EN || '',
          storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_EN || '',
          storeLocale: 'en',
          default: true,
        },
      ]),
  // NL Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_NL || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_NL || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_NL || '',
    storeLocale: 'nl',
    default: false,
  },
  // UK Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_UK || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_UK || '',
    storeLocale: 'gb',
    default: false,
  },
  // ES Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_ES || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_ES || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_ES || '',
    storeLocale: 'es',
    default: false,
  },
  // DK Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DK || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_DK || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_DK || '',
    storeLocale: 'dk',
    default: false,
  },
  // IT Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_IT || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_IT || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_IT || '',
    storeLocale: 'it',
    default: false,
  },
  // DE Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DE || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_DE || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_DE || '',
    storeLocale: 'de',
    default: false,
  },
  // FR Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_FR || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_FR || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_FR || '',
    storeLocale: 'fr',
    default: false,
  },
  // CH Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_CH || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_CH || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_CH || '',
    storeLocale: 'ch',
    default: false,
  },
]

export const preparePayload = (query: string, variables: Record<string, any>) => ({
  query,
  variables,
})
