import { ShopifyUserErrorWithCode } from '../../types'

export interface ReplaceLineItemsDto {
  data: {
    cartLinesUpdate: {
      userErrors: ShopifyUserErrorWithCode[]
      cart: { id: string }
    }
  }
}

export const REPLACE_LINE_ITEMS_MUTATION = `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}`
