export const getCartLocaleKey = (storeLocale = 'en'): Readonly<string> => {
  // For testing only
  if (process.env.NODE_ENV === 'development') {
    return 'CART_STAGING'
  }

  switch (storeLocale) {
    case 'nl':
      return 'CART_NL'
    case 'it':
      return 'CART_IT'
    case 'gb':
      return 'CART_UK'
    case 'es':
      return 'CART_ES'
    case 'fr':
      return 'CART_FR'
    case 'de':
      return 'CART_DE'
    case 'en':
      return 'CART_EN'
    case 'dk':
      return 'CART_DK'
    case 'ch':
      return 'CART_CH'
    default:
      return 'CART_EN'
  }
}
