import { Badges, ShopifyVariantInSanity } from '@/types/product'
import { liteClient as algoliasearch, LiteClient } from 'algoliasearch/lite'
import { Media } from 'data-access/sanity/fragments/components/media.fragment'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { Slug } from 'data-access/sanity/types'
import { IndexUiState, StateMapping, UiState } from 'instantsearch.js'
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types/results'
import singletonRouter from 'next/router'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'
import supportedFilterGroups from 'sanity-helpers/search-filters'
import aa, { InsightsClient } from 'search-insights'

export const algoliaClient: LiteClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID || '', process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY || '')

/**
 * Patched searchclient to prevent Algolia search for empty queries but rather return an empty state.
 * This improves performance when opening or resetting the search
 */
export const searchClient: LiteClient = {
  ...algoliaClient,
  search(requests) {
    if (Array.isArray(requests) && requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      })
    }

    return algoliaClient.search(requests)
  },
}

// Please note this is the Insights browser client, not Algolia's API client;
// and most of the client is initialized automatically by the InstantSearch component.
// @see https://www.algolia.com/doc/api-client/methods/insights/
export const algoliaInsightsClient: InsightsClient = aa

const INDEX_SUFFIXES = ['bestsellers', 'newest', 'price_asc', 'price_desc'] as const
export type AlgoliaIndexSuffixes = (typeof INDEX_SUFFIXES)[number]

export const getAlgoliaIndexName = (locale: string, sortBy?: string): string => {
  // TODO: Remove this when we have a proper preview environment
  if (process.env.NODE_ENV === 'development') {
    return `dev_search_en`
  }

  const baseIndex = `production_search_${locale}`

  // If no sortBy is provided or it's 'relevance', return base index
  if (!sortBy || sortBy === 'relevance') {
    return baseIndex
  }

  // Check if the sortBy already includes a valid suffix
  const suffix = INDEX_SUFFIXES.find((s) => sortBy.endsWith(s))
  if (!suffix) {
    return baseIndex
  }

  // Ensure the baseIndex does not already end with the suffix
  if (baseIndex.endsWith(suffix)) {
    return baseIndex
  }

  return `${baseIndex}_${suffix}`
}

export const hasValidSuffix = (indexName: string): boolean => {
  return INDEX_SUFFIXES.some((suffix) => indexName.endsWith(suffix))
}

/**
 * Builds an object ID for Algolia.
 * @type: The type of the object (extend this type when more types are needed)
 * @id: The ID of the object
 * @suffix: The suffix of the object. Used for multiple variants of the same product.
 */
export const buildObjectID = (type: 'product', id = '', suffix = '') => {
  let objectID = `${type}-${id}`

  if (suffix) {
    objectID += `-${suffix}`
  }

  return objectID
}

/**
 * Custom router configuration for Algolia InstantSearch with Next.js.
 * This function creates a router that syncs the search state with the URL.
 */
export function customRouter(serverUrl: string) {
  return createInstantSearchRouterNext({
    singletonRouter,
    serverUrl,
    routerOptions: {
      cleanUrlOnDispose: false,
    },
  })
}

/**
 * Custom state mapping for Algolia InstantSearch.
 * This function maps the search UI state to the URL and vice versa, allowing for clean and readable URLs.
 * It handles Algolia-specific refinements, range filters, and preserves non-Algolia parameters.
 */
export function customStateMapping(url: string, indexName: string, defaultSorting?: string): StateMapping {
  // Create a Set of supported filter keys for efficient lookup
  const supportedKeys = new Set(supportedFilterGroups.map((group) => group.value))

  const createServerUrl = (_url: string) => {
    if (!/^https?:\/\//i.test(_url)) {
      _url = 'http://' + _url
    }

    return new URL(_url)
  }

  const serverUrl = createServerUrl(url)

  const getBaseIndexName = (fullIndexName: string): string => {
    const suffix = INDEX_SUFFIXES.find((s) => fullIndexName.endsWith(s))
    if (suffix) {
      return fullIndexName.slice(0, -suffix.length - 1) // -1 for the underscore
    }
    return fullIndexName
  }

  const getValidSortBy = (sortBy: string | undefined) => {
    if (!sortBy) {
      return undefined
    }

    const suffix = INDEX_SUFFIXES.find((s) => sortBy.endsWith(s))
    return suffix || undefined
  }

  const baseIndexName = getBaseIndexName(indexName)

  return {
    /**
     * Converts the UI state to URL parameters
     */
    stateToRoute: (uiState: UiState): Record<string, any> => {
      const indexUiState: IndexUiState = uiState[indexName] || {}
      const { sortBy, refinementList, range, configure, ...restUiState } = indexUiState

      // Convert refinement list to URL-friendly format
      const refinements = refinementList
        ? Object.keys(refinementList).reduce(
            (acc, key) => {
              const values = refinementList[key]
              if (values) {
                acc[key] = values.join(',')
              }
              return acc
            },
            {} as Record<string, string>,
          )
        : {}

      // Convert range filters to URL-friendly format
      const ranges = range
        ? Object.keys(range).reduce(
            (acc, key) => {
              const value = range[key]
              if (value) {
                acc[`range_${key}`] = value
              }
              return acc
            },
            {} as Record<string, string>,
          )
        : {}

      // Preserve non-Algolia parameters from the UI state
      const nonAlgoliaParams = Object.keys(restUiState).reduce(
        (acc, key) => {
          if (!supportedKeys.has(key)) {
            acc[key] = restUiState[key as keyof typeof restUiState]
          }
          return acc
        },
        {} as Record<string, any>,
      )

      // Include existing URL parameters that are not related to Algolia
      const currentParams = new URLSearchParams(serverUrl.search)
      const existingParams: Record<string, string> = {}
      currentParams.forEach((value, key) => {
        if (!supportedKeys.has(key) && key !== 'sortBy') {
          existingParams[key] = value
        }
      })

      const validSortBy = getValidSortBy(sortBy) || (defaultSorting !== 'relevance' ? getValidSortBy(defaultSorting) : undefined)

      // Combine all parameters into a single object
      return {
        ...(validSortBy && { sortBy: validSortBy }),
        ...refinements,
        ...ranges,
        ...nonAlgoliaParams,
        ...existingParams,
      }
    },
    /**
     * Converts URL parameters back to the UI state
     */
    routeToState: (routeState: Record<string, any>): UiState => {
      const { sortBy, ...refinementsAndRanges } = routeState
      const refinements: Record<string, string> = {}
      const range: Record<string, string> = {}
      const nonAlgoliaParams: Record<string, any> = {}

      // Categorize parameters into refinements, ranges, and non-Algolia params
      Object.keys(refinementsAndRanges).forEach((key) => {
        if (key.startsWith('range_')) {
          range[key.replace('range_', '')] = refinementsAndRanges[key]
        } else if (supportedKeys.has(key)) {
          refinements[key] = refinementsAndRanges[key]
        } else {
          nonAlgoliaParams[key] = refinementsAndRanges[key]
        }
      })

      // Convert refinements back to the format expected by InstantSearch
      const refinementList = Object.keys(refinements).reduce(
        (acc, key) => {
          const values = refinements[key]
          if (values) {
            acc[key] = values.split(',')
          }
          return acc
        },
        {} as Record<string, string[]>,
      )

      const validSortBy = getValidSortBy(sortBy) || (defaultSorting !== 'relevance' ? getValidSortBy(defaultSorting) : undefined)

      return {
        [indexName]: {
          ...(validSortBy && { sortBy: `${baseIndexName}_${validSortBy}` }),
          refinementList,
          range,
          ...nonAlgoliaParams, // Ensure non-Algolia parameters are preserved in the state
        },
      }
    },
  }
}

type MisterBaseHit<T = {}> = AlgoliaHit<
  {
    _id: string
    _type: 'product' | 'pageArticle' | 'page' | 'collection' | 'faqQuestion'
  } & T
>

type AlgoliaImage = {
  asset: {
    originalFileName: string
    url: string
    height: number
    width: number
  }
}

export type AlgoliaProductHit = MisterBaseHit<{
  _type: 'product'
  title: string
  discontinued: boolean
  parentCollectionsNames: string[]
  parentCollectionsSlugs: string[]
  category: string
  style: string
  sizes: string[]
  lengthSizes: string[]
  waistSizes: string[]
  materials: string[]
  colors: string[]
  sleeveLength: string | null
  filterCategory: string[] | null
  bestsellerScore: number
  availableInShopify: boolean
  price: number
  createdAt: string
  updatedAt: string
  isAvailable: boolean
  shopifyProductId: string
  sku: string
  handle: string
  slug: Slug
  description: string
  badges: Badges
  color: string
  colorId: string
  subcategory: string
  season: string
  url: string
  primaryCollection: {
    collectionSeason: string
    collectionTitle: string
    collectionDescription: string
    showInLocale: boolean
    productType: string
  }
  images: AlgoliaImage[]
  modelShot: AlgoliaImage
  variants: Array<ShopifyVariantInSanity>
}>

export type AlgoliaArticleHit = MisterBaseHit<{
  _type: 'pageArticle'
  createdAt: string
  images: Media[]
  seo: {
    seoTitle: string
    seoDescription: string | null
  }
  seoDescription: string | null
  seoTitle: string | null
  showInLocale: boolean
  slug: string
  title: string
  updatedAt: string
  url: string
}>

export type AlgoliaCollectionHit = MisterBaseHit<{
  _type: 'collection'
  collectionDescription: string
  content: string
  createdAt: string
  images: Media[]
  isPrimary: boolean
  longDescription: string
  seo: {
    seoTitle: string
    seoDescription: string | null
  }
  seoDescription: string | null
  seoTitle: string | null
  showInLocale: boolean
  slug: string
  title: string
  updatedAt: string
  url: string
}>

export type AlgoliaFAQHit = MisterBaseHit<{
  _type: 'faqQuestion'
  title: string
  question: string
  answer: string
  showInLocale: boolean
  updatedAt: string
  url: string
}>

export type AlgoliaPageHit = MisterBaseHit<{
  _type: 'page'
  title: string
  seoTitle: string
  seoDescription: string
  showInLocale: boolean
  updatedAt: string
  url: string
}>

/**
 * Map an Algolia Result to the props of a ProductCard
 * @param hit
 */
export const mapAlgoliaHitToProductCard = (hit: AlgoliaProductHit): ProductCardProduct & { sku: string } => ({
  _id: hit._id,
  discontinued: hit.discontinued,
  slug: hit.slug,
  badges: hit.badges,
  images: hit.images
    ?.filter(({ asset }) => !!asset)
    .map(({ asset }) => {
      return {
        _type: 'image',
        url: asset.url,
        originalFilename: asset.originalFileName,
        lqip: '',
        width: asset.width,
        height: asset.height,
      }
    }),
  modelShot: hit.modelShot && {
    _type: 'image',
    url: hit.modelShot?.asset?.url,
    originalFilename: hit.modelShot?.asset?.originalFileName,
    lqip: '',
    width: hit.modelShot?.asset?.width,
    height: hit.modelShot?.asset?.height,
  },
  productTitle: hit.title,
  primaryCollection: {
    _id: hit.title,
    productType: hit.primaryCollection?.productType,
  },
  colorId: hit.color,
  shopifyProductId: hit.shopifyProductId,
  sku: hit.sku,
  isAvailable: hit.isAvailable,
  price: hit.price,
  variants: hit.variants,
})
