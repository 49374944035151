import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { LINK_FRAGMENT, Link } from '../components/link.fragment'

export interface SectionImageLinkData {
  _id: string
  _type: 'sectionImageLink'
  title: string
  image: Image
  link: Link
}

export const SECTION_IMAGE_LINK_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionImageLink' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    image {
      ${GET_IMAGE_FRAGMENT()}
    },
    link-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
