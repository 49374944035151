import { SanityReference } from '@sanity/client'
import { defineQuery } from 'next-sanity'

export interface Image {
  _type: 'image'
  url: string
  originalFilename: string
  lqip: string
  width: number
  height: number
  crop?: {
    top: number
    bottom: number
    left: number
    right: number
  }
  hotspot?: {
    height: number
    x: number
    y: number
  }
  // These are only optional because we don't send the reference to algolia as we don't currently use hotspot or crop for those images
  asset?: SanityReference
  isOpaque?: boolean
}

export const GET_IMAGE_FRAGMENT = () =>
  defineQuery(`
  _type,
  asset,
  ...asset-> {
    url,
    originalFilename,
    ...metadata {
      lqip,
      isOpaque,
      ...dimensions {
        width,
        height
      }
    }
  },
  defined(crop) => {
    crop {
      top,
      bottom,
      left,
      right
    }
  },
  defined(hotspot) => {
    hotspot {
      height,
      x,
      y
    }
  }
`)
