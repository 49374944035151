import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { defineQuery } from 'next-sanity'

export interface SectionRegistrationData {
  _id: string
  _type: 'sectionRegistration'
  title: string
  subtitle?: string
  backgroundImage: Media
  firstNameLabel: string
  firstNameErrorMessage: string
  lastNameLabel: string
  lastNameErrorMessage: string
  emailLabel: string
  emailErrorMessage: string
  passwordLabel: string
  passwordErrorMessage: string
  confirmPasswordLabel: string
  passwordMatchErrorMessage: string
  submitButtonText: string
  alreadyHaveAccountLink: Link
}

export const SECTION_REGISTRATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionRegistration' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT(locale)}
    },
    ${coalesceQuery('firstNameLabel', locale)},
    ${coalesceQuery('firstNameErrorMessage', locale)},
    ${coalesceQuery('lastNameLabel', locale)},
    ${coalesceQuery('lastNameErrorMessage', locale)},
    ${coalesceQuery('emailLabel', locale)},
    ${coalesceQuery('emailErrorMessage', locale)},
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    ${coalesceQuery('confirmPasswordLabel', locale)},
    ${coalesceQuery('passwordMatchErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
    alreadyHaveAccountLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
