const categoryAlternatives: Readonly<Record<string, string>> = {
  'The Short Seersuckers – Wider Stripes': 'The Short Seersuckers',
  'Los Shorts Seersuckers – Rayas más anchas': 'Los Shorts Seersuckers',
  'Shorts Seersuckers – Rayures plus larges': 'Shorts Seersuckers',
  'Die Seersucker Shorts - Breite Streifen': 'Die Seersucker Shorts',
}

export const productTitle = (productTitle: string, shorten = false): { title: string; category: string | undefined } => {
  const parts = productTitle?.split('*')
  const category = (parts?.[1] || '').trim()

  return {
    title: (parts?.[0] || '').trim(),
    category: shorten ? categoryAlternatives?.[category] || category : category,
  }
}
