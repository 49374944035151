import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { Cta, CTA_FRAGMENT } from '../components/cta.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export type HighlightData = {
  _id: string
  _type: string
  title: string
  text: string
  image: Media
  button?: Cta
}

export type SectionHighlightsData = {
  _type: 'sectionHighlights'
  _id: string
  title: string
  highlights: HighlightData[]
}

export const SECTION_HIGHLIGHTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionHighlights' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    highlights[]-> {
      _id,
      _type,
      ${coalesceQuery('title', locale)},
      ${coalesceQuery('text', locale)},
      "image": image-> {
        ${MEDIA_FRAGMENT(locale)}
      },
      defined(button) => {
        button-> {
          ${CTA_FRAGMENT(locale)}
        }
      },
    },
  }
`)
