import { defineQuery } from 'next-sanity'
import { SectionType } from '../common/sectionTypes.fragment'
import { Section, SECTION_LIST } from '../pages/sections.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface contextualizedValue {
  name: 'base' | 'md' | 'lg'
  value: number
}
;[]

export interface SectionLayoutData {
  _id: string
  _type: 'sectionLayout'
  columns: contextualizedValue[]
  sections?: ({
    colSpan: contextualizedValue[]
    rowSpan: contextualizedValue[]
    orderOverride?: contextualizedValue[]
  } & Section)[]
  containerized?: 'medium' | 'small'
  padding?: boolean
  backgroundColor?: string
}

export const SECTION_LAYOUT_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]) => {
  const sections = types.map((type) => type?.sections).filter(Boolean)

  if (sections.length === 0) {
    return ''
  }

  return defineQuery(`
    _type == 'sectionLayout' => {
      _id,
      _type,
      "columns": array::compact(columns[defined(value)]{
        name,
        value,
      }),
      sections[] {
        ...section-> {
          ${sections.map((section): string => `${SECTION_LIST(locale, section as SectionType[])}`)}
        },
        "colSpan": array::compact(colSpan[defined(value)]{
          name,
          value,
        }),
        "rowSpan": array::compact(rowSpan[defined(value)]{
          name,
          value,
        }),
        "orderOverride": array::compact(orderOverride[defined(value)]{
          name,
          value,
        }),
      },
      defined(containerized) => {
        containerized,
      },
      defined(padding) => {
        padding,
      },
      defined(backgroundColor) => {
        'backgroundColor': backgroundColor.value
      },
    }
  `)
}
