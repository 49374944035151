import { DefaultSeoProps, NextSeoProps } from 'next-seo/lib/types'
import { getBaseUrl } from './url.util'
import { imageQuality } from './image.util'
import { Seo } from 'data-access/sanity/fragments/components/seo.fragment'
import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'

const defaultTitle: string = 'MR MARVIS'
const defaultDescription: string = 'MR MARVIS'
const baseUrl: string = getBaseUrl()

// Default SEO configuration
// @see https://github.com/garmeeh/next-seo
export const seo: DefaultSeoProps = {
  titleTemplate: `%s | ${defaultTitle}`,
  defaultTitle: defaultTitle,
  description: defaultDescription,
  openGraph: {
    type: 'website',
    locale: 'en_IE', // EN with €
    url: baseUrl,
    siteName: defaultTitle,
    description: defaultDescription,
  },
}

/**
 * Extract SEO info out of Sanity data object to hydrate next-seo component.
 */

export const seoData = (seo?: Seo, titleFallback?: string, additionalImages: Image[] = []): NextSeoProps => {
  const defaultImageWidth = 1200
  const defaultImageHeight = 627

  return {
    title: seo?.seoTitle || titleFallback,
    description: seo?.seoDescription,
    noindex: seo?.noIndex || false,
    openGraph: {
      images: [
        ...(seo?.seoImage?.url
          ? [
              {
                // Resized on the fly
                // @see https://www.sanity.io/docs/image-urls
                url: `${seo?.seoImage?.url}?fit=clip&h=${defaultImageWidth}&w=${defaultImageHeight}&q=${imageQuality}`,
                type: seo?.seoImage?.url?.endsWith('.png') ? 'image/png' : 'image/jpeg',
                width: defaultImageWidth,
                height: defaultImageHeight,
                alt: seo?.seoTitle || titleFallback,
              },
            ]
          : []),
        ...(additionalImages
          ?.filter((image) => image._type === 'image')
          ?.map((image) => {
            return {
              url: `${image.url}?q=${imageQuality}`,
              type: image.url?.endsWith('.png') ? 'image/png' : 'image/jpeg',
              width: image.width,
              height: image.height,
              alt: seo?.seoTitle || titleFallback,
            }
          }) || []),
      ],
    },
  }
}
