import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Link, LINK_FRAGMENT } from './link.fragment'
import { defineQuery } from 'next-sanity'

export interface Cta {
  variant: 'primary' | 'secondary' | 'tertiary' | 'transparent' | 'secondaryTransparent' | 'warning'
  buttonText: string
  size: 'small' | 'medium' | 'large'
  icon: 'none' | 'cart'
  link?: Link
}

export const CTA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  variant,
  ${coalesceQuery('buttonText', locale)},
  size,
  icon,
  link-> {
    ${LINK_FRAGMENT(locale)}
  }
`)
