import { useContext } from 'react'
import { MisterCookieConsentContext, MisterCookieConsentContextType } from '../../contexts/MisterCookieConsent.context'

export const useCookieConsentContext = (): MisterCookieConsentContextType => {
  const context = useContext(MisterCookieConsentContext)

  if (context === undefined) {
    throw new Error('The hook useCookieConsentContext() must be used within a MisterCookieConsentContext.')
  }

  return context
}
