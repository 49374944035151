import { Slug } from '../../types'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Link, LINK_FRAGMENT } from './link.fragment'
import { defineQuery } from 'next-sanity'

export interface FooterMenuItem {
  _type: string
  title: string
  titleLink?: Link
  footerMenuItems?: [Link, Collection]
  showInLocales: boolean
}

interface Collection {
  _type: 'collection'
  slug: Slug
  new?: boolean
  collectionTitle: string
}

export const FOOTER_MENU_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  ${coalesceQuery('title', locale)},
  defined(titleLink) => {
    titleLink-> {
      ${LINK_FRAGMENT(locale)}
    },
  },
  footerMenuItems [@->.showInLocales[$locale] != false]-> {
    ${LINK_FRAGMENT(locale)}
    defined(slug) => {
      ${coalesceQuery('slug', locale)},
    },
    defined(new) => {
      new,
    },
    ${coalesceQuery('title', locale, 'collectionTitle')},
  },
  "showInLocales": showInLocales[$locale],
`)
