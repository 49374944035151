import { defineQuery } from 'next-sanity'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface ProductAnnouncementBar {
  backgroundColor?: {
    hex: string
  }
  textColor?: {
    hex: string
  }
  content?: RichText
  showInLocales?: boolean
}

export const PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  backgroundColor {
    hex
  },
  textColor {
    hex
  },
  ${RICH_TEXT_FRAGMENT(locale, 'content')},
  "showInLocales": showInLocales[$locale],
`)
