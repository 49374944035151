import { defineQuery } from 'next-sanity'
import { coalesceFilter, coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'

export type ParentCollection = {
  title: string
  slug: string
}

export type SectionOverviewCardData = {
  _id: string
  showInLocale: boolean
  isPrimary: boolean
  isNew: boolean
  title: string
  slug: string
  description?: string
  bulletImage?: Media // Used in Pills
  overviewImage?: Media // Used in Overview Cards
}

export type SectionOverviewCardsCollectionData = {
  title: string
  slug: string
  data: SectionOverviewCardData[]
}

export type SectionOverviewCardsData = {
  _id: string
  _type: 'sectionOverviewCards'
  isAutomated: boolean
  isPrimaryCollectionPage: boolean // To determine whether we show overview cards or pills
  stylesOrder: string[]
  collections: SectionOverviewCardsCollectionData[]
}

const commonCollectionFields = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  isPrimary,
  'showInLocale': showInLocales.${locale},
  "isNew": new,
  ${coalesceQuery('title', locale)},
  ${coalesceQuery('slug', locale, 'slug', '.current')},
  ${coalesceQuery('longDescription', locale, 'description')},
  "overviewImage": select(
    defined(collectionCardImage) => collectionCardImage-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    defined(collectionSliderImage) => collectionSliderImage-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    bulletImage-> {
      ${MEDIA_FRAGMENT(locale)}
    }
  ),
  "bulletImage": bulletImage-> {
    ${MEDIA_FRAGMENT(locale)}
  },
`)

export const SECTION_OVERVIEW_CARDS_FRAGMENT = (locale = DEFAULT_LOCALE, slug?: string) =>
  defineQuery(`
  _type == 'sectionOverviewCards' => {
    _id,
    _type,
    "stylesOrder": *[_type == 'stylesOrder'][0].order[]->._id,
    isAutomated,
    ...*[_type == 'collection' && ${coalesceFilter('slug', locale)}.current == "${slug}"][0] {
      "isPrimaryCollectionPage": isPrimary,
      "relatedCollections": select(
        isPrimary == true => parentCollection[]-> {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)}.current,
          "data": *[_type == 'collection' && isPrimary == true && showInLocales[$locale] != false && references(^.products[]->._id)][0...20] | order(_id asc) {
            ${commonCollectionFields(locale)}
          }
        },
        {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)}.current,
          "data": *[_type == 'collection' && isPrimary == true && showInLocales[$locale] != false && references(^.products[]->._id)][0...20] | order(_id asc) {
            ${commonCollectionFields(locale)}
          }
        }
      ),
    "manualCollections": [{
      ...parentCollection[0]-> {
        ${coalesceQuery('title', locale)},
        ${coalesceQuery('slug', locale)}.current,
      },
      "data": ^.manualCollections[]-> {
        ${commonCollectionFields(locale)}
        }
      }]
    }
  } {
    _id,
    _type,
    stylesOrder,
    isAutomated,
    isPrimaryCollectionPage,
    "collections": select(
      isAutomated == false => manualCollections,
      relatedCollections
    )
  }
`)
