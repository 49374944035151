import { coalesceFilter, DEFAULT_LOCALE } from '../../utils'
import { SectionType } from '../common/sectionTypes.fragment'
import { Section, SECTION_LIST } from '../pages/sections.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionLocalizerData {
  _id: string
  _type: 'sectionLocalizer'
  fallback: boolean
  section: Section
}

export const SECTION_LOCALIZER_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]): string => {
  const nestedTypes = types.map((type) => type?.localizedSections).filter(Boolean) || []

  if (nestedTypes.length === 0) {
    return ''
  }

  return defineQuery(`
    _type == 'sectionLocalizer' => {
      _id,
      _type,
      fallback,
      'section': select(
        fallback == true => ${coalesceFilter('section', locale)},
        section[$locale]
      )->{
        ${SECTION_LIST(locale, nestedTypes as SectionType[])}
      }
    }
  `)
}
