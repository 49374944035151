import { defineQuery } from 'next-sanity'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'

export interface SectionMediaData {
  _type: 'sectionMedia'
  _id: string
  media?: Media
  desktopView: 'single' | 'singleMediaArticle'
  padding: boolean
}

export const SECTION_MEDIA_FRAGMENT = (locale: string = 'en') =>
  defineQuery(`
  _type == 'sectionMedia' => {
    _type,
    _id,
    defined(media) => {
      media-> {
        ${MEDIA_FRAGMENT(locale)}
      },
    },
    desktopView,
    padding,
  }
`)
