import { defineQuery } from 'next-sanity'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionShippingInformationData {
  _type: 'sectionShippingInformation'
  _id: string
  showSearchbar: boolean
  extraInformationHeader?: string
  extraInformation?: RichText
  shipmentAreas?: ShipmentArea[]
}

export interface ShipmentArea {
  countries: {
    name: string
    countryCode: string
  }[]
  deliveryTime: DeliveryTime
  shippingCosts: DeliveryCosts
  returnCosts: ReturnCosts
}

export interface DeliveryTime {
  type: 'orderedBy' | 'businessDays'
  orderBefore?: string
  businessDays?: string
}

export interface DeliveryCosts {
  type: 'free' | 'freeFrom' | 'fixed' | 'custom'
  freeFromPrice?: number
  otherwisePrice?: number
  price?: number
  custom?: string
}

export interface ReturnCosts {
  type: 'free' | 'custom'
  custom?: string
}

export const SECTION_SHIPPING_INFORMATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionShippingInformation' => {
    _type,
    _id,
    showSearchbar,
    defined(extraInformationHeader) => {
      ${coalesceQuery('extraInformationHeader', locale)}
      },
    defined(extraInformation) => {
      ${RICH_TEXT_FRAGMENT(locale, 'extraInformation')}
    },
    shipmentAreas[]-> {
      countries[]-> {
        ${coalesceQuery('name', locale)},
        countryCode,
      },
      deliveryTime {
        type,
        orderBefore,
        businessDays,
      },
      shippingCosts {
        type,
        defined(freeFromPrice) => {
          ${coalesceQuery('freeFromPrice', locale)}
        },
        defined(otherwisePrice) => {
          ${coalesceQuery('otherwisePrice', locale)}
        },
        defined(price) => {
          ${coalesceQuery('price', locale)}
        },
        defined(custom) => {
          ${coalesceQuery('custom', locale)}
        },
      },
      returnCosts {
        type,
        defined(freeFromPrice) => {
          ${coalesceQuery('freeFromPrice', locale)}
        },
        defined(custom) => {
          ${coalesceQuery('custom', locale)}
        },
      },
    }
  }
`)
