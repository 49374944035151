import { ShopifyStoreConfig, ShopifyStoreConfigWithStorefrontConfig, StoreLocale } from './types'

export const stores: ShopifyStoreConfig[] = [
  ...(process.env.NODE_ENV === 'development'
    ? [
        // Staging Store for Development
        {
          storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_STAGING || '',
          customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_STAGING || '',
          storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_STAGING || '',
          storeLocale: 'en' as StoreLocale,
          default: true,
        },
      ]
    : [
        // International Store / EN store
        {
          storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EN || '',
          customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_EN || '',
          storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_EN || '',
          storeLocale: 'en' as StoreLocale,
          default: true,
        },
      ]),
  // NL Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_NL || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_NL || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_NL || '',
    storeLocale: 'nl' as StoreLocale,
    default: false,
  },
  // UK Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_UK || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_UK || '',
    storeLocale: 'gb' as StoreLocale,
    default: false,
  },
  // ES Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_ES || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_ES || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_ES || '',
    storeLocale: 'es' as StoreLocale,
    default: false,
  },
  // DK Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DK || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_DK || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_DK || '',
    storeLocale: 'dk' as StoreLocale,
    default: false,
  },
  // IT Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_IT || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_IT || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_IT || '',
    storeLocale: 'it' as StoreLocale,
    default: false,
  },
  // DE Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DE || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_DE || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_DE || '',
    storeLocale: 'de' as StoreLocale,
    default: false,
  },
  // FR Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_FR || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_FR || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_FR || '',
    storeLocale: 'fr' as StoreLocale,
    default: false,
  },
  // CH Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_CH || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_CH || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_CH || '',
    storeLocale: 'ch' as StoreLocale,
    default: false,
  },
  // RETAIL Store
  {
    storeDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_RETAIL || '',
    customStoreDomain: process.env.NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_RETAIL || '',
    storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_RETAIL || '',
    storeLocale: 'retail' as StoreLocale,
    default: false,
  },
]

export const preparePayload = (query: string, variables?: Record<string, any>) => ({
  query,
  variables,
})

export const shopifyAdminApiToken = (storeDomain: string): Readonly<string> => {
  if (storeDomain === 'mr-marvis-stage.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_STAGING || ''
  } else if (storeDomain === 'mr-gustav-amsterdam.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_EN || ''
  } else if (storeDomain === 'mr-marvis-netherlands.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_NL || ''
  } else if (storeDomain === 'mr-marvis-uk.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_UK || ''
  } else if (storeDomain === 'mr-marvis-es.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_ES || ''
  } else if (storeDomain === 'mr-marvis-dk.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_DK || ''
  } else if (storeDomain === 'mr-marvis-it.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_IT || ''
  } else if (storeDomain === 'mr-marvis-de.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_DE || ''
  } else if (storeDomain === 'mr-marvis-fr.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_FR || ''
  } else if (storeDomain === 'mr-marvis-ch.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_CH || ''
  }

  throw new Error('Invalid Shopify store domain.')
}

export const prepareStore = (locale = 'en'): ShopifyStoreConfigWithStorefrontConfig => {
  const store = stores?.find(({ storeLocale }) => storeLocale === locale) || stores?.find((s) => s.default)

  if (!store) {
    throw new Error('Store config not found.')
  }

  return {
    ...store,
    storefrontConfig: {
      Accept: 'application/json',
      'Accept-Language': 'en',
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': store?.storefrontAccessToken || '',
    },
  }
}

export function isStoreLocale(locale: unknown): locale is StoreLocale {
  const validLocales: StoreLocale[] = ['en', 'nl', 'gb', 'es', 'dk', 'it', 'de', 'fr', 'ch', 'retail']
  return typeof locale === 'string' && validLocales.includes(locale as StoreLocale)
}
