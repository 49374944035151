import { Section, SECTION_LIST } from '../pages/sections.fragment'
import { defineQuery } from 'next-sanity'
import { SectionType } from '../common/sectionTypes.fragment'
import { DEFAULT_LOCALE } from '../../utils'

type ContextualizedCss = {
  _type: 'object'
  _key: 'base' | 'md' | 'lg'
  name: 'base' | 'md' | 'lg'
  value: string
}

export interface SectionAbTesterData {
  _id: string
  _type: 'sectionAbTester'
  enabled: boolean
  name: string
  featureFlag: string
  controlSection: Section
  alternativeSection: Section
  alternativeSectionVariantKey: string
  cssAspectRatio?: ContextualizedCss[]
  cssMinHeight?: ContextualizedCss[]
}

export const SECTION_AB_TESTER_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]): string => {
  const controlSectionType = types.flatMap((type) => type?.controlSection || []).filter(Boolean) || []
  const alternativeSectionType = types.flatMap((type) => type?.alternativeSection || []).filter(Boolean) || []

  if (controlSectionType.length === 0 || alternativeSectionType.length === 0) {
    return ''
  }

  return defineQuery(`
  _type == 'sectionAbTester' => {
    ...,
    controlSection->{
     ${SECTION_LIST(locale, controlSectionType)}
    },
    alternativeSection->{
     ${SECTION_LIST(locale, alternativeSectionType)}
    },
  }
`)
}
