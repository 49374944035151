import { ShopifyCart, ShopifyUserErrorWithoutCode } from '../../types'
import { CART_QUERY } from './cartQuery.fragment'

export interface CreateCartDto {
  data: {
    cartCreate: {
      cart: ShopifyCart
      checkoutUserErrors: ShopifyUserErrorWithoutCode[]
    }
  }
}

// @see https://shopify.dev/docs/api/storefront/2024-01/mutations/cartCreate
export const CREATE_CART_MUTATION = `mutation cartCreate($input: CartInput!) {
  cartCreate(input: $input) {
    cart {
      ${CART_QUERY}
    }
    userErrors {
      field
      message
    }
  }
}`
