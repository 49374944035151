import { DEFAULT_LOCALE } from '../../utils'
import { SECTION_TEXT_FRAGMENT, SectionTextData } from './sectionText.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionTwoColumnsData {
  _type: 'sectionTwoColumns'
  _id: string
  sections: SectionTextData[]
  width: 'default' | 'fluid' | 'small'
}

export const SECTION_TWO_COLUMNS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionTwoColumns' => {
    _id,
    _type,
    sections[]-> {
      ${SECTION_TEXT_FRAGMENT(locale)}
    },
    width,
  }
`)
