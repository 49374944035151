import { SectionType } from '../common/sectionTypes.fragment'
import { defineQuery } from 'next-sanity'
import { DEFAULT_LOCALE } from '../../utils'
import { SECTION_BANNER_CTA_FRAGMENT, SectionBannerCtaData } from '../sections/sectionBannerCta.fragment'
import { SECTION_CONTACT_FRAGMENT, SectionContactData } from '../sections/sectionContact.fragment'
import { SECTION_TEXT_FRAGMENT, SectionTextData } from '../sections/sectionText.fragment'
import { SECTION_STORE_FRAGMENT, SectionStoreData } from '../sections/sectionStore.fragment'
import { SECTION_COOKIE_SETTINGS_FRAGMENT, SectionCookieSettingsData } from '../sections/sectionCookieSettings.fragment'
import { SECTION_MEDIA_FRAGMENT, SectionMediaData } from '../sections/sectionMedia.fragment'
import { SECTION_IMAGE_TEXT_LIST_FRAGMENT, SectionImageTextListData } from '../sections/sectionImageTextList.fragment'
import { SECTION_SIZE_CHART_FRAGMENT, SectionSizeChartsData } from '../sections/sectionSizeCharts.fragment'
import { SECTION_TEXT_MEDIA_CTA_FRAGMENT, SectionTextMediaCtaData } from '../sections/sectionTextMediaCta.fragment'
import { SECTION_PADDING_FRAGMENT, SectionPaddingData } from '../sections/sectionPadding.fragment'
import { SECTION_NAVIGATION_FRAGMENT, SectionNavigationData } from '../sections/sectionNavigation.fragment'
import { SECTION_RETURN_FORM_FRAGMENT, SectionReturnFormData } from '../sections/sectionReturnForm.fragment'
import { SECTION_SLIDER_FRAGMENT, SectionSliderData } from '../sections/sectionSlider.fragment'
import { SECTION_AFTERSHIP_FRAGMENT, SectionAftershipData } from '../sections/sectionAftership.fragment'
import { SECTION_WISHLIST_FRAGMENT, SectionWishlistData } from '../sections/sectionWishlist.fragement'
import { SECTION_SEARCH_RESULTS_FRAGMENT, SectionSearchResultsData } from '../sections/sectionSearchResults.fragment'
import { SECTION_ERROR_FRAGMENT, SectionErrorData } from '../sections/sectionError.fragment'
import { SECTION_TWO_COLUMNS_FRAGMENT, SectionTwoColumnsData } from '../sections/sectionTwoColumns.fragment'
import { SECTION_STORIES_FRAGMENT, SectionStoriesData } from '../sections/sectionStories.fragment'
import { SECTION_BANNER_CAROUSEL_FRAGMENT, SectionBannerCarouselData } from '../sections/sectionBannerCarousel.fragment'
import { SECTION_LOGIN_FRAGMENT, SectionLoginData } from '../sections/sectionLogin.fragment'
import { SECTION_FORGOT_PASSWORD_FRAGMENT, SectionForgotPasswordData } from '../sections/sectionForgotPassword.fragment'
import { SECTION_REGISTRATION_FRAGMENT, SectionRegistrationData } from '../sections/sectionRegistration.fragment'
import { SECTION_ACTIVATE_ACCOUNT_FRAGMENT, SectionActivateAccountData } from '../sections/sectionActivateAccount.fragment'
import { SECTION_RESET_PASSWORD_FRAGMENT, SectionResetPasswordData } from '../sections/sectionResetPassword.fragment'
import { SECTION_INVALID_TOKEN_FRAGMENT, SectionInvalidTokenData } from '../sections/sectionInvalidToken.fragment'
import { SECTION_ORDERS_OVERVIEW_FRAGMENT, SectionOrdersOverviewData } from '../sections/sectionOrdersOverview.fragment'
import { SECTION_PERSONAL_INFO_FRAGMENT, SectionPersonalInfoData } from '../sections/sectionPersonalInfo.fragment'
import { SECTION_BUSINESS_REVIEWS_FRAGMENT, SectionBusinessReviewsData } from '../sections/sectionBusinessReviews.fragment'
import { SECTION_ARTICLE_OVERVIEW_FRAGMENT, SectionArticleOverviewData } from '../sections/sectionArticleOverview.fragment'
import { SECTION_SEARCH_FILTERS_GRID_FRAGMENT, SectionSearchFiltersGridData } from '../sections/sectionSearchFiltersGrid.fragment'
import { SECTION_OVERVIEW_CARDS_FRAGMENT, SectionOverviewCardsData } from '../sections/sectionOverviewCards.fragment'
import { SECTION_HIGHLIGHTS_FRAGMENT, SectionHighlightsData } from '../sections/sectionHighlights.fragment'
import { SECTION_STORE_INDEX_FRAGMENT, SectionStoreIndexData } from '../sections/sectionStoreIndex.fragment'
import { SECTION_LOCALIZER_FRAGMENT, SectionLocalizerData } from '../sections/sectionLocalizer.fragment'
import { SECTION_FAQ_FRAGMENT, SectionFaqData } from '../sections/sectionFaq.fragment'
import { SECTION_SHIPPING_INFORMATION_FRAGMENT, SectionShippingInformationData } from '../sections/sectionShippingInformation.fragment'
import { SECTION_CARE_FRAGMENT, SectionCareData } from '../sections/sectionCare.fragment'
import { SECTION_BULLET_POINTS_FRAGMENT, SectionBulletPointsData } from '../sections/sectionBulletPoints.fragment'
import { SECTION_HALF_AND_HALF_FRAGMENT, SectionHalfAndHalfData } from '../sections/sectionHalfAndHalf.fragment'
import { SECTION_SUPPORT_OVERVIEW_FRAGMENT, SectionSupportOverviewData } from '../sections/sectionSupportOverview.fragment'
import { SECTION_SUPPORT_INFO_FRAGMENT, SectionSupportInfoData } from '../sections/sectionSupportInfo.fragment'
import { SECTION_LAYOUT_FRAGMENT, SectionLayoutData } from '../sections/sectionLayout.fragment'
import { SECTION_AB_TESTER_FRAGMENT, SectionAbTesterData } from '../sections/sectionAbTester.fragment'
import { SECTION_TAGGED_IMAGE_FRAGMENT, SectionTaggedImageData } from '../sections/sectionTaggedImage.fragment'
import { SECTION_PRODUCT_CARD_FRAGMENT, SectionProductCardData } from '../sections/sectionProductCard.fragment'
import { SECTION_PROMO_FRAGMENT, SectionPromoData } from '../sections/sectionPromo.fragment'
import { SECTION_IMAGE_LINK_FRAGMENT, SectionImageLinkData } from '../sections/sectionImageLink.fragment'

export type Section =
  | SectionBannerCtaData
  | SectionTextData
  | SectionTextMediaCtaData
  | SectionImageTextListData
  | SectionMediaData
  | SectionContactData
  | SectionSliderData
  | SectionArticleOverviewData
  | SectionStoreData
  | SectionSizeChartsData
  | SectionPaddingData
  | SectionCookieSettingsData
  | SectionNavigationData
  | SectionReturnFormData
  | SectionAftershipData
  | SectionStoriesData
  | SectionBannerCarouselData
  | SectionWishlistData
  | SectionSearchResultsData
  | SectionErrorData
  | SectionTwoColumnsData
  | SectionLoginData
  | SectionForgotPasswordData
  | SectionRegistrationData
  | SectionActivateAccountData
  | SectionResetPasswordData
  | SectionBusinessReviewsData
  | SectionArticleOverviewData
  | SectionFaqData
  | SectionOverviewCardsData
  | SectionSearchFiltersGridData
  | SectionHighlightsData
  | SectionShippingInformationData
  | SectionCareData
  | SectionBulletPointsData
  | SectionHalfAndHalfData
  | SectionInvalidTokenData
  | SectionOrdersOverviewData
  | SectionStoreIndexData
  | SectionLocalizerData
  | SectionSupportOverviewData
  | SectionSupportInfoData
  | SectionLayoutData
  | SectionAbTesterData
  | SectionPersonalInfoData
  | SectionTaggedImageData
  | SectionProductCardData
  | SectionPromoData
  | SectionImageLinkData

export const SECTION_LIST = (locale = DEFAULT_LOCALE, types: (SectionType | { _type: string })[], slug?: string, secondSlug?: string) => {
  const sectionTypes = types.map((type) => type._type)

  return defineQuery(
    [
      // Common Sections
      sectionTypes.includes('sectionStories') ? SECTION_STORIES_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionBannerCarousel') ? SECTION_BANNER_CAROUSEL_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionBannerCta') ? SECTION_BANNER_CTA_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionText') ? SECTION_TEXT_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionTwoColumns') ? SECTION_TWO_COLUMNS_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionMedia') ? SECTION_MEDIA_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionContact') ? SECTION_CONTACT_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionStore') ? SECTION_STORE_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionCookieSettings') ? SECTION_COOKIE_SETTINGS_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionImageTextList') ? SECTION_IMAGE_TEXT_LIST_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionSizeCharts') ? SECTION_SIZE_CHART_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionTextMediaCta') ? SECTION_TEXT_MEDIA_CTA_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionPadding') ? SECTION_PADDING_FRAGMENT() : null,
      sectionTypes.includes('sectionNavigation') ? SECTION_NAVIGATION_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionReturnForm') ? SECTION_RETURN_FORM_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionSlider') ? SECTION_SLIDER_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionAftership') ? SECTION_AFTERSHIP_FRAGMENT() : null,
      sectionTypes.includes('sectionWishlist') ? SECTION_WISHLIST_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionSearchResults') ? SECTION_SEARCH_RESULTS_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionError') ? SECTION_ERROR_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionLogin') ? SECTION_LOGIN_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionForgotPassword') ? SECTION_FORGOT_PASSWORD_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionRegistration') ? SECTION_REGISTRATION_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionActivateAccount') ? SECTION_ACTIVATE_ACCOUNT_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionResetPassword') ? SECTION_RESET_PASSWORD_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionInvalidToken') ? SECTION_INVALID_TOKEN_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionBusinessReviews') ? SECTION_BUSINESS_REVIEWS_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionArticleOverview') ? SECTION_ARTICLE_OVERVIEW_FRAGMENT(locale, secondSlug ? secondSlug : slug) : null,
      sectionTypes.includes('sectionStoreIndex') ? SECTION_STORE_INDEX_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionShippingInformation') ? SECTION_SHIPPING_INFORMATION_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionCare') ? SECTION_CARE_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionBulletPoints') ? SECTION_BULLET_POINTS_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionFaq') ? SECTION_FAQ_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionSupportOverview') ? SECTION_SUPPORT_OVERVIEW_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionSupportInfo') ? SECTION_SUPPORT_INFO_FRAGMENT(locale) : null,
      sectionTypes.includes('shopTheLookImage') || sectionTypes.includes('shopTheLookImage') ? SECTION_TAGGED_IMAGE_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionProductCard') ? SECTION_PRODUCT_CARD_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionPromo') ? SECTION_PROMO_FRAGMENT(locale) : null,
      sectionTypes.includes('sectionImageLink') ? SECTION_IMAGE_LINK_FRAGMENT(locale) : null,

      // Orchestration Sections
      sectionTypes.includes('sectionLayout') ? SECTION_LAYOUT_FRAGMENT(locale, types as SectionType[]) : null,
      sectionTypes.includes('sectionLocalizer') ? SECTION_LOCALIZER_FRAGMENT(locale, types as SectionType[]) : null,
      sectionTypes.includes('sectionHalfAndHalf') ? SECTION_HALF_AND_HALF_FRAGMENT(locale, types as SectionType[]) : null,
      sectionTypes.includes('sectionAbTester') ? SECTION_AB_TESTER_FRAGMENT(locale, types as SectionType[]) : null,

      // Auth Page only Sections
      sectionTypes.includes('sectionOrdersOverview') ? SECTION_ORDERS_OVERVIEW_FRAGMENT() : null,
      sectionTypes.includes('sectionPersonalInfo') ? SECTION_PERSONAL_INFO_FRAGMENT() : null,

      // Collection Page Only Sections
      sectionTypes.includes('sectionOverviewCards') ? SECTION_OVERVIEW_CARDS_FRAGMENT(locale, slug) : null,
      sectionTypes.includes('sectionSearchFiltersGrid') ? SECTION_SEARCH_FILTERS_GRID_FRAGMENT(locale, slug) : null,
      sectionTypes.includes('sectionHighlights') ? SECTION_HIGHLIGHTS_FRAGMENT(locale) : null,
    ]
      .filter(Boolean)
      .join(','),
  )
}

export const SECTIONS_FRAGMENT = (locale = DEFAULT_LOCALE, types: { _type: string; _id: string }[], slug?: string, secondSlug?: string) => {
  return defineQuery(`
    sections[]-> {
      ${SECTION_LIST(locale, types, slug, secondSlug)}
    }
  `)
}
