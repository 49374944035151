import { defineQuery } from 'next-sanity'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionActivateAccountData {
  _id: string
  _type: 'sectionActivateAccount'
  title: string
  subtitle?: string
  backgroundImage: Media
  passwordLabel: string
  passwordErrorMessage: string
  confirmPasswordLabel: string
  passwordMatchErrorMessage: string
  submitButtonText: string
}

export const SECTION_ACTIVATE_ACCOUNT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionActivateAccount' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT(locale)}
    },
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    ${coalesceQuery('confirmPasswordLabel', locale)},
    ${coalesceQuery('passwordMatchErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
  }
`)
