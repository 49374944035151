import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { SIZE_CHART_MODAL_FRAGMENT, SizeGuideModal } from '../components/sizeCharts.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionSizeChartsData {
  _type: 'sectionSizeCharts'
  _id: string
  buttonText?: string
  sizeGuideModals: SizeGuideModal[]
}

export const SECTION_SIZE_CHART_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSizeCharts' => {
    _type,
    _id,
    defined(buttonText) => {
      ${coalesceQuery('buttonText', locale)},
    },
    'sizeGuideModals': *[_type == 'sizeGuideSettings'][0].collections[@->.showInLocales[$locale] != false && @->.sizeCharts != null]-> {
      ${SIZE_CHART_MODAL_FRAGMENT(locale)}
    },
  }
`)
