import { createBreakpoint } from 'react-use'

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | string

// Create breakpoints that follow TailwindCSS
// @see https://tailwindcss.com/docs/screens
// @see https://github.com/streamich/react-use/blob/master/docs/createBreakpoint.md
const breakpoints = createBreakpoint({ '3xl': 1600, '2xl': 1400, xl: 1280, lg: 1024, md: 768, sm: 640, xs: 390, _: 0 })

export const useBreakpoint = (): Breakpoint => {
  return breakpoints()
}
