import { FunctionComponent } from 'react'
import { useKey } from 'react-use'

const MisterAccessibility: FunctionComponent = () => {
  // Add class to body as to introduce focus rings to tabbable elements;
  // we opt to not show this initially since it is off brand.
  // @see apps/store/src/styles/globals.css
  useKey('Tab', () => document.body.classList.add('is-tabbing'))

  return null
}

export default MisterAccessibility
