import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Media, MEDIA_FRAGMENT } from './media.fragment'
import { RichText } from './richText.fragment'

export interface SizeChart {
  _type: string
  _id: string
  title?: string
  description?: string
  headers?: SizeChartHeader[]
  tableData?: TableData
  tableDataInch?: TableData
}

interface SizeChartHeader {
  headerText: SizeChartHeaderText
  image?: Media
}

interface SizeChartHeaderText {
  title: string
  description?: RichText
}

interface TableData {
  rows: [
    {
      cells: [string]
    },
  ]
}

export const SIZE_CHARTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  sizeCharts[@->.showInLocales[$locale] != false]-> {
    _type,
    _id,
    defined(title) => {
      ${coalesceQuery('title', locale)},
    },
    defined(description) => {
      ${coalesceQuery('description', locale)},
    },
    defined(headers) => {
      headers[] {
        headerText-> {
          ${coalesceQuery('title', locale)},
          defined(description) => {
            ${coalesceQuery('description', locale)}
          },
        },
        image-> {
          ${MEDIA_FRAGMENT(locale)}
        }
      }
    },
    defined(sizeChart) => {
      'tableData': sizeChart
    },
    defined(sizeChartInch) => {
      'tableDataInch': sizeChartInch
    }
  }
`)
