export const CART_QUERY = `
  id
  note
  attributes {
    key
    value
  }
  buyerIdentity {
    countryCode
  }
  cost {
    subtotalAmount {
      amount
      currencyCode
    }
    totalAmount {
      amount
      currencyCode
    }
  }
  lines(first: 250) {
    edges {
      node {
        id
        quantity
        attributes {
          key
          value
        }
        discountAllocations {
          discountedAmount {
            amount
          }
        }
        merchandise {
          ... on ProductVariant {
            id
            sku
            title
            availableForSale
            image {
              transformedSrc(preferredContentType: WEBP, maxWidth: 190)
            }
            selectedOptions {
              name
              value
            }
            price {
              amount
              currencyCode
            }
            product {
              id
              title
              handle
              tags
              productType
            }
          }
        }
      }
    }
  }
  checkoutUrl
  createdAt
`
