import { defineQuery } from 'next-sanity'
import { coalesceFilter, DEFAULT_LOCALE } from '../../utils'

export interface SectionType {
  _id: string
  _type: string
  localizedSections?: {
    _id: string
    _type: string
  }
  sections?: {
    _id: string
    _type: string
  }[]
  leftSections?: {
    _id: string
    _type: string
  }[]
  rightSections?: {
    _id: string
    _type: string
  }[]
  controlSection?: {
    _id: string
    _type: string
  }
  alternativeSection?: {
    _id: string
    _type: string
  }
}

export const SECTION_TYPES_FRAGMENT = (locale = DEFAULT_LOCALE, count = 1): string => {
  if (count > 3) {
    return defineQuery(`
    _id,
    _type
  `)
  }
  return defineQuery(`
    _id,
    _type,
    _type == "sectionLocalizer" => {
      fallback,
      "localizedSections": select(
        fallback == true => ${coalesceFilter('section', locale)},
        section[$locale]
      )->{
        ${SECTION_TYPES_FRAGMENT(locale, count + 1)},
      }
    },
    _type == "sectionAbTester" => {
      controlSection-> {
        _id,
        _type
      },
      alternativeSection-> {
        _id,
        _type
      },
    },
    _type == "sectionLayout" => {
      sections[] {
        ...section-> {
          ${SECTION_TYPES_FRAGMENT(locale, count + 1)},
        }
      }
    },
    _type == "sectionHalfAndHalf" => {
      leftSections[]->{
        _id,
        _type
      },
      rightSections[]->{
        _id,
        _type
      }
    }
`)
}
