import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionForgotPasswordData {
  _type: 'sectionForgotPassword'
  _id: string
  title: string
  subtitle?: string
  backgroundImage: Media
  emailLabel: string
  emailErrorMessage: string
  submitSuccessTitle: string
  submitSuccessSubtitle?: string
  submitButtonText: string
  returnToLoginLink: Link
}

export const SECTION_FORGOT_PASSWORD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionForgotPassword' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage -> {
      ${MEDIA_FRAGMENT(locale)}
    },
    ${coalesceQuery('emailLabel', locale)},
    ${coalesceQuery('emailErrorMessage', locale)},
    ${coalesceQuery('submitSuccessTitle', locale)},
    defined(submitSuccessSubtitle) => {
      ${coalesceQuery('submitSuccessSubtitle', locale)}
    },
    ${coalesceQuery('submitButtonText', locale)},
    returnToLoginLink-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
