import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { CUSTOMER_SERVICE_FRAGMENT, CustomerService } from '../globals/customerService.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionSupportOverviewData {
  _type: 'sectionSupportOverview'
  _id: string
  title: string
  description?: RichText
  pages?: SupportPage[]
  customerServiceData: CustomerService
}

export interface SupportPage {
  title: string
  link: Link
  icon: string
}

export const SECTION_SUPPORT_OVERVIEW_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSupportOverview' => {
    _type,
    _id,
    ${coalesceQuery('title', locale)},
    defined(description) => {
      ${RICH_TEXT_FRAGMENT(locale, 'description')}
    },
    pages[@->.showInLocales[$locale] != false]-> {
      ${coalesceQuery('title', locale)},
      link-> {
        ${LINK_FRAGMENT(locale)}
      },
      icon,
    },
    ${CUSTOMER_SERVICE_FRAGMENT(locale)}
  }
`)
