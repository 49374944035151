import { defineQuery } from 'next-sanity'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface ServiceUspSet {
  serviceUsps: {
    icon: 'tick' | 'warning'
    text: RichText
    openAccordionItem: 'description' | 'sizing' | 'material' | 'details' | 'policies'
  }[]
}

export const SERVICE_USPS_SET_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  serviceUsps[]-> {
    icon,
    ${RICH_TEXT_FRAGMENT(locale, 'text')},
    openAccordionItem
  }
`)
