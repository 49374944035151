import { defineQuery } from 'next-sanity'
import { Cta, CTA_FRAGMENT } from '../components/cta.fragment'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionBannerCtaData {
  _type: 'sectionBannerCta'
  _id: string
  name: string
  title: string
  titleCase?: 'uppercase' | 'capitalised'
  titleFont: 'default' | 'aw24' | 'festive'
  hideTitle?: boolean
  subtitle?: string
  textShadowTitle?: 'default' | 'large'
  textShadowSubtitle?: 'default' | 'large'
  padding?: boolean
  copyPosition?: 'left' | 'middle' | 'right'
  copyPositionDesktop?: 'left' | 'middle' | 'right'
  copyPositionVertical?: 'middle' | 'bottom'
  copyPositionVerticalDesktop?: 'middle' | 'bottom'
  darkTitle?: {
    mobile: boolean
    desktop: boolean
  }
  darkSubtitle?: {
    mobile: boolean
    desktop: boolean
  }
  ctaToBottom: {
    mobile: boolean
    desktop: boolean
  }
  media: Media
  cta?: Cta
}

export const SECTION_BANNER_CTA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBannerCta' => {
    _type,
    _id,
    name,
    ${coalesceQuery('title', locale)},
    defined(titleCase) => {titleCase},
    defined(titleFont) => {titleFont},
    defined(hideTitle) => {hideTitle},
    defined(subtitle) => {${coalesceQuery('subtitle', locale)}},
    defined(textShadowTitle) => {textShadowTitle},
    defined(textShadowSubtitle) => {textShadowSubtitle},
    defined(padding) => {padding},
    defined(copyPosition) => {copyPosition},
    defined(copyPositionDesktop) => {copyPositionDesktop},
    defined(copyPositionVertical) => {copyPositionVertical},
    defined(copyPositionVerticalDesktop) => {copyPositionVerticalDesktop},
    defined(ctaToBottom) => {ctaToBottom},
    defined(darkTitle) => {darkTitle},
    defined(darkSubtitle) => {darkSubtitle},
    defined(media) => {
      media-> {
        ${MEDIA_FRAGMENT(locale)}
      },
    },
    defined(cta) => {
      cta-> {
        ${CTA_FRAGMENT(locale)}
      }
    },
  }
`)
