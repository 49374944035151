import { isStoreLocale, prepareStore, shopifyAdminApiToken } from './utils'

const fetchShopify = async ({
  locale,
  payload,
  withAdmin = false,
  useCustomDomain = false,
}: {
  locale?: string | null
  payload: unknown
  withAdmin?: boolean
  useCustomDomain?: boolean
}) => {
  if (!locale || !isStoreLocale(locale)) {
    throw new Error('Locale not found')
  }

  const { storefrontConfig, storeDomain, customStoreDomain } = prepareStore(locale) || {}

  const response = await fetch(
    `https://${useCustomDomain ? customStoreDomain : storeDomain}${withAdmin ? '/admin' : ''}/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`,
    {
      method: 'POST',
      headers: withAdmin
        ? {
            ...storefrontConfig,
            'X-Shopify-Access-Token': shopifyAdminApiToken(storeDomain),
          }
        : storefrontConfig,
      body: JSON.stringify(payload),
    },
  )

  const json = await response.json()

  // Throw error for errors with 200 status
  if (json?.errors) {
    const errorMessage = json.errors[0]?.message
    const extensionCode = json.errors[0]?.extensions?.code
    throw new Error(errorMessage || `[${extensionCode}]\n\n${JSON.stringify(['THROTTLED', 'TIMEOUT'].includes(extensionCode) ? payload : json.errors, null, 2)}`)
  }

  return json
}

export default fetchShopify
