import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { IMAGE_TEXT_LIST_ITEM_FRAGMENT, ImageTextListItem } from '../components/imageTextListItem.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionImageTextListData {
  _type: 'sectionImageTextList'
  _id: string
  name: string
  title?: string
  backgroundColor?: {
    hex: string
  }
  backgroundColorExtended?: boolean
  direction: 'row' | 'column'
  imageTextListItems?: ImageTextListItem[]
}

export const SECTION_IMAGE_TEXT_LIST_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionImageTextList' => {
    _type,
    _id,
    name,
    defined(title) => {
      ${coalesceQuery('title', locale)},
    },
    backgroundColor {
      hex
    },
    backgroundColorExtended,
    direction,
    defined(imageTextListItems) => {
      imageTextListItems[]-> {
        ${IMAGE_TEXT_LIST_ITEM_FRAGMENT(locale)}
      },
    },
  }
`)
