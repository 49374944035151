import { defineQuery } from 'next-sanity'
import { Media, MEDIA_FRAGMENT } from './media.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface Color {
  _id: string
  _type: string
  hexCode: {
    hex: string
  }
  image?: Media
  colorName: string
  descriptiveName?: string
  colorQuote?: string
}

export const COLOR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  _type,
  hexCode {
    hex,
  },
  defined(image) => {
    image -> {
      ${MEDIA_FRAGMENT(locale)}
    }
  },
  colorName,
  ${coalesceQuery('descriptiveName', locale)},
  defined(colorQuote) => {
    ${coalesceQuery('colorQuote', locale)},
  }
`)
