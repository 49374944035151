import { Slug } from 'data-access/sanity/types'
import { ParsedUrlQuery } from 'querystring'

export const getBaseUrl = (): Readonly<string> => {
  // Initially set url to NEXT_PUBLIC_SITE_URL or empty string
  let url = process?.env?.NEXT_PUBLIC_SITE_URL || ''

  // Client-side: Use browser's current URL
  if (typeof window !== 'undefined') {
    url = window.location.origin
  }
  // Server-side: Vercel preview environment
  else if (process.env?.NEXT_PUBLIC_VERCEL_ENV === 'preview') {
    url = `https://${process?.env?.NEXT_PUBLIC_VERCEL_URL || ''}`
  }
  // Server-side: Local development
  else if (process?.env?.NODE_ENV === 'development') {
    url = 'http://localhost:3000'
  }

  // Remove any trailing slashes
  return url.replace(/\/+$/, '')
}

const siteUrl: string = getBaseUrl()

export const getSiteUrl = (path = '/'): Readonly<string> => {
  return `${siteUrl}${path}`
}

export const getQueryParam = (key: string, query?: ParsedUrlQuery): string | undefined => {
  const value = query?.[key]

  return Array.isArray(value) ? value[0] : value
}

export const buildUrl = (sanityDocument: { _type?: string; slug?: Slug; [key: string]: any }, locale: string, defaultLocale: string) => {
  const currentSlug = sanityDocument?.slug?.current
  const url = `${siteUrl}${locale === defaultLocale ? '' : '/' + locale}`

  switch (sanityDocument?._type) {
    case 'product':
      return `${url}/products/${currentSlug}`
    case 'collection':
      return `${url}/collections/${currentSlug}`
    case 'pageArticle':
      return `${url}/blog/${currentSlug}`
    case 'homePage':
      return url
    case 'page':
    case 'pageWishlist':
    case 'pageSearch':
    case 'pageWithRequirements':
      return `${url}/${currentSlug}`
    case 'pageSearch':
      return `${url}/search`
    default:
      return url
  }
}
export type LinkType = 'product' | 'collection' | 'account' | 'blog' | 'pageArticle' | 'pageWithRequirements' | 'pageAccount' | 'pagePressRelease' | 'pageRetail'

export const convertSlug = (slug?: Slug, pageType?: LinkType, newTab = false) => {
  return {
    page: {
      _type: pageType,
      slug,
    },
    newTab,
  }
}

const RMA_PORTAL_DOMAINS: { rmaPortalHostname: string; orderNamePrefix: string }[] = [
  { rmaPortalHostname: 'https://exchanges.mrmarvis.com', orderNamePrefix: '10016' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.de', orderNamePrefix: '10017' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.co.uk', orderNamePrefix: '10018' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.fr', orderNamePrefix: '10019' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.nl', orderNamePrefix: '10015' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.dk', orderNamePrefix: '10011' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.es', orderNamePrefix: '10012' },
  { rmaPortalHostname: 'https://exchanges.mrmarvis.it', orderNamePrefix: '10014' },
  { rmaPortalHostname: 'https://exchanges-ch.mrmarvis.com', orderNamePrefix: '10021' },
]

export const rmaPortalUrl = (shopifyOrderName: string, postcodeOrEmail: string) => {
  const orderNumberNoHash = shopifyOrderName.replace('#', '')
  const rmaPortalHostname = RMA_PORTAL_DOMAINS?.filter((param) => orderNumberNoHash.startsWith(param.orderNamePrefix))?.[0]?.rmaPortalHostname || 'https://exchanges.mrmarvis.com'
  const postcodeOrEmailNoSpaces = postcodeOrEmail.replace(' ', '%20').toUpperCase()

  return `${rmaPortalHostname}?order=${orderNumberNoHash}&secret=${postcodeOrEmailNoSpaces}`
}
