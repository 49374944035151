// Global terms; should also be in Sanity schema
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../../utils'

// @see apps/studio/schemas/dictionary/globalTerms.schema.ts
export const globalTermsText: string[] = [
  'availableColors',
  'sizeGuide',
  'sizeChart',
  'aiSizeGuideWhatAreYourMeasurements',
  'aiSizeGuideHeight',
  'aiSizeGuideWeight',
  'aiSizeGuideWhatBodyShape',
  'aiSizeGuideAnythingWeNeedToKnow',
  'aiSizeGuideTummyShape',
  'aiSizeGuideHipsShape',
  'aiSizeGuideFlat',
  'aiSizeGuideAverage',
  'aiSizeGuideRound',
  'aiSizeGuideSlim',
  'aiSizeGuideWide',
  'aiSizeGuideMissingInput',
  'aiSizeGuideSkipStep',
  'aiSizeGuidePreviousStep',
  'aiSizeGuideContinue',
  'aiSizeGuideSubmit',
  'aiSizeGuideLoading',
  'aiSizeGuideNoResultsWarning',
  'aiSizeGuideWeRecommendSizeX',
  'aiSizeGuideIfPreferDifferent',
  'aiSizeGuidePrivacyPolicy',
  'aiSizeGuideWhatDoYouThink',
  'aiSizeGuideWeAreImproving',
  'aiSizeGuideThankYouFeedback',
  'aiSizeGuideRestart',
  'close',
  'readMore',
  'info',
  'backToTop',
  'shareThisArticle',
  'shareSocials',
  'viewAll',
  'otherStories',
  'send',
  'firstName',
  'lastName',
  'email',
  'emailHint',
  'phone',
  'formErrorMessage',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'address',
  'openingHours',
  'emailSubscribeSuccess',
  'emailSubscribeError',
  'frCountryPicker',
  'gbCountryPicker',
  'nlCountryPicker',
  'itCountryPicker',
  'enCountryPicker',
  'deCountryPicker',
  'usCountryPicker',
  'esCountryPicker',
  'dkCountryPicker',
  'chCountryPicker',
  'labelReview',
  'labelReviewsTitle',
  'labelReviews',
  'labelOutOfStars',
  'reviewExplanationTooltip',
  'reviewAttributeFit',
  'reviewAttributeQuality',
  'reviewAttributeValueForMoney',
  'reviewAttributeSize',
  'reviewAttributeSizeLikertVerySmall',
  'reviewAttributeSizeLikertSmall',
  'reviewAttributeSizeLikertSlightlySmall',
  'reviewAttributeSizeLikertAsExpected',
  'reviewAttributeSizeLikertSlightlyLarge',
  'reviewAttributeSizeLikertLarge',
  'reviewAttributeSizeLikertVeryLarge',
  'reviewAttributeValueDisappointing',
  'reviewAttributeValueMediocre',
  'reviewAttributeValueOkay',
  'reviewAttributeValueGreat',
  'reviewAttributeValueExcellent',
  'labelShowMoreReviews',
  'labelViewMoreReviews',
  'labelVerifiedBuyer',
  'labelAnonymousBuyer',
  'filter',
  'sort',
  'sortNewToOld',
  'sortOldToNew',
  'sizeAndFit',
  'shareSocialsMessage',
  'new',
  'limitedEdition',
  'widerStripes',
  'comingSoon',
  'cart',
  'sizeCartLabel',
  'account',
  'checkOutourSizeGuide',
  'redirectionNotice',
  'stayHere',
  'continue',
  'search',
  'searchQuickHit',
  'viewAllSearchResults',
  'products',
  'collections',
  'stories',
  'blogPosts',
  'pages',
  'wishlist',
  'remove',
  'backToOverview',
  'shopTheLook',
  'addToCartToastMessage',
  'closed',
  'openInGoogleMaps',
  'storeAvailability',
  'inStock',
  'outOfStock',
  'lastFewLeft',
  'openingHoursToday',
  'storeDetails',
  'findInStore',
  'openingSoon',
  'selectSize',
  'easterCampaignDiscountMessage',
  'copied',
  'feedback',
  'thankYou',
  'feedbackSubmitted',
  'feedbackSurveyTitle',
  'feedbackSurveyDescription',
  'submit',
  'businessDays',
  'orderedBefore',
  'freeShipping',
  'freeReturns',
  'freeShippingOver',
  'searchYourCountry',
  'searchYourQuestion',
  'searchProductName',
  'allCountries',
  'nl',
  'de',
  'be',
  'allTopics',
  'country',
  'deliveryTime',
  'shippingCosts',
  'pending',
  'infoReceived',
  'orderPlaced',
  'inTransit',
  'outForDelivery',
  'shipped',
  'attemptFail',
  'delivered',
  'availableForPickup',
  'exception',
  'expired',
  'estimatedDelivery',
  'timesAreInLocalTime',
  'carrier',
  'orderSummary',
  'quantity',
  'goBackToTrack',
  'noResults',
  'copyTrackingNumber',
  'visitCarrierWebsite',
  'washing',
  'drying',
  'wearAndTear',
  'ironing',
  'storage',
  'orderNumber',
  'myAccount',
  'colors',
  'shopStyle',
  'copyToClipboard',
  'copyLinkToFaq',
  'careGuideLink',
  'shopBySeason',
  'shopByType',
  'featuredStyles',
  'viewAllStyles',
]

export const GLOBAL_TERMS_FRAGMENT = (locale = DEFAULT_LOCALE) => defineQuery(globalTermsText.map((term) => `${coalesceQuery(term, locale)}`).join(','))
