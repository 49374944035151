import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionBusinessReviewsData {
  _type: 'sectionBusinessReviews'
  _id: string
  title?: string
}

export const SECTION_BUSINESS_REVIEWS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBusinessReviews' => {
    _type,
    _id,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
  }
`)
