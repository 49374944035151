import { defineQuery } from 'next-sanity'
import { HOTSPOT_IMAGE_FRAGMENT, HotspotImage } from '../components/hotspotImage.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface SectionTaggedImageData extends HotspotImage {
  _id: string
  _type: 'shopTheLookImage' | 'taggedImage'
}

export const SECTION_TAGGED_IMAGE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == "shopTheLookImage" || _type == "taggedImage" => {
    _id,
    _type,
    ${HOTSPOT_IMAGE_FRAGMENT(locale)}
  }
`)
