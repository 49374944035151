import { ShopifyProductAvailability } from '../../types'

export interface GetProductsAvailabilityDto {
  data: {
    nodes: ShopifyProductAvailability[]
  }
}

export const PRODUCTS_AVAILABILITY_QUERY = `query multipleProducts($ids: [ID!]!){
  nodes(ids: $ids){
    ... on Product {
      variants(first: 150) {
        edges {
          node {
            sku
            quantityAvailable
            availableForSale
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
}`
