import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionBulletPointsData {
  _type: 'sectionBulletPoints'
  _id: string
  title?: string
  bulletPoints?: BulletPoint[]
}

export interface BulletPoint {
  icon?: string
  title?: string
  text?: RichText
}

export const SECTION_BULLET_POINTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBulletPoints' => {
    _type,
    _id,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
    defined(bulletPoints) => {
      bulletPoints[] {
        defined(icon) => { icon },
        defined(title) => {
          ${coalesceQuery('title', locale)}
        },
        defined(text) => {
          ${RICH_TEXT_FRAGMENT(locale, 'text')}
        },
      }
    }
  }
`)
