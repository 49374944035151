import { Slug } from '@sanity/types'
import { ProductCategory, Style } from '../../repository'
import { Link, LINK_FRAGMENT, LINK_FRAGMENT_WITH_SHOW_IN_LOCALES, LinkWithShowInLocales } from './link.fragment'
import { MENU_CARD_FRAGMENT, MenuCard } from './menuCard.fragment'
import { defineQuery } from 'next-sanity'
import { DEFAULT_LOCALE, coalesceFilter, coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT } from '../common/getImage.fragment'

export interface MainMenuItem {
  _key: string
  _type: 'mainMenuItem'
  mainMenuCards: MenuCard[]
  mainMenuItemLink: Link
  productCategories?: ProductCategory[]
  productCategoriesV1?: ProductCategory[]
  shopByTypeList?: {
    title: string
    links: Array<LinkWithShowInLocales | { _type: 'collection'; title: string; slug: Slug }>
  }
  shopBySeasonList?: {
    title: string
    links: Array<LinkWithShowInLocales | { _type: 'collection'; title: string; slug: Slug }>
  }
  featuredStyles?: Style[]
}

export const MAIN_MENU_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
        _key,
        _type,
        _type == 'reference' => @-> {
          ${LINK_FRAGMENT(locale)}
        },
        mainMenuCards[]-> {
          ${MENU_CARD_FRAGMENT(locale)}
        },
        mainMenuItemLink-> {
          ${LINK_FRAGMENT(locale)}
        },
        productCategories[] {
          ${coalesceQuery('productCategoryMenuTitle', locale)},
          productCategory-> {
            ${coalesceQuery('title', locale)},
            ${coalesceQuery('slug', locale)}
          },
          productCategoryImage {
            ${GET_IMAGE_FRAGMENT()}
          },
          ${coalesceQuery(
            'styles',
            locale,
            'styles',
            `
            [style->showInLocales[$locale] != false] {
              ...style-> {
                ${coalesceQuery('title', locale)},
                ${coalesceQuery('slug', locale)},
                ${coalesceQuery('collectionDescription', locale)},
                new,
                "colors": array::compact(products[@->showInLocales[$locale] != false]->color-> {
                  "colorId": _id,
                  defined(hexCode) => {
                    "hex": hexCode.hex,
                  },
                  defined(image) => {
                    "image": image->mobile {
                      ${GET_IMAGE_FRAGMENT()}
                    },
                  }
                })
              },
              styleImage {
                ${GET_IMAGE_FRAGMENT()}
              }
            }`,
          )}
        },
          productCategoriesV1[] {
            ${coalesceQuery('productCategoryMenuTitle', locale)},
            productCategory-> {
              ${coalesceQuery('title', locale)},
              ${coalesceQuery('slug', locale)}
            },
            productCategoryImage {
              ${GET_IMAGE_FRAGMENT()}
            },
            ${coalesceQuery(
              'styles',
              locale,
              'styles',
              `
              [style->showInLocales[$locale] != false] {
                ...style-> {
                  ${coalesceQuery('title', locale)},
                  ${coalesceQuery('slug', locale)},
                  ${coalesceQuery('collectionDescription', locale)},
                  new,
                  "colors": array::compact(products[@->showInLocales[$locale] != false]->color-> {
                    "colorId": _id,
                    defined(hexCode) => {
                      "hex": hexCode.hex,
                    },
                    defined(image) => {
                      "image": image->mobile {
                        ${GET_IMAGE_FRAGMENT()}
                      },
                    }
                  })
                },
                styleImage {
                  ${GET_IMAGE_FRAGMENT()}
                }
              }`,
            )}
          },
          shopByTypeList {
            ${coalesceQuery('title', locale)},
            links[]-> {
              _type,
              _type == 'link' => {
                ${LINK_FRAGMENT_WITH_SHOW_IN_LOCALES(locale)}
              },
              _type == 'collection' && showInLocales[$locale] != false => {
                ${coalesceQuery('title', locale)},
                ${coalesceQuery('slug', locale)},
                "showInLocales": showInLocales[$locale]
              }
            }
          },
          shopBySeasonList {
            ${coalesceQuery('title', locale)},
            links[]-> {
              _type,
              _type == 'link' => {
                ${LINK_FRAGMENT_WITH_SHOW_IN_LOCALES(locale)}
              },
              _type == 'collection' => {
                ${coalesceQuery('title', locale)},
                ${coalesceQuery('slug', locale)},
                "showInLocales": showInLocales[$locale]
              }
            }
          },
           ${coalesceQuery(
             'featuredStyles[0]',
             locale,
             'featuredStyles',
             `
              [style->showInLocales[$locale] != false] {
                ...style-> {
                  ${coalesceQuery('title', locale)},
                  ${coalesceQuery('slug', locale)},
                  ${coalesceQuery('collectionDescription', locale)},
                  new,
                  "showInLocales": showInLocales[$locale],
                  "colors": array::compact(products[@->showInLocales[$locale] != false]->color-> {
                    "colorId": _id,
                    defined(hexCode) => {
                      "hex": hexCode.hex,
                    },
                    defined(image) => {
                      "image": image->mobile {
                        ${GET_IMAGE_FRAGMENT()}
                      },
                    }
                  })
                },
                styleImage {
                  ${GET_IMAGE_FRAGMENT()}
                }
              }`,
           )}

        } {
          ...,
          productCategories[length(styles) > 0],
          productCategoriesV1[length(styles) > 0]
  `)
