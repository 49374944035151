import { ClientConfig, createClient } from '@sanity/client'

const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'yb9xf4jc'
const dataset = process.env.NEXT_PUBLIC_SANITY_PROJECT_DATASET || 'production'
const token = process.env.SANITY_API_TOKEN || ''
const cdnApiUrl = process.env.NEXT_PUBLIC_SANITY_CDN_API_URL || 'https://apicdn.mrmarvis.com'
const apiUrl = process.env.NEXT_PUBLIC_SANITY_API_URL || 'https://api.mrmarvis.com'
const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2021-10-21'

const sanityClientConfig = (useCdn = true): Readonly<ClientConfig> => {
  return {
    dataset,
    projectId,
    useCdn,
    apiVersion,
    perspective: 'published',
    apiHost: useCdn ? cdnApiUrl : apiUrl,
  }
}

export const sanityBuildClient = createClient(sanityClientConfig(false))
export const sanityCdnClient = createClient(sanityClientConfig(true))
export const sanityAdminClient = createClient({ ...sanityClientConfig(false), perspective: 'previewDrafts', token })
