import { EffectCallback, useEffect } from 'react'

export type ExpectedReadyState = ReadonlyArray<DocumentReadyState> | DocumentReadyState | undefined

export const isReadyStateMatch = (expected?: ExpectedReadyState): boolean => {
  if (!expected) {
    return true
  }

  if ('string' === typeof expected && document.readyState === expected) {
    return true
  }

  return expected.indexOf(document.readyState) !== -1
}

export type useReadyStateEffect = (effect: EffectCallback, deps?: any[], onState?: ExpectedReadyState) => void

/**
 * The most performance optimized hook to detect
 * whether the page has finished loading.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */
export const useReadyStateEffect: useReadyStateEffect = (effect, deps = [], onState = 'complete'): void => {
  useEffect(() => {
    const destructors: Array<() => void> = [() => document.removeEventListener('readystatechange', listener)]

    const listener = () => {
      if (!isReadyStateMatch(onState)) {
        return
      }

      const destructor = effect()

      if (destructor) {
        destructors.push(destructor)
      }
    }

    listener()
    document.addEventListener('readystatechange', listener)

    return () => destructors.forEach((d) => d())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
