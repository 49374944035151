export interface GetCustomerWishListDto {
  data: {
    customer: {
      metafield: {
        value: string
      } | null
    }
  }
}

export const CUSTOMER_WISHLIST_METAFIELD_QUERY = `query customerQuery($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    metafield(namespace: "customer", key: "customer_wishlist") {
      value
    }
  }
}`
