import { defineQuery } from 'next-sanity'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface CareInstruction {
  instruction: string
  type: 'washing' | 'drying' | 'ironing' | 'dryCleaning' | 'wearAndTear'
  icon: Image
}

const CARE_INSTRUCTIONS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${coalesceQuery('instruction', locale)},
  type,
  icon {
    ${GET_IMAGE_FRAGMENT()}
  }
`)

export interface CareInstructionSet {
  washing: {
    careInstructions: CareInstruction[]
  }
  drying: {
    careInstructions: CareInstruction[]
  }
  ironing: {
    careInstructions: CareInstruction[]
  }
  storage: {
    careInstructions: CareInstruction[]
  }
  wearAndTear: {
    careInstructions: CareInstruction[]
  }
}

export const CARE_INSTRUCTION_SET_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  washing {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  drying {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  ironing {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  storage {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  },
  wearAndTear {
    careInstructions[]-> {
      ${CARE_INSTRUCTIONS_FRAGMENT(locale)}
    }
  }
`)
