import { defineQuery } from 'next-sanity'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProduct } from '../components/productCardProduct.fragment'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface SectionProductCardData {
  _id: string
  _type: 'sectionProductCard'
  product: ProductCardProduct
  square: boolean
  hoverImage?: Image
}

export const SECTION_PRODUCT_CARD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  _type,
  product-> {
    ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale, 1)}
  },
  square,
  defined(hoverImage) => {
    hoverImage {
      ${GET_IMAGE_FRAGMENT()}
    }
  }
`)
