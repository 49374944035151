export const keys: Record<string, string> = {
  CART_STAGING: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_STAGING}`,
  CART_NL: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_NL}`,
  CART_US: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US}`,
  CART_UK: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK}`,
  CART_DK: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DK}`,
  CART_DE: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DE}`,
  CART_ES: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_ES}`,
  CART_IT: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_IT}`,
  CART_FR: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_FR}`,
  CART_EN: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EN}`,
  CART_CH: `cartId::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_CH}`,
  USER: 'shopify_local_user',
  FILTER_VALUE: 'filter-value',
  LOCAL_WISHLIST_STAGING: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_STAGING}`,
  LOCAL_WISHLIST_NL: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_NL}`,
  LOCAL_WISHLIST_US: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US}`,
  LOCAL_WISHLIST_UK: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK}`,
  LOCAL_WISHLIST_DK: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DK}`,
  LOCAL_WISHLIST_DE: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_DE}`,
  LOCAL_WISHLIST_ES: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_ES}`,
  LOCAL_WISHLIST_IT: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_IT}`,
  LOCAL_WISHLIST_FR: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_FR}`,
  LOCAL_WISHLIST_EN: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EN}`,
  LOCAL_WISHLIST_CH: `localWishlist::${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_CH}`,
}

export function set(key: string, value: string | undefined, useSessionStorage: boolean = false) {
  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) {
    try {
      const storage = useSessionStorage ? window.sessionStorage : window.localStorage

      if (value) {
        storage.setItem(key, value)
      } else {
        remove(key, useSessionStorage)
      }
    } catch (error) {
      console.warn(error)
    }
  }
}

export function remove(key: string, useSessionStorage: boolean = false) {
  const isBrowser = typeof window !== 'undefined'

  if (isBrowser) {
    try {
      const storage = useSessionStorage ? window.sessionStorage : window.localStorage

      storage.removeItem(key)
    } catch (error) {
      console.warn(error)
    }
  }
}

export function get(key: string, useSessionStorage: boolean = false) {
  const isBrowser = typeof window !== 'undefined'

  if (!isBrowser) {
    return null
  }

  try {
    const storage = useSessionStorage ? window.sessionStorage : window.localStorage

    return storage.getItem(key)
  } catch (error) {
    console.warn(error)
  }

  return null
}
