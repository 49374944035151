import { ShopifyUserErrorWithoutCode } from '../../types'

export interface ApplyDiscountCodeDto {
  data: {
    cartDiscountCodesUpdate: {
      userErrors: ShopifyUserErrorWithoutCode[]
      cart: {
        id: string
        discountCodes: {
          code: string
          applicable: boolean
        }[]
      }
    }
  }
}

export const ADD_DISCOUNT_CODE_MUTATION = `mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
  cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
    cart {
      id
      discountCodes {
        code
        applicable
      }
    }
    userErrors {
      field
      message
    }
  }
}`
