import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Media, MEDIA_FRAGMENT } from './media.fragment'
import { defineQuery } from 'next-sanity'

export interface ImageTextListItem {
  title?: string
  text: string
  media: Media
}

export const IMAGE_TEXT_LIST_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(title) => {
    ${coalesceQuery('title', locale)}
  },
  ${coalesceQuery('text', locale)},
  media-> {
    ${MEDIA_FRAGMENT(locale)}
  },
`)
