import { ShopifyUserErrorWithoutCode } from '../../types'

export interface RemoveLineItemsDto {
  data: {
    cartLinesRemove: {
      userErrors: ShopifyUserErrorWithoutCode[]
      cart: { id: string }
    }
  }
}

export const REMOVE_LINE_ITEMS_MUTATION = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds){
    cart {
      id
    }
    userErrors {
      field
      message
    }
  }
}`
