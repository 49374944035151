import { FunctionComponent, useEffect } from 'react'
import { useRouter } from 'next/router'
import { initPosthog } from '../../utils/telemetry.util'
import { useCookieConsentContext } from '../../hooks/context/useCookieConsentContext'
import { usePostHog } from 'posthog-js/react'

initPosthog()

const MisterPostHog: FunctionComponent = () => {
  const router = useRouter()
  const posthog = usePostHog()
  const { consent } = useCookieConsentContext()

  // Track route changes
  useEffect(() => {
    const handleRouteChange = () => {
      if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
        posthog?.capture('$pageview')
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Consent
  // @see https://posthog.com/docs/libraries/js#opt-users-out
  useEffect(() => {
    if (consent?.statistics) {
      if (posthog?.has_opted_out_capturing()) {
        posthog?.opt_in_capturing()
      }
    } else {
      if (!posthog?.has_opted_out_capturing()) {
        posthog?.opt_out_capturing()
      }
    }
  }, [consent, posthog])

  return null
}

export default MisterPostHog
