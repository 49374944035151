import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Slug } from '../../types'

export interface SectionSearchResultsData {
  _type: 'sectionSearchResults'
  _id: string
  searchResultsSlug: Slug
}

export const SECTION_SEARCH_RESULTS_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSearchResults' => {
    _type,
    _id,
    "searchResultsSlug": *[_type == 'pageSearch'][0] {
      ${coalesceQuery('slug', locale)},
    },
  }
`)
