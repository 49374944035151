import { defineQuery } from 'next-sanity'
import { RICH_TEXT_FRAGMENT, RichText } from './richText.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface ProductSizeInfoNote {
  content?: RichText
  showInLocales?: boolean
}

export const PRODUCT_SIZE_INFO_NOTE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${RICH_TEXT_FRAGMENT(locale, 'content')},
  "showInLocales": showInLocales[$locale],
`)
