import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionReturnFormData {
  _id: string
  _type: 'sectionReturnForm'
  title: string
  description?: RichText
  orderNumberLabel?: string
  orderNumberPlaceholder?: string
  orderNumberHint?: string
  postcodeOrEmailLabel?: string
  postcodeOrEmailPlaceholder?: string
  postcodeOrEmailHint?: string
  buttonText: string
}

export const SECTION_RETURN_FORM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
_type == 'sectionReturnForm' => {
  _id,
  _type,
  ${coalesceQuery('title', locale)},
  defined(description) => {
    ${RICH_TEXT_FRAGMENT(locale, 'description')},
  },
  defined(orderNumberLabel) => {
    ${coalesceQuery('orderNumberLabel', locale)},
  },
  defined(orderNumberPlaceholder) => {
    ${coalesceQuery('orderNumberPlaceholder', locale)},
  },
  defined(orderNumberHint) => {
    ${coalesceQuery('orderNumberHint', locale)},
  },
  defined(postcodeOrEmailLabel) => {
    ${coalesceQuery('postcodeOrEmailLabel', locale)},
  },
  defined(postcodeOrEmailPlaceholder) => {
    ${coalesceQuery('postcodeOrEmailPlaceholder', locale)},
  },
  defined(postcodeOrEmailHint) => {
    ${coalesceQuery('postcodeOrEmailHint', locale)},
  },
  ${coalesceQuery('buttonText', locale)},
}
`)
