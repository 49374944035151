import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface SectionLoginData {
  _type: 'sectionLogin'
  _id: string
  title: string
  subtitle?: string
  backgroundImage: Media
  emailLabel: string
  emailErrorMessage: string
  passwordLabel: string
  passwordErrorMessage: string
  forgottenPasswordLink: Link
  loginErrorMessage: string
  submitButtonText: string
  createAccountTitle?: string
  createAccountSubtitle?: string
  createAccountLink?: Link
}

export const SECTION_LOGIN_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionLogin' => {
    _id,
    _type,
    ${coalesceQuery('title', locale)},
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)}
    },
    backgroundImage-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    ${coalesceQuery('emailLabel', locale)},
    ${coalesceQuery('emailErrorMessage', locale)},
    ${coalesceQuery('passwordLabel', locale)},
    ${coalesceQuery('passwordErrorMessage', locale)},
    forgottenPasswordLink-> {
      ${LINK_FRAGMENT(locale)}
    },
    ${coalesceQuery('loginErrorMessage', locale)},
    ${coalesceQuery('submitButtonText', locale)},
    defined(createAccountTitle) => {
      ${coalesceQuery('createAccountTitle', locale)}
    },
    defined(createAccountSubtitle) => {
      ${coalesceQuery('createAccountSubtitle', locale)}
    },
    defined(createAccountLink) => {
      createAccountLink-> {
        ${LINK_FRAGMENT(locale)}
      }
    },
  }
`)
