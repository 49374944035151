import React, { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { get, remove, set } from '../utils/localStorage.util'
import { useCookieConsentContext } from '../hooks/context/useCookieConsentContext'

// Since it's local storage, always treat every field
// as optional to make our implementation resilient
export interface RecentlyViewedEntry {
  pathname?: string | null // Pathname without query params or fragment
  viewed?: number | null // Timestamp (allows for easy sorting)
}

interface RecentlyViewedContextProps {
  recentlyViewed: RecentlyViewedEntry[]
  recentlyViewedProductSlugs: string[]
  removeRecentlyViewed: () => void
}

const RecentlyViewedContext = createContext<RecentlyViewedContextProps>({
  recentlyViewed: [],
  recentlyViewedProductSlugs: [],
  removeRecentlyViewed: () => {},
})

export const useRecentlyViewed = () => {
  const context = useContext(RecentlyViewedContext)

  if (context === undefined) {
    throw new Error('useRecentlyViewed must be used within a RecentlyViewedProvider.')
  }

  return context
}

const localStorageKey = 'recentlyViewed'
const localStorageMaxEntries = 50

const RecentlyViewedContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { asPath, isReady, locale } = useRouter()
  const { hasConsentedToCookie } = useCookieConsentContext()
  const [recentlyViewed, setRecentlyViewed] = useState<RecentlyViewedEntry[]>([])
  const [recentlyViewedProductSlugs, setRecentlyViewedProductSlugs] = useState<string[]>([])

  useEffect(() => {
    if (isReady && hasConsentedToCookie('preferences')) {
      // Ensure to get a clean path without query params or fragment
      const url = new URL(asPath, 'https://example.com')

      const currentEntries = getRecentlyViewed()
      const newEntry: RecentlyViewedEntry = { pathname: url.pathname, viewed: Date.now() }
      const updatedEntries: RecentlyViewedEntry[] = [newEntry, ...currentEntries.filter((item) => item?.pathname !== url.pathname)].slice(0, localStorageMaxEntries)

      setRecentlyViewed(updatedEntries)
      set(localStorageKey, JSON.stringify(updatedEntries))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, locale, isReady])

  useEffect(() => {
    setRecentlyViewedProductSlugs(
      recentlyViewed
        // Only product paths
        .filter((entry) => entry?.pathname?.startsWith('/products/'))
        // Take slug from path
        .map((entry) => entry?.pathname?.replace('/products/', '') || ''),
    )
  }, [recentlyViewed])

  const getRecentlyViewed = (): RecentlyViewedEntry[] => {
    return JSON.parse(get(localStorageKey) || '[]')
  }

  const removeRecentlyViewed = () => {
    remove(localStorageKey)
    setRecentlyViewed([])
  }

  return (
    <RecentlyViewedContext.Provider
      value={{
        recentlyViewed,
        recentlyViewedProductSlugs,
        removeRecentlyViewed,
      }}
    >
      {children}
    </RecentlyViewedContext.Provider>
  )
}

export default RecentlyViewedContextProvider
