import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'

export interface CookieType {
  _type: 'cookieType'
  type: 'necessary' | 'preferences' | 'statistics' | 'marketing'
  title?: string
  description?: string
}

export const COOKIE_TYPE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    _type,
    type,
    ${coalesceQuery('title', locale)},
    ${coalesceQuery('description', locale)},
`)
