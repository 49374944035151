import { defineQuery } from 'next-sanity'
import { coalesceQuery } from '../../utils'

export interface CustomerService {
  openingHoursHeader?: string
  openingHours?: string
  specialOpeningHours?: string
  email?: string
  phone?: string
  whatsApp?: string
  messenger?: string
}

export const CUSTOMER_SERVICE_FRAGMENT = (locale: string) =>
  defineQuery(`
  "customerServiceData": *[_type == "customerService"][0] {
    ${coalesceQuery('openingHoursHeader', locale)},
    ${coalesceQuery('openingHours', locale)},
    defined(specialOpeningHours) => {
      "specialOpeningHours": specialOpeningHours[$locale],
    },
    defined(email) => {
      "email": email[$locale],
    },
    defined(phone) => {
      "phone": phone[$locale],
    },
    defined(whatsApp) => {
      "whatsApp": whatsApp[$locale],
    },
    defined(messenger) => {
      "messenger": messenger[$locale],
    }
  }
`)
