import { QueryParams } from 'sanity-helpers'
import throttledQueue from 'throttled-queue'
import { sanityAdminClient, sanityBuildClient, sanityCdnClient } from 'sanity-helpers/client'

const sanityThrottleQueue = throttledQueue(25, 1000)

export const sanityFetch = async (query: Readonly<string>, preview = false, params?: QueryParams) => {
  let useCdn = true

  // Sanity CDN usage depends on env and application lifecycle
  if (process?.env?.NODE_ENV === 'development') {
    useCdn = (process?.env?.NEXT_PUBLIC_LOCALHOST_USE_CDN || 'true') === 'true'
  } else if (process?.env?.NEXT_PHASE === 'phase-production-build' && process?.env?.NEXT_PUBLIC_VERCEL_ENV !== 'preview') {
    useCdn = false
  }

  const client = getSanityClient(preview, useCdn)

  // Prevent hitting Sanity rate limiter; global max 500 req/s;
  // @see https://www.sanity.io/docs/technical-limits
  if (!useCdn) {
    return await sanityThrottleQueue(() => client.fetch(query, params))
  }

  return client.fetch(query, params)
}

const getSanityClient = (admin = false, useCdn = true) => {
  if (admin) {
    return sanityAdminClient
  }

  return useCdn ? sanityCdnClient : sanityBuildClient
}

/**
 * Parse Sanity data.
 *
 * // todo: better/more descriptive function name
 */
export function parseData(data: any, preview: boolean = false) {
  // Undefined cannot be serialized for hydration, null can
  if (data === undefined) {
    return null
  }

  if (!Array.isArray(data)) {
    return data
  }

  if (data.length === 1) {
    return data[0]
  }

  if (preview) {
    return data.find((item) => item._id?.startsWith(`drafts.`)) || data[0]
  }

  return data[0]
}

export const localesWithFallbackForCoalesceQuery: Record<string, string[]> = {
  en: ['en'],
  gb: ['gb', 'en'],
  nl: ['nl', 'en'],
  de: ['de', 'en'],
  ch: ['ch', 'de', 'en'],
  dk: ['dk', 'en'],
  it: ['it', 'en'],
  fr: ['fr', 'en'],
  es: ['es', 'en'],
}

export const coalesceQuery = (name: string, currentLocale = 'en', rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || name}': coalesce(${locales.map((locale) => `${name}${name === '' ? locale : `['${locale}']`}${additionalQuery}`).join(', ')})`
}

export const coalesceFilter = (name: string, currentLocale = 'en') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `coalesce(${locales.map((locale) => `${name}${name === '' ? locale : `['${locale}']`}`).join(', ')})`
}

export const coalesceQueryForMigration = (currentName: string, newName: string, currentLocale = 'en', rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || newName}': coalesce(${locales.map((locale) => `coalesce(${newName}['${locale}'], ${currentName}['${locale}'])${additionalQuery}`).join(', ')})`
}

export const coalesceFilterForMigration = (currentName: string, newName: string, currentLocale = 'en') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `coalesce(${locales.map((locale) => `coalesce(${newName}['${locale}'], ${currentName}['${locale}'])`).join(', ')})`
}

export const forceFallbackForAll = (name: string) => {
  return `${name} {
    ${Object.entries(localesWithFallbackForCoalesceQuery)
      .map(([locale, fallbacks]) => {
        return `"${locale}": coalesce(${fallbacks.map((locale) => locale).join(', ')})`
      })
      .join(', ')}
  }`
}

export const coalesceLocalizedValue = (name: string, currentLocale = 'en', rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || name}': coalesce(${locales.map((locale) => `${`${name}[name == '${locale}'][0]`}${additionalQuery}`).join(', ')})`
}
