import { Slug } from '../../types'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { defineQuery } from 'next-sanity'

export interface ShopifyVariantInSanity {
  shopifyVariantId: string
  price: number
  sku: string
  inventory: {
    quantity: number
  }
  options: {
    name: string
    value: string
  }[]
  isAvailable: boolean
}

export interface Badges {
  new?: boolean
  limitedEdition?: boolean
  widerStripes?: boolean
}

export interface ProductCardProduct {
  _id: string
  productTitle: string
  slug: Slug
  badges: Badges
  discontinued?: boolean
  images: Image[]
  modelShot?: Image
  shopifyProductId?: string
  isAvailable: boolean
  price: number
  variants: ShopifyVariantInSanity[]
  colorId?: string
  primaryCollection?: {
    _id: string
    productType: string
  }
}

export const PRODUCT_CARD_PRODUCT_FRAGMENT = (locale = DEFAULT_LOCALE, limitImages: number | false = 2) =>
  defineQuery(`
  _id,
  ${coalesceQuery('productTitle', locale)},
  slug,
  badges,
  defined(discontinued[$locale]) => {
    'discontinued': discontinued[$locale]
  },
  'images': coalesce(images[_type == 'image' || _type == 'reference'][${limitImages ? `0...${limitImages}` : ''}] {
    _type == 'image' => {
      ${GET_IMAGE_FRAGMENT()}
    },
    _type == 'reference' => @-> {
      ...image {
        ${GET_IMAGE_FRAGMENT()}
      },
    },
  }, []),
  'modelShot': coalesce(
    images[_type == 'image' && asset->.originalFilename match '*MDL-F3*'][0] {
      ${GET_IMAGE_FRAGMENT()}
    },
    images[_type == 'image' && asset->.originalFilename match '*MDL-CF2*'][0] {
      ${GET_IMAGE_FRAGMENT()}
    }
  ),
  defined(shopifyProductId[$locale]) => {
    'shopifyProductId': shopifyProductId[$locale]
  },
  'isAvailable': count(shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > 0]) > 0 && availableInShopify[$locale],
  'price': shopifyVariants[0]->price[$locale],
  'variants': shopifyVariants[]-> {
    'shopifyVariantId': shopifyId[$locale],
    'price': price[$locale],
    sku,
    inventory[locationId == $inventoryLocationId][0] {
      quantity
    },
    options[] {
      name,
      'value': value[$locale],
    },
    'isAvailable': inventory[locationId == $inventoryLocationId][0].quantity > 0,
  },
  'colorId': color->_id,
  'primaryCollection': *[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
    _id,
    productType,
  }
`)
