import { defineQuery } from 'next-sanity'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { DEFAULT_LOCALE } from '../../utils'

export interface SectionNavigationData {
  _type: 'sectionNavigation'
  _id: string
  navigationItems: Link[]
}

export const SECTION_NAVIGATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionNavigation' => {
    _type,
    _id,
    navigationItems[]-> {
      ${LINK_FRAGMENT(locale)}
    }
  }
`)
