import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Cta, CTA_FRAGMENT } from '../components/cta.fragment'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionTextMediaCtaData {
  _type: 'sectionTextMediaCta'
  _id: string
  name: string
  title: string
  text?: RichText
  copyPositionDesktop: 'left' | 'right'
  media: Media
  cta?: Cta
}

export const SECTION_TEXT_MEDIA_CTA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionTextMediaCta' => {
    _type,
    _id,
    name,
    ${coalesceQuery('title', locale)},
    defined(text) => {
      ${RICH_TEXT_FRAGMENT(locale, 'text')},
    },
    copyPositionDesktop,
    media-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    defined(cta) => {
      cta-> {
        ${CTA_FRAGMENT(locale)}
      }
    }
  }
`)
