import { defineQuery } from 'next-sanity'
import { DEFAULT_LOCALE } from '../../utils'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'

export interface Media {
  _type: 'media'
  _id: string
  type: 'image' | 'remoteVideo'
  alt: string
  desktop?: Image
  mobile?: Image
  videoPoster?: Image
  videoPosterDesktop?: Image
  remoteVideoUrl?: string
  remoteVideoUrlDesktop?: string
  videoAutoplay: boolean
  videoControls: boolean
}

export const MEDIA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  _id,
  type,
  alt,
  defined(desktop) => {
    desktop {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(mobile) => {
    mobile {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(videoPoster) => {
    videoPoster {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(videoPosterDesktop) => {
    videoPosterDesktop {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  type == "remoteVideo" && defined(remoteVideoUrl) => {
    remoteVideoUrl
  },
  type == "remoteVideo" && defined(remoteVideoUrlDesktop) => {
    remoteVideoUrlDesktop
  },
  'videoAutoplay': videoAutoplay != false,
  'videoControls': videoControls != false,
`)
