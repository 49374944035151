import { defineQuery } from 'next-sanity'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Media, MEDIA_FRAGMENT } from './media.fragment'
import { RICH_TEXT_FRAGMENT, RichText } from './richText.fragment'
import { SIZE_CHARTS_FRAGMENT, SizeChart } from './sizeChart.fragment'

export interface SizeGuideModal {
  _type?: string
  _id: string
  collectionTitle: string
  collectionBulletImage?: Media
  collectionSizing?: RichText
  sizeCharts?: SizeChart[]
  showInLocales?: boolean
}

export const SIZE_CHART_MODAL_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  _id,
  ${coalesceQuery('title', locale, 'collectionTitle')},
  "collectionBulletImage": bulletImage-> {
    ${MEDIA_FRAGMENT(locale)}
  },
  defined(product.sizing) => {
    ${RICH_TEXT_FRAGMENT(locale, 'product.sizing', 'collectionSizing')}
  },
  defined(sizeCharts) => {
    ${SIZE_CHARTS_FRAGMENT(locale)}
  },
  'showInLocales': showInLocales[$locale],
`)
