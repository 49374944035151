import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { Cta, CTA_FRAGMENT } from '../components/cta.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { defineQuery } from 'next-sanity'

export type SectionGridCalloutsData = {
  _type: 'gridCallout'
  /**
   * Is true when:
   * - hideDynamically is set to false (this indicates that the grid callout should always be shown)
   * - the linked product is in good stock (configured in Sanity), available in Shopify and visible in the current locale
   * - the linked collection should be visible in the current locale and has products which should show in the current locale
   * - the linked page is not a collection or product
   */
  showGridCallout: boolean
  image: Media
  text: string
  button?: Cta
  columnSpan: number
}

export const SECTION_GRID_CALLOUT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'gridCallout' => {
    _type,
    'page': button->link->page->,
    button-> {
      ${CTA_FRAGMENT(locale)}
    },
    ${coalesceQuery('text', locale)},
    "image": image-> {
      ${MEDIA_FRAGMENT(locale)}
    },
    columnSpan,
  } {
    _type,
    button,
    text,
    image,
    columnSpan,
    'showGridCallout': select(
      advancedSettings.hideDynamically == false => true,
      page._type == 'collection' => page.showInLocales[$locale] != false && count(page.products[@->showInLocales.[$locale] != false]) > 0,
      page._type == 'product' => count(page.shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > coalesce(advancedSettings.showAtStockNumber, 20)]) > 0 && page.availableInShopify[$locale] && page.showInLocales[$locale] != false,
      page._type != 'collection' && page._type != 'product' => true
    ),
  }
`)
