import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { defineQuery } from 'next-sanity'

export interface Seo {
  seoTitle?: string
  seoDescription?: string
  seoImage?: Image
  noIndex?: boolean
}

export const SEO_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(seoTitle) => {
    ${coalesceQuery('seoTitle', locale)}
  },
  defined(seoDescription) => {
    ${coalesceQuery('seoDescription', locale)}
  },
  defined(seoImage) => {
    "seoImage": seoImage {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(noIndex) => {
    noIndex
  },
`)
