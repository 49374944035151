import { ShopifyCartLineItem } from '@/types/shopify'
import { at, flatten } from './array.util'
import { productTitle } from './product.util'
import { ShopifyProductInSanity, ShopifyVariantInSanity, ShopifyVariantInSanityWithProductTitle } from '@/types/product'
import { getSiteUrl } from './url.util'
import { get } from './localStorage.util'
import { toSentenceCase } from './copy.util'
import { imageQuality } from './image.util'
import { Image } from 'sanity-helpers'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { ProductMedia } from 'data-access'

export const elevarUserId = () => get('___ELEVAR_GTM_SUITE--userId') ?? undefined
export const elevarSessionId = () => get('___ELEVAR_GTM_SUITE--sessionId') ?? undefined

// Minified 'Non-Shopify Subdomains' script from Elevar with key
// replaced by [elevarKey] placeholder (differs per instance)
// @see https://www.toptal.com/developers/javascript-minifier
// @see https://app.getelevar.com/company/4610/website/5000/my-tracking/source-custom-pages
export const elevarScript = (elevarKey: string) =>
  'try{let t={},a=(await import("https://shopify-gtm-suite.getelevar.com/configs/[elevarKey]/config.js")).default,r=t.proxyPath?`${t.proxyPath}${a.script_src_custom_pages_proxied}`:a.script_src_custom_pages;if(r){let{handler:c}=await import(r);await c(a,t)}}catch(e){console.error("Elevar Error:",e)}'.replace(
    '[elevarKey]',
    elevarKey,
  )

export const ecommerceInfoUserData = (cart: any, list?: string) => {
  const lineItems = cart?.lines?.edges?.map(({ node }: Record<string, any>) => node) || []

  return {
    currencyCode: cart?.cost?.totalAmount?.currencyCode || 'EUR',
    cart_contents: {
      products: lineItems.map((lineItem: ShopifyCartLineItem, index: number) => {
        const { title, category } = productTitle(lineItem?.merchandise?.product?.title)
        const regularPrice = parseFloat(lineItem?.merchandise?.price?.amount || '0')

        const discount =
          lineItem?.discountAllocations
            ?.map((discount) => discount?.discountedAmount?.amount)
            ?.reduce((accumulator: number, currentValue: string) => {
              return accumulator + parseFloat(currentValue)
            }, 0) / lineItem?.quantity

        return {
          id: lineItem?.merchandise?.sku,
          name: title,
          brand: 'MR MARVIS',
          variant: lineItem?.merchandise?.title,
          list,
          category,
          position: index + 1,
          price: (regularPrice - discount).toFixed(2),
          quantity: String(lineItem?.quantity || 0),
          product_id: String(at((lineItem?.merchandise?.product?.id || '').split('/'), -1) || ''),
          variant_id: String(at((lineItem?.merchandise?.id || '').split('/'), -1) || ''),
          compare_at_price: discount > 0 ? regularPrice.toFixed(2) || '0.0' : '0.0',
          image: lineItem?.merchandise?.image?.transformedSrc || '',
        }
      }),
    },
    // Not for Elevar but used by Contentsquare: reset previously set
    // content group values in datalayer since they aren't being reset
    // by their tracking
    contentGroupCategory: undefined,
    contentGroupSubcategory: undefined,
  }
}

export const ecommerceInfoArticleList = (currentChunkIndex: number, chunks: Record<string, any>[], list: string, currencyCode?: string, type?: string, season?: string) => {
  let position = currentChunkIndex === 0 ? 0 : flatten(chunks.filter((chunk, i) => i < currentChunkIndex)).length

  // Category is 'Shorts' and subcategory is 'The Originals';
  // not required by Elevar but custom made for Contentsquare
  const contentGroupCategory = toSentenceCase(type?.split('|')?.[0]?.trim() || '')
  const contentGroupSubcategory = type?.split('|')?.[1]?.trim()

  return {
    currencyCode: currencyCode || 'EUR',
    impressions: chunks?.[currentChunkIndex]?.flatMap((product: Record<string, any>) => {
      // Return first available variant to not send all variants into the data layer
      const variant = (product?.variants || []).find((variant: any) => variant?.isAvailable) || product?.variants?.[0]

      if (variant) {
        const { title, category } = productTitle(product?.productTitle)

        return {
          id: variant?.sku,
          name: title,
          brand: 'MR MARVIS',
          variant: variant?.options.map(({ value }: { value: string }) => value).join(' / ') || '',
          price: parseFloat(variant?.price || 0).toFixed(2),
          list,
          category,
          product_id: String(at((product?.shopifyProductId || '').split('/'), -1) || ''),
          variant_id: String(at((variant?.shopifyVariantId || '').split('/'), -1) || ''),
          position: ++position,
          // Non-Elevar required, custom by MR MARVIS
          slug: product?.slug?.current,
        }
      }

      return []
    }),
    // Non-Elevar required, custom by MR MARVIS
    contentGroupCategory,
    contentGroupSubcategory,
    season,
  }
}

export const ecommerceInfoSelectItem = (product: ProductCardProduct, index: number, list: string, currencyCode?: string) => {
  // Return first available variant to not send all variants into the data layer
  let variant = (product?.variants || []).find((variant: any) => variant?.isAvailable)

  // If there are no sellable variants, return first
  if (!variant) {
    variant = product?.variants?.[0]
  }

  return {
    currencyCode: currencyCode || 'EUR',
    click: {
      actionField: {
        list,
        action: 'click',
      },
      products:
        variant && product
          ? [
              {
                id: variant?.sku,
                name: product?.productTitle,
                brand: 'MR MARVIS',
                variant: variant?.options.map((option) => option.value).join(' / '),
                price: (variant?.price || 0).toFixed(2),
                list,
                category: (product?.productTitle.split('*')?.[1] || '').trim(),
                position: index + 1,
                product_id: String(at((product?.shopifyProductId || '').split('/'), -1) || ''),
                variant_id: String(at((variant?.shopifyVariantId || '').split('/'), -1) || ''),
                compare_at_price: '0.0',
                image: product?.images?.[0] || '',
                // Not required by Elevar but custom by MR MARVIS
                slug: product?.slug?.current,
              },
            ]
          : [],
    },
    // Not required by Elevar but custom by MR MARVIS
    slug: product?.slug?.current,
  }
}

export const ecommerceInfoViewItem = (
  slug: string,
  product: ShopifyProductInSanity<ShopifyVariantInSanityWithProductTitle>,
  productMedia: ProductMedia[] = [],
  list?: string,
  currencyCode?: string,
  category?: string,
  type?: string,
  season?: string,
) => {
  // Return first available variant to not send all variants into the data layer
  let variant = (product?.variants || []).find((variant) => variant?.isAvailable)

  // If there are no sellable variants, return first
  if (!variant) {
    variant = product?.variants?.[0]
  }

  // Category is 'Shorts' and subcategory is 'The Originals';
  // not required by Elevar but custom made for Contentsquare
  const contentGroupCategory = toSentenceCase(type?.split('|')?.[0]?.trim() || '')
  const contentGroupSubcategory = type?.split('|')?.[1]?.trim()

  // Find image (that is not a shop the look object)
  const imageUrl = (productMedia.find((media) => media._type === 'image') as Image | undefined)?.url

  return {
    currencyCode: currencyCode || 'EUR',
    detail: {
      actionField: {
        list,
        action: 'detail',
      },
      products:
        variant && product
          ? [
              {
                id: variant?.sku,
                name: product?.productTitle,
                brand: 'MR MARVIS',
                variant: variant?.options.map((option) => option.value).join(' / '),
                price: (variant?.price || 0).toFixed(2),
                list,
                category,
                product_id: String(at((product.shopifyProductId || '').split('/'), -1) || ''),
                variant_id: String(at((variant.shopifyVariantId || '').split('/'), -1) || ''),
                compare_at_price: '0.0',
                inventory: String(variant?.inventory?.quantity || 0),
                image: imageUrl ? `${imageUrl}?q=${imageQuality}` : '',
                // Not required by Elevar but custom by MR MARVIS
                slug,
              },
            ]
          : [],
    },
    // Category is 'Shorts' and subcategory is 'The Originals';
    // not required by Elevar but custom made for Contentsquare
    contentGroupCategory,
    contentGroupSubcategory,
    // Other custom logic (not Contentsquare)
    season,
    slug,
  }
}

export const ecommerceInfoUpdateCartItem = (lineItem: ShopifyCartLineItem, quantity: number, action: 'add' | 'remove', list?: string, locale = 'en') => {
  const { title, category } = productTitle(lineItem?.merchandise?.product?.title)

  return {
    currencyCode: lineItem?.merchandise?.price?.currencyCode || 'EUR',
    [action]: {
      actionField: {
        list,
      },
      products: [
        {
          id: lineItem?.merchandise?.sku,
          name: title,
          brand: 'MR MARVIS',
          variant: lineItem?.merchandise?.title,
          quantity: String(quantity),
          price: parseFloat(lineItem?.merchandise?.price?.amount || '0').toFixed(2),
          list,
          category,
          product_id: String(at((lineItem?.merchandise?.product?.id || '').split('/'), -1) || ''),
          variant_id: String(at((lineItem?.merchandise?.id || '').split('/'), -1) || ''),
          compare_at_price: '0.0',
          image: lineItem?.merchandise?.image?.transformedSrc || '',
          url: getSiteUrl(`${locale === 'en' ? '' : `/${locale}`}/products/${lineItem.merchandise.product.handle}`),
        },
      ],
    },
  }
}

interface EcommerceInfoAddToCartArgs {
  slug: string
  product: ShopifyProductInSanity
  productMedia: ProductMedia[]
  variant: ShopifyVariantInSanity
  context?: string
  list?: string
  currencyCode?: string
  locale?: string
}

export const ecommerceInfoAddToCart = ({ slug, product, productMedia, variant, context, list, currencyCode, locale = 'en' }: EcommerceInfoAddToCartArgs) => {
  // Find image (that is not a shop the look object)
  const image = (productMedia.find((media) => media._type === 'image') as Image | undefined)?.url || ''

  return {
    currencyCode: currencyCode || 'EUR',
    add: {
      actionField: {
        list,
      },
      products: [
        {
          id: variant?.sku,
          name: product?.productTitle,
          brand: 'MR MARVIS',
          variant: variant?.options.map((option) => option.value).join(' / '),
          quantity: '1',
          price: (variant?.price || 0).toFixed(2),
          list,
          category: (product?.productTitle.split('*')?.[1] || '').trim(),
          product_id: String(at((product.shopifyProductId || '').split('/'), -1) || ''),
          variant_id: String(at((variant?.shopifyVariantId || '').split('/'), -1) || ''),
          compare_at_price: '0.0',
          inventory: String(variant?.inventory?.quantity || 0),
          url: getSiteUrl(`${locale === 'en' ? '' : `/${locale}`}/products/${slug}`),
          image,
          // Not required by Elevar but custom by MR MARVIS
          slug,
        },
      ],
    },
    // Not required by Elevar but custom by MR MARVIS
    context,
    slug,
  }
}

export const ecommerceInfoRemoveFromCart = (lineItem: ShopifyCartLineItem, list?: string, removeAll = false, locale = 'en') => {
  const { title, category } = productTitle(lineItem?.merchandise?.product?.title)

  return {
    currencyCode: lineItem?.merchandise?.price?.currencyCode || 'EUR',
    remove: {
      actionField: {
        list,
      },
      products: [
        {
          id: lineItem?.merchandise?.sku,
          name: title,
          brand: 'MR MARVIS',
          variant: lineItem?.merchandise?.title,
          price: parseFloat(lineItem?.merchandise?.price?.amount || '0').toFixed(2),
          list,
          category,
          quantity: removeAll ? String(lineItem?.quantity || 1) : '1',
          product_id: String(at((lineItem?.merchandise?.product?.id || '').split('/'), -1) || ''),
          variant_id: String(at((lineItem?.merchandise?.id || '').split('/'), -1) || ''),
          compare_at_price: '0.0',
          image: lineItem?.merchandise?.image?.transformedSrc || '',
          url: getSiteUrl(`${locale === 'en' ? '' : `/${locale}`}/products/${lineItem.merchandise.product.handle}`),
        },
      ],
    },
  }
}

export const ecommerceInfoViewCart = (lineItems: ShopifyCartLineItem[], list?: string, locale = 'en') => {
  return {
    currencyCode: lineItems?.[0]?.merchandise?.price?.currencyCode || 'EUR',
    actionField: {
      list: 'Shopping Cart',
    },
    impressions: lineItems.map((lineItem, i) => {
      const { title, category } = productTitle(lineItem?.merchandise?.product?.title)
      const productHandle = lineItem?.merchandise?.product?.handle

      return {
        id: lineItem?.merchandise?.sku,
        name: title,
        brand: 'MR MARVIS',
        variant: lineItem?.merchandise?.title,
        price: parseFloat(lineItem?.merchandise?.price?.amount || '0').toFixed(2),
        list,
        category,
        quantity: String(lineItem?.quantity || 1),
        product_id: String(at((lineItem?.merchandise?.product?.id || '').split('/'), -1) || ''),
        variant_id: String(at((lineItem?.merchandise?.id || '').split('/'), -1) || ''),
        position: i + 1,
        // The Elevar debugger is mismatching the documentation; the debugger states a
        // url field/key is required, which their documentation in Notion does not mention
        url: productHandle ? getSiteUrl(`${locale === 'en' ? '' : `/${locale}`}/products/${productHandle}`) : '',
      }
    }),
  }
}

export const getLocalizedElevarKey = (locale: string = 'en') => {
  switch (locale) {
    case 'en':
      return '2344ca5bdc4804f154aac522d7ff43a1fef65c09'
    case 'nl':
      return '36e78b91967c2b146647ede4100b2ff80a51b302'
    case 'de':
      return '070d94a24fa6d5c9b92094f3d2c2799c477d1bf6'
    case 'fr':
      return 'dbdb8de0b64c5f9f44e577190d6d4693cafc7927'
    case 'es':
      return '473160469e33ccf2d86c6ade4f4d4cf81f3c0f49'
    case 'it':
      return '4ae7924ad0aa2bea14c734d099a3b782fa625253'
    case 'dk':
      return '82f08000b3d6efcb45f1ccb33758adad3288a964'
    case 'gb':
      return '948a8c0f810780b7aad6e4af34e04eb8779cdcd3'
    case 'ch':
      return '0cfd93958eae15341ec75f373718037328d48cc3'
  }
}
