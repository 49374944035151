import { defineQuery } from 'next-sanity'
import { coalesceFilter, coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { COLLECTION_BREADCRUMB_FRAGMENT, CollectionBreadcrumb } from '../components/breadcrumb.fragment'
import { SECTION_FILTER_CONFIGURATION_FRAGMENT, SectionFilterConfigurationData } from './sectionFilterConfiguration.fragment'
import { SECTION_GRID_CALLOUT_FRAGMENT, SectionGridCalloutsData } from './sectionGridCallouts.fragment'
import { CollectionSeason, ProductType, Slug } from '../../types'

export type SectionSearchFiltersGridData = {
  _id: string
  _type: 'sectionSearchFiltersGrid'
  title: string
  slug: Slug
  collectionSeason?: CollectionSeason
  productType: ProductType
  longDescription: string
  gridCallouts: SectionGridCalloutsData[]
  filterConfiguration: SectionFilterConfigurationData
  isPrimaryCollectionPage: boolean
  breadcrumb: CollectionBreadcrumb
}

const coalesceGridCallouts = (locale = DEFAULT_LOCALE, slug?: string) =>
  defineQuery(`
  coalesce(
    *[_type  == 'pageCollection' && length(connectedCollection[${coalesceFilter('@->slug', locale)}.current == '${slug}']) > 0][0].gridCallouts[]-> {
      ${SECTION_GRID_CALLOUT_FRAGMENT(locale)}
    },
    *[_type == 'defaultCollectionTemplate'][0].gridCallouts[]->{
      ${SECTION_GRID_CALLOUT_FRAGMENT(locale)}
    },
  )
`)

// This fragment collects the data needed to display the full search filters grid including the dynamic grid callouts and the filter configuration
export const SECTION_SEARCH_FILTERS_GRID_FRAGMENT = (locale = DEFAULT_LOCALE, slug?: string) =>
  defineQuery(`
  _type == 'sectionSearchFiltersGrid' => {
    _id,
    _type,
    ...coalesce(
      connectedCollection[${coalesceFilter('@->slug', locale)}.current == '${slug}'][0]->,
      *[_type == 'collection' && ${coalesceFilter('slug', locale)}.current == '${slug}'][0]
    ) {
      ${coalesceQuery('title', locale)},
      ${coalesceQuery('slug', locale)},
      defined(collectionSeason) => {
        collectionSeason
      },
      productType,
      "breadcrumb": {
        ${COLLECTION_BREADCRUMB_FRAGMENT(locale)}
      },
      'isPrimaryCollectionPage': isPrimary,
      "longDescription": coalesce(
        ${coalesceFilter('longDescription', locale)},
        ${coalesceFilter('collectionDescription', locale)}
      ),
    },
    "gridCallouts": ${coalesceGridCallouts(locale, slug)},
    filterConfiguration-> {
      ${SECTION_FILTER_CONFIGURATION_FRAGMENT(locale)}
    }
  }
`)
