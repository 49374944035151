import { defineQuery } from 'next-sanity'
import { Color, COLOR_FRAGMENT } from './color.fragment'
import { DEFAULT_LOCALE } from '../../utils'
import { Slug } from '../../types'

export interface ProductColor {
  _id: string
  slug: Slug
  title: string
  productColor?: Color
}

export const PRODUCT_COLOR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  slug,
  title,
  "productColor": color-> {
    ${COLOR_FRAGMENT(locale)}
  },
`)
