import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { defineQuery } from 'next-sanity'
import { Slug } from '../../types'

export interface Upsell {
  upsellCollection: {
    slug: Slug
  }
  upsellCollectionImage: Image
  toInclude?: string[]
  toExclude?: string[]
  upsellLinkText: string
  upsellCollectionMessage: string
}

export const UPSELL_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(upsellCollection) => {
    ${coalesceQuery(
      'upsellCollection',
      locale,
      'upsellCollection',
      `-> {
        _type,
        defined(slug) => {
          ${coalesceQuery('slug', locale, '', ', slug')}
        },
      }`,
    )}
  },
  ${coalesceQuery('upsellCollectionImage', locale, '', `{${GET_IMAGE_FRAGMENT()}}`)},
  defined(toInclude) => {
    "toInclude": array::unique(
      toInclude[]->{
        "slug": coalesce(
          products[]->slug.current,
          [slug.current]
        )
      }.slug[]
    )
  },
  defined(toExclude) => {
    "toExclude": array::unique(
      toExclude[]->{
        "slug": coalesce(
          products[]->slug.current,
          [slug.current]
        )
      }.slug[]
    )
  },
  defined(upsellLinkText) => {
    ${coalesceQuery('upsellLinkText', locale)}
  },
  defined(upsellCollectionMessage) => {
    ${coalesceQuery('upsellCollectionMessage', locale)}
  },
`)
