import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { SECTION_MEDIA_FRAGMENT, SectionMediaData } from '../sections/sectionMedia.fragment'
import { SECTION_TEXT_FRAGMENT, SectionTextData } from '../sections/sectionText.fragment'
import { SECTION_PADDING_FRAGMENT, SectionPaddingData } from '../sections/sectionPadding.fragment'
import { Article, RELATED_ARTICLES_FRAGMENT } from '../components/relatedArticles.fragments'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { defineQuery } from 'next-sanity'
import { Slug, YYYY_MM_DD } from '../../types'

export interface PageArticle {
  _type: string
  _id: string
  slug: Slug
  dateCreated: YYYY_MM_DD
  articleMainImage?: Media
  sections: (SectionTextData | SectionMediaData | SectionPaddingData)[]
  relatedArticles: Article[]
}

export const PAGE_ARTICLE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  _id,
  ${coalesceQuery('slug', locale)},
  dateCreated,
  ${coalesceQuery('title', locale)},
  articleMainImage-> {
    ${MEDIA_FRAGMENT()}
  },
  sections[]-> {
    ${SECTION_TEXT_FRAGMENT(locale)},
    ${SECTION_MEDIA_FRAGMENT()},
    ${SECTION_PADDING_FRAGMENT()}
  },
  "relatedArticles": *[ _type == ^._type && _id != ^._id && showInLocales[$locale] != false && (*[_type == 'sectionArticleOverview' && ^._id in articles[]._ref && (*[_type == 'pageWithRequirements' && ^._id in sections[]._ref][0] != null && showInLocales[$locale] != false)][0] != null)] | order(dateTime(dateCreated) desc)[0...3] {
    ${RELATED_ARTICLES_FRAGMENT(locale)}
  },
`)
