import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { defineQuery } from 'next-sanity'

export interface SpecialOpeningTime extends OpeningTime {
  date: string
  message?: string
}

export interface OpeningTime {
  open?: string
  close?: string
  closed?: boolean
}

export interface RetailStoreData {
  shopifyId: string
  geoPoint: {
    lat: number
    lng: number
  }
  city: string
  street: string
  postalCode: string
  country: string
  googleMapsLink?: string
  telephoneNumber?: string
  isOpen: boolean
  defaultOpeningTimes: {
    monday: OpeningTime
    tuesday: OpeningTime
    wednesday: OpeningTime
    thursday: OpeningTime
    friday: OpeningTime
    saturday: OpeningTime
    sunday: OpeningTime
  }
  specialOpeningTimes: SpecialOpeningTime[]
}

export interface SectionStoreData {
  _type: 'sectionStore'
  _id: string
  title: string
  store: RetailStoreData
  description?: RichText
  storeMedia: Media
}

export const SECTION_STORE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionStore' => {
    _type,
    _id,
    store-> {
      geoPoint,
      ${coalesceQuery('city', locale)},
      street,
      postalCode,
      googleMapsLink,
      telephoneNumber,
      defaultOpeningTimes,
      isOpen,
      specialOpeningTimes[(dateTime(@->date + 'T00:00:00Z') < dateTime(now()) + 60*60*24*14) && (dateTime(@->date + 'T00:00:00Z') > dateTime(now()) - 60*60*24)][0...7]-> {
        ...,
        ${coalesceQuery('message', locale)}
      }
    },
    ${coalesceQuery('title', locale)},
    defined(description) => {
      ${RICH_TEXT_FRAGMENT(locale, 'description')}
    },
    storeMedia-> {
      ${MEDIA_FRAGMENT(locale)}
    }
  }
`)
