import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { SECTION_TEXT_FRAGMENT, SectionTextData } from './sectionText.fragment'

export interface SectionWishlistData {
  _type: 'sectionWishlist'
  _id: string
  wishlistTitle: SectionTextData
  wishlistEmptyStatement: string
  saveWishlistButtonText: string
  loggedOutWishListWithItemsStatement?: string
  loggedInWishListWithItemsStatement?: string
}

export const SECTION_WISHLIST_FRAGMENT = (locale = DEFAULT_LOCALE) => `
_type == 'sectionWishlist' => {
  _id,
  _type,
  wishlistTitle -> {
    ${SECTION_TEXT_FRAGMENT(locale)},
  },
  ${coalesceQuery('loggedOutWishListWithItemsStatement', locale)},
  ${coalesceQuery('loggedInWishListWithItemsStatement', locale)},
  ${coalesceQuery('wishlistEmptyStatement', locale)},
  ${coalesceQuery('saveWishlistButtonText', locale)},
 }
`
