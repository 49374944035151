import { DEFAULT_LOCALE } from '../../utils'
import { SECTION_BANNER_CTA_FRAGMENT, SectionBannerCtaData } from './sectionBannerCta.fragment'
import { defineQuery } from 'next-sanity'

export interface SectionBannerCarouselData {
  _type: 'sectionBannerCarousel'
  _id: string
  name: string
  slides: SectionBannerCtaData[]
  delay?: number
}

export const SECTION_BANNER_CAROUSEL_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBannerCarousel' => {
    _type,
    _id,
    name,
    slides[@->showInLocales[$locale] != false]-> {
      ${SECTION_BANNER_CTA_FRAGMENT(locale)}
    },
    defined(delay) => {
      delay
    }
  }
`)
