import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { GET_IMAGE_FRAGMENT } from '../common/getImage.fragment'
import { ProductType } from '../../types'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { defineQuery } from 'next-sanity'
import { CareInstruction } from '../components/careInstructions.fragment'

export interface SectionCareData {
  _id: string
  _type: 'sectionCare'
  carePageData: {
    styles: Style[]
  }
}

export interface Style {
  _id: string
  title: string
  productType: ProductType
  products: {
    _id: string
    title: string
    care: CareInstructionSetWithDescription
  }[]
  care: CareInstructionSetWithDescription
}

export const SECTION_CARE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionCare' => {
    _id,
    _type,
    'carePageData': {
      "styles": *[_type == "collection" && isPrimary == true && showInLocales[$locale] != false && (defined(care) || count(products[@->.care != null]._id) != 0)] {
        _id,
        ${coalesceQuery('title', locale)},
        productType,
        products[@->.care != null]-> {
          _id,
          ${coalesceQuery('productTitle', locale, 'title')},
          ${CARE_INSTRUCTIONS_SET_WITH_DESCRIPTION_FRAGMENT(locale)}
        },
        ${CARE_INSTRUCTIONS_SET_WITH_DESCRIPTION_FRAGMENT(locale)}
      }
    }
  }
`)

export interface CareInstructionSetWithDescription {
  washing: CareInstructionWithDescription
  drying: CareInstructionWithDescription
  ironing: CareInstructionWithDescription
  storage: CareInstructionWithDescription
  wearAndTear: CareInstructionWithDescription
}

const CARE_INSTRUCTIONS_SET_WITH_DESCRIPTION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  care-> {
    washing {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    drying {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    ironing {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    storage {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
    wearAndTear {
      ${CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT(locale)}
    },
  }
`)

interface CareInstructionWithDescription {
  description: RichText
  careInstructions: CareInstruction[]
}

const CARE_INSTRUCTIONS_WITH_DESCRIPTION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(description) => {
    ${RICH_TEXT_FRAGMENT(locale, 'description')}
  },
  careInstructions[]-> {
    ${coalesceQuery('instruction', locale)},
    type,
    icon {
      ${GET_IMAGE_FRAGMENT()}
    }
  }
`)
