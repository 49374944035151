import { defineQuery } from 'next-sanity'
import { coalesceLocalizedValue, DEFAULT_LOCALE } from '../../utils'

export interface StylesOrder {
  applyOrderTo?: {
    header: boolean
    footer: boolean
    collectionSlider: boolean
    pcpCollectionCards: boolean
    sizeCharts: boolean
  }
  styles?: string[]
}

export const STYLES_ORDER_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  *[_type == 'stylesOrder'][0] {
    applyOrderTo,
    ${coalesceLocalizedValue('stylesOrder', locale, 'styles', '.value[]->._id')},
  }
`)
