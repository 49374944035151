import { ShopifyStoreConfigWithStorefrontConfig } from '@/types/shopify'
import { stores } from '../lib/shopify'

export const shopifyAdminApiToken = (storeDomain: string): Readonly<string> => {
  if (storeDomain === 'mr-marvis-stage.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_STAGING || ''
  } else if (storeDomain === 'mr-gustav-amsterdam.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_EN || ''
  } else if (storeDomain === 'mr-marvis-netherlands.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_NL || ''
  } else if (storeDomain === 'mr-marvis-uk.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_UK || ''
  } else if (storeDomain === 'mr-marvis-es.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_ES || ''
  } else if (storeDomain === 'mr-marvis-dk.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_DK || ''
  } else if (storeDomain === 'mr-marvis-it.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_IT || ''
  } else if (storeDomain === 'mr-marvis-de.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_DE || ''
  } else if (storeDomain === 'mr-marvis-fr.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_FR || ''
  } else if (storeDomain === 'mr-marvis-ch.myshopify.com') {
    return process.env.SHOPIFY_ADMIN_API_ACCESS_TOKEN_CH || ''
  }

  throw new Error('Invalid Shopify store domain.')
}

export const prepareStore = (locale = 'en'): ShopifyStoreConfigWithStorefrontConfig => {
  const store = stores?.find(({ storeLocale }) => storeLocale === locale) || stores?.find((s) => s.default)

  if (!store) {
    throw new Error('Store config not found.')
  }

  return {
    ...store,
    storefrontConfig: {
      Accept: 'application/json',
      'Accept-Language': 'en',
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': store?.storefrontAccessToken || '',
    },
  }
}
