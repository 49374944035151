import { defineQuery } from 'next-sanity'
import { GET_IMAGE_FRAGMENT, Image } from '../common/getImage.fragment'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProduct } from './productCardProduct.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { ProductType } from '../../types'

export interface ProductSpotProduct extends ProductCardProduct {
  color: {
    colorName: string
  }
  primaryCollectionTitle: string
  primaryCollectionProductType: ProductType
  showInLocales: boolean
}

export interface ProductSpot {
  product: ProductSpotProduct
  x: number
  y: number
}

export interface HotspotImage {
  _type: 'shopTheLookImage' | 'taggedImage'
  image: Image
  productSpots: ProductSpot[]
}

export const HOTSPOT_IMAGE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  image {
    ${GET_IMAGE_FRAGMENT()}
  },
  productSpots[
    product->.showInLocales[$locale] != false
    && product->.availableInShopify[$locale] != false
    && (
      *[
        _type == 'collection'
        && product->._id in products[]._ref
        && isPrimary == true
      ][0].showInLocales[$locale] != false
    )
  ] {
    product-> {
      ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)},
      color-> {
        colorName
      },
      ...*[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
        ${coalesceQuery('title', locale, 'primaryCollectionTitle')},
        "primaryCollectionProductType": productType,
      },
      'showInLocales': showInLocales[$locale]
    },
    x,
    y,
  }
`)
