import { QueryParams } from '@sanity/client'
import throttledQueue from 'throttled-queue'
import { sanityAdminClient, sanityBuildClient, sanityCdnClient } from './clients/sanityClient'

export const DEFAULT_LOCALE = 'en'

const sanityThrottleQueue = throttledQueue(25, 1000)

const getSanityClient = (admin = false, useCdn = true) => {
  if (admin) {
    return sanityAdminClient
  }

  return useCdn ? sanityCdnClient : sanityBuildClient
}

export const sanityFetch = async (query: string, preview = false, params?: QueryParams) => {
  let useCdn = true

  // Sanity CDN usage depends on env and application lifecycle
  if (process?.env?.NODE_ENV === 'development') {
    useCdn = (process?.env?.NEXT_PUBLIC_LOCALHOST_USE_CDN || 'true') === 'true'
  } else if (process?.env?.NEXT_PHASE === 'phase-production-build' && process?.env?.NEXT_PUBLIC_VERCEL_ENV !== 'preview') {
    useCdn = false
  }

  const client = getSanityClient(preview, useCdn)

  // Prevent hitting Sanity rate limiter; global max 500 req/s;
  // @see https://www.sanity.io/docs/technical-limits
  if (!useCdn) {
    return await sanityThrottleQueue(() => client.fetch(query, params))
  }

  return client.fetch(query, params)
}

const locales = ['en', 'gb', 'nl', 'de', 'ch', 'dk', 'it', 'fr', 'es']

export const localesWithFallbackForCoalesceQuery: Record<string, string[]> = {
  en: ['en'],
  gb: ['gb', DEFAULT_LOCALE],
  nl: ['nl', DEFAULT_LOCALE],
  de: ['de', DEFAULT_LOCALE],
  ch: ['ch', 'de', DEFAULT_LOCALE],
  dk: ['dk', DEFAULT_LOCALE],
  it: ['it', DEFAULT_LOCALE],
  fr: ['fr', DEFAULT_LOCALE],
  es: ['es', DEFAULT_LOCALE],
}

export const coalesceQuery = (name: string, currentLocale = DEFAULT_LOCALE, rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || name}': coalesce(${locales.map((locale) => `${name}${name === '' ? locale : `['${locale}']`}${additionalQuery}`).join(', ')})`
}

export const coalesceFilter = (name: string, currentLocale = DEFAULT_LOCALE) => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `coalesce(${locales.map((locale) => `${name}${name === '' ? locale : `['${locale}']`}`).join(', ')})`
}

export const forceFallbackForAll = (name: string) => {
  return `${name} {
    ${Object.entries(localesWithFallbackForCoalesceQuery)
      .map(([locale, fallbacks]) => {
        return `"${locale}": coalesce(${fallbacks.map((locale) => locale).join(', ')})`
      })
      .join(', ')}
  }`
}

export const requireAllToBeTrue = (name: string, fields: string[]) => `'${name}': {
    ${locales.map((locale) => `'${locale}': !coalesce(${fields.map((field) => `!${field}['${locale}']`).join(', ')})`)}
  }`

export const notSureAboutName = () =>
  `${locales
    .map(
      (locale) => `
  '${locale}': select(showInLocales['${locale}'] != false => ${coalesceFilter('slug', locale)}.current)
`,
    )
    .join(', ')}`

export const coalesceLocalizedValue = (name: string, currentLocale = DEFAULT_LOCALE, rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || name}': coalesce(${locales.map((locale) => `${`${name}[name == '${locale}'][0]`}${additionalQuery}`).join(', ')})`
}
