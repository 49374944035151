import { defineQuery } from 'next-sanity'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { coalesceQuery, DEFAULT_LOCALE } from '../../utils'
import { Slug } from '../../types'

export interface SpecialOpeningTime extends OpeningTime {
  date: string
  message?: string
}

export interface OpeningTime {
  open?: string
  close?: string
  closed?: boolean
}

export interface ThumbnailStoreInfo {
  city: string
  street: string
  postalCode: string
  country: string
  googleMapsLink?: string
  isOpen: boolean
  defaultOpeningTimes: {
    monday: OpeningTime
    tuesday: OpeningTime
    wednesday: OpeningTime
    thursday: OpeningTime
    friday: OpeningTime
    saturday: OpeningTime
    sunday: OpeningTime
  }
  specialOpeningTimes: SpecialOpeningTime[]
}

export interface StoreObject {
  store: ThumbnailStoreInfo
  page: {
    slug: Slug
  }
  image: Media
}

export interface SectionStoreIndexData {
  _type: 'sectionStoreIndex'
  _id: string
  title: string
  todaysOpeningHours: string
  viewStoreDetails: string
  stores: StoreObject[]
}

export const SECTION_STORE_INDEX_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
_type == 'sectionStoreIndex' => {
  _type,
  _id,
  ${coalesceQuery('title', locale)},
  ${coalesceQuery('todaysOpeningHours', locale)},
  ${coalesceQuery('viewStoreDetails', locale)},
  stores[] {
    store-> {
      ${coalesceQuery('city', locale)},
      street,
      postalCode,
      googleMapsLink,
      country,
      defaultOpeningTimes,
      isOpen,
      specialOpeningTimes[]-> {
        date,
        closed,
        open,
        close
      }
    } {
      ...,
      "specialOpeningTimes": specialOpeningTimes[dateTime(date + 'T00:00:00Z') < dateTime(now()) + 60*60*24*14]
    },
    page-> {
      ${coalesceQuery('slug', locale)}
    },
    image-> {
       ${MEDIA_FRAGMENT(locale)}
    }
  } | order(store.city asc)
}
`)
