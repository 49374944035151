import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { defineQuery } from 'next-sanity'
import { DEFAULT_LOCALE } from '../../utils'

export interface AnnouncementBarItem {
  body: RichText
  link: Link
}

export interface AnnouncementBar {
  announcementBarItems: AnnouncementBarItem[]
}

export const ANNOUNCEMENT_BAR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  announcementBarItems[]-> {
    ${RICH_TEXT_FRAGMENT(locale, 'text', 'body')},
    link-> {
      ${LINK_FRAGMENT(locale)}
    },
  }
`)
