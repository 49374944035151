import { createContext, FunctionComponent, PropsWithChildren } from 'react'
import { useCookieConsentContext } from '@use-cookie-consent/react'
import { useEffectOnce, useToggle } from 'react-use'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from '../utils/telemetry.util'
import { AllCookieTypeKeys, ConsentState } from '@use-cookie-consent/core'

const cookieTypes: AllCookieTypeKeys[] = ['necessary', 'preferences', 'statistics', 'marketing']

export type MisterCookieConsentContextType = {
  consent: ConsentState
  showCookieBanner: boolean
  customiseCookies: boolean
  cookieTypes: AllCookieTypeKeys[]
  acceptAllCookies: () => void
  rejectAllCookies: () => void
  startCustomise: () => void
  endCustomise: () => void
  handleCookieConsentChange: (value: boolean, changedCookie: AllCookieTypeKeys) => void
  hasConsentedToCookie: (changedCookie: AllCookieTypeKeys) => boolean
}

export const MisterCookieConsentContext = createContext<MisterCookieConsentContextType | undefined>(undefined)

export const MisterCookieConsentProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [showCookieBanner, setShowCookieBanner] = useToggle(false)
  const [customiseCookies, setCustomiseCookies] = useToggle(false)

  // @see https://github.com/use-cookie-consent/use-cookie-consent-react
  const cookieConsentContext = useCookieConsentContext()

  // Show banner on page load
  useEffectOnce(() => {
    // Cookie consent lib sets { necessary: true } by default,
    // so having one item means no cookie banner shown yet
    if (Object.keys(cookieConsentContext.consent).length === 1) {
      setShowCookieBanner(true)
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.ASK_COOKIE_CONSENT)
    }
  })

  const acceptAllCookies = () => {
    setShowCookieBanner(false)
    cookieConsentContext.acceptAllCookies()

    // Ensure the MisterScript useEffect hooks (with updated consent
    // values) are finished before we dispatch this event into GTM)
    setTimeout(() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.ACCEPT_ALL_COOKIES), 1000)
  }

  const rejectAllCookies = () => {
    setShowCookieBanner(false)
    cookieConsentContext.declineAllCookies()
  }

  const startCustomise = () => {
    handleCookieConsentChange(true, 'necessary')
    setCustomiseCookies(true)
    setShowCookieBanner(false)
  }

  const endCustomise = () => {
    setCustomiseCookies(false)
    setShowCookieBanner(false)
  }

  const hasConsentedToCookie = (cookie: AllCookieTypeKeys): boolean => {
    return cookieConsentContext.consent[cookie] || false
  }

  const handleCookieConsentChange = (value: boolean, changedCookie: AllCookieTypeKeys) => {
    cookieConsentContext.acceptCookies({
      ...cookieConsentContext.consent,
      [changedCookie]: value,
    })
  }

  return (
    <MisterCookieConsentContext.Provider
      value={{
        consent: cookieConsentContext.consent,
        showCookieBanner,
        customiseCookies,
        cookieTypes,
        acceptAllCookies,
        rejectAllCookies,
        startCustomise,
        endCustomise,
        handleCookieConsentChange,
        hasConsentedToCookie,
      }}
    >
      {children}
    </MisterCookieConsentContext.Provider>
  )
}
