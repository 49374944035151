import { createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ShopifyStoreConfigWithStorefrontConfig } from '@/types/shopify'
import { prepareStore } from '../utils/shopify.util'

type ShopifyContextType = {
  store: ShopifyStoreConfigWithStorefrontConfig | null
}

interface Props {
  locale?: string
}

const ShopifyContext = createContext<ShopifyContextType | undefined>(undefined)

export const ShopifyContextProvider: FunctionComponent<PropsWithChildren<Props>> = ({ locale = 'en', children }) => {
  const [store, setStore] = useState<ShopifyStoreConfigWithStorefrontConfig | null>(null)

  useEffect(() => {
    setStore((prevState) => ({
      ...prevState,
      ...prepareStore(locale),
    }))
  }, [locale])

  return <ShopifyContext.Provider value={{ store }}>{children}</ShopifyContext.Provider>
}

export const useShopify = (): ShopifyContextType => {
  const context = useContext(ShopifyContext)

  if (context === undefined) {
    throw new Error('useShopify must be used within a ShopifyProvider.')
  }

  return context
}
